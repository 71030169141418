import * as React from "react";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { ILoginLibrary } from "src/models/dto/DashboardModels";
import { Drawer, DrawerContainer } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { DashboardView } from "src/ui/foundation/Layout";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { PanelView } from "../PanelView";
import { ProductForm } from "./ProductForm";
import { PublisherPromotionForm } from "./PublisherPromotionForm";

export interface IProductViewProps {
  IsLoggedIn: boolean;
}
export interface IProductViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  allowedPubs: ILoginLibrary[];
  currentPub: ILoginLibrary | null;
  panelDisabled: boolean;
}
export class ProductView extends React.Component<IProductViewProps, IProductViewState> {
  context: AppSession;
  static contextType = AppContext;
  productTable = React.createRef<DataTable>();
  promoTable = React.createRef<DataTable>();
  constructor(props: IProductViewProps) {
    super(props);
    this.state = { drawerShow: false, currentDrawerContent: null, allowedPubs: [], currentPub: null, panelDisabled: false };
  }

  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Products)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Products)!.loading.off(this.loginInit);
  }
  loginInit = () => {
    let allowedPubs = this.context.getManageableProducts();
    if (allowedPubs.length > 0) {
      this.setState({ allowedPubs: allowedPubs, currentPub: allowedPubs[0] });
      this.context.viewedViews.get(DashboardView.Products)!.progressLoading();
    }
  };

  reloadTable = () => {
    this.productTable.current!.reload();
    this.promoTable.current!.reload();
  };
  publisherChanged = (e: ILoginLibrary) => {
    this.setState({ currentPub: this.state.allowedPubs.find((x) => x.PublisherId === e.PublisherId)! }, () => {
      this.reloadTable();
    });
  };
  // #region Product Table
  private initializeProduct = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowProducts({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });
  private productFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowProducts({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        } else {
          reject();
        }
      });
    });

  private saveProduct = async (e: Models.IProductViewModel, applyToSubs: boolean, newThumb: string) => {
    if (!Convert.isEmptyOrSpaces(newThumb)) {
      e.ProductDef.Thumbnail = newThumb;
    }
    let response = await this.context.insertOrUpdateProduct({ Product: e, ApplyToSubscriptions: applyToSubs });
    if (response.valid()) {
      Messages.Notify.success("Product saved successfully!");
      this.reloadTable();
      return response.data.Product;
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      return null;
    }
  };

  private deleteProduct = async (e: Models.IProductViewModel) => {
    let dResp = await Messages.Dialog.confirm(
      <div>
        <span>Are you absolutely sure you wish to delete this product? Doing so will:</span>
        <ul>
          <li>Delete the product.</li>
          <li>Delete ALL subscriptions to this product.</li>
          <li>Delete ALL Licences to subscriptions to this product.</li>
          <li>Delete ALL conditions for this product.</li>
        </ul>
      </div>,
      "Delete product?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (dResp === "true") {
      let response = await this.context.deleteProduct({ Product: e.ProductDef });
      if (response.valid()) {
        Messages.Notify.success("Product deleted successfully!");
        this.reloadTable();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private reloadProducts = () => {
    this.reloadTable();
  };

  private productRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <ProductForm
          initialNode={e as Models.IProductViewModel}
          saveRequested={this.saveProduct}
          deleteRequested={this.deleteProduct}
          reloadProducts={this.reloadProducts}
        />
      ),
    });
  };
  private productInsertRequest = () => {
    let blank: Models.IProductViewModel = {
      AssociatedTitles: [],
      AssociatedTitlesCount: 0,
      Conditions: [],
      Index: 0,
      IsFirst: false,
      IsLast: false,
      ProductDef: {
        AccessAfterExpiration: true,
        AvailableForSale: true,
        ContentPermissions: 3,
        Description: "",
        MasterCode: "",
        Name: "",
        OfflineAccessDuration: 0,
        OfflineAccessDurationType: 0,
        OfflineLoginsNumber: 0,
        ProductCode: "",
        PublisherId: this.state.currentPub!.PublisherId,
        TableId: -1,
        Thumbnail: "",
        StartDate: new Date(Date.now()),
        EndDate: new Date(Date.now()),
        IsFreeProduct: false,
      },
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: <ProductForm initialNode={blank} saveRequested={this.saveProduct} reloadProducts={this.reloadProducts} />,
    });
  };

  private generateProduct = (node: INode): JSX.Element => {
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.ProductDef.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={1} className="centerText" key={3} value={node.ProductDef.MasterCode} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={4} value={node.ProductDef.ProductCode} />);
    dataItems.push(<DataItem flexVal={3} className="" key={1} value={node.ProductDef.Name} />);

    let value = "";
    if (node.ProductDef.EndDate === null) {
      value = "Never";
    } else if (new Date(node.ProductDef.EndDate as Date) < new Date()) {
      value = "Expired";
    } else {
      value = Convert.dateToFormattedString(node.ProductDef.EndDate as Date, Languages.English);
    }
    if (value === "Expired") {
      dataItems.push(
        <DataItem flexVal={2} className="rightBorder leftBorder centerText" key={2} value={null}>
          <span style={{ color: "red" }}>{value}</span>
        </DataItem>
      );
    } else if (value === "Never") {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText bolded" key={2} value={value} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={2} value={value} />);
    }
    dataItems.push(<DataItem flexVal={1} className="" key={5} value={node.ProductDef.AvailableForSale} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={6} value={node.AssociatedTitlesCount.toString()} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.productRowEditRequest} />;
  };
  private productQueryExecute = (query: string) => {
    this.productTable.current!.reload(query);
  };
  // #endregion
  // #region Promotions
  private initializePromo = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowPublisherPromotions({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.currentPub!.PublisherId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private promoFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowPublisherPromotions({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private savePromo = async (e: Models.IPublisherPromoViewModel) => {
    let response = await this.context.insertOrUpdatePublisherPromotion({ Promotion: e.Promotion });
    if (response.valid()) {
      Messages.Notify.success("Promotion saved successfully!");
      this.reloadTable();
      this.setState({ currentDrawerContent: null, drawerShow: false });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  private deletePromo = async (e: Models.IPublisherPromoViewModel) => {
    let result = await Messages.Dialog.confirm(
      `Are you sure you wish to delete this Promotion? Users having this promotion will lose access only once the end date is reached.`
    );
    if (result === "true") {
      let response = await this.context.deletePublisherPromotion({ Promotion: e.Promotion });
      if (response.valid()) {
        Messages.Notify.success("Promotion deleted successfully!");
        this.reloadTable();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private promoRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <PublisherPromotionForm
          publisherId={this.state.currentPub!.PublisherId}
          initialNode={e as Models.IPublisherPromoViewModel}
          saveRequested={this.savePromo}
          deleteRequested={this.deletePromo}
          reloadProducts={this.reloadTable}
        />
      ),
    });
  };
  private promoInsertRequest = () => {
    let blank: Models.IPublisherPromoViewModel = {
      Index: 0,
      IsFirst: false,
      IsLast: false,
      ProductDef: null,
      Promotion: {
        DefinitionId: -1,
        Description: "",
        StartDate: new Date(Date.now()),
        EndDate: new Date(Date.now()),
        Name: "",
        TableId: -1,
      },
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <PublisherPromotionForm
          publisherId={this.state.currentPub!.PublisherId}
          initialNode={blank}
          saveRequested={this.savePromo}
          reloadProducts={this.reloadTable}
        />
      ),
    });
  };
  private generatePromo = (n: INode): JSX.Element => {
    let node = n as Models.IPublisherPromoViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.ProductDef!.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={2} className="centerText" key={1} value={node.Promotion.Name} />);
    dataItems.push(<DataItem flexVal={2} className="centerText" key={2} value={node.ProductDef!.Name} />);

    let startdate = Convert.dateToFormattedString(node.Promotion.StartDate, Languages.English);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={3} value={startdate} />);

    let endate = "";
    if (node.Promotion.EndDate === null) {
      endate = "Never";
    } else if (new Date(node.Promotion.EndDate) < new Date()) {
      endate = "Expired";
    } else {
      endate = Convert.dateToFormattedString(node.Promotion.EndDate, Languages.English);
    }
    if (endate === "Expired") {
      dataItems.push(
        <DataItem flexVal={1} className="rightBorder leftBorder centerText" key={4} value={null}>
          <span style={{ color: "red" }}>{endate}</span>
        </DataItem>
      );
    } else if (endate === "Never") {
      dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText bolded" key={4} value={endate} />);
    } else {
      dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={4} value={endate} />);
    }
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.promoRowEditRequest} />;
  };
  private promoQueryExecute = () => {};
  // #endregion
  render() {
    if (!this.props.IsLoggedIn || this.state.allowedPubs.length <= 0 || !this.context.viewedViews.get(DashboardView.Products)!.isLoaded()) {
      return "";
    }
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          publisherList={this.state.allowedPubs}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.currentPub!}
          disabled={this.state.panelDisabled}
        />
        <div className="productView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="productViewInner">
              <div className="section">
                <h1>Welcome to the Product View</h1>
                <p>
                  Welcome to the Product View. This view is used to manage the various products in the system. Products are groupings of titles to which users
                  will have Licence to a subscription. This way, a product can have multiple titles and these can change when necessary. In this view you can:
                </p>
                <ul>
                  <li>Create new products</li>
                  <li>Modify Existing products</li>
                  <li>Delete products (along with all subscriptions/Licences to them)</li>
                  <li>
                    Remove products from sale (hide product from the Reader &quot;available titles&quot; section, without nullifying existing subscriptions)
                  </li>
                </ul>
                <p>
                  If you are seeing this page, it&apos;s because you are listed as an administrator of the Dashboard system for proLibro Connect or you have
                  ManageProduct/ManagePublisher rights to one or more publishers.
                </p>
              </div>
              <div className="section">
                <h2>Products</h2>
                <p>Products group one or more titles and will be the target of Subscriptions that users will have Licence to.</p>
                <DataTable
                  headers={["Master Code", "Product Code", "Name", "End Date", "Available for Sale", "Titles"]}
                  headerFlexes={[1, 1, 3, 2, 1, 1]}
                  rowAddRequested={this.productInsertRequest}
                  flowProvider={this.productFlowProvider}
                  initializeFlowProvider={this.initializeProduct}
                  objectBuilder={this.generateProduct}
                  ref={this.productTable}
                  settingsOverride={Models.genericDataSettings}
                  searchQueryComitted={this.productQueryExecute}
                />
              </div>
              <div className="section">
                <h2>Publisher Promotions</h2>
                <p>
                  As a product manager or publisher manager, you can extend promotions to you users. Promotions reference a product you wish to giver all your
                  users&apos; access within a set start date and end date.{" "}
                  <b>Note: The start date and end date must be within the dates of the referenced product.</b> Within these dates, users will see the referenced
                  product in their library and can use it as normal (following the product&apos;s rights). Once the date expires, they lose access. Their user
                  content is not lost (in the event they purchase this product they can access their annotations etc.).
                </p>
                <DataTable
                  headers={["Name", "Product", "Start Date", "End Date"]}
                  headerFlexes={[2, 2, 1, 1]}
                  rowAddRequested={this.promoInsertRequest}
                  flowProvider={this.promoFlowProvider}
                  initializeFlowProvider={this.initializePromo}
                  objectBuilder={this.generatePromo}
                  ref={this.promoTable}
                  settingsOverride={Models.genericDataSettings}
                  searchQueryComitted={this.promoQueryExecute}
                />
              </div>
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
