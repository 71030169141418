import * as React from 'react';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';
import { Convert } from 'src/utilities/Helpers';

import { Image } from '../foundation/Assets';
import { Icon, Loading } from '../foundation/Controls';
import * as Messages from '../foundation/Messages';
import { AppContext } from '../state/Contextes';

export interface IConfirmPasswordResetViewProps {
  tokenCode: string;
  clearInvite: () => void;
}
export interface IConfirmPasswordResetViewState {
  passwordChanging: boolean;
  password: string;
  confirmPassword: string;
}

export class ConfirmPasswordResetView extends React.Component<IConfirmPasswordResetViewProps, IConfirmPasswordResetViewState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IConfirmPasswordResetViewProps) {
    super(props);
    this.state = { passwordChanging: false, confirmPassword: "", password: "" };
  }
  componentDidMount() {
    this.setState({ passwordChanging: true }, async () => {
      let response = await this.context.validateResetToken({ Token: this.props.tokenCode });
      if (!response.valid()) {
        if (response.errors.length > 0) {
          Messages.Notify.error("Validation failed. " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }

        this.props.clearInvite();
      } else {
        this.setState({ passwordChanging: false });
      }
    });
  }
  resetPassword = () => {
    this.setState({ passwordChanging: true }, async () => {
      let response = await this.context.confirmResetPassword({ Token: this.props.tokenCode, NewPassword: this.state.password });
      if (response.valid()) {
        Messages.Notify.success("Your password has been successfully changed!");
        this.props.clearInvite();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Confirmation failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
        this.setState({ passwordChanging: false });
      }
    });
  };
  handlePasswordChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: arg.target.value });
  };
  handleConfirmPasswordChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: arg.target.value });
  };
  render() {
    return (
      <div className="darkOverlay">
        <div className="confirmPasswordResetView">
          <Loading theme="opaque" isLoading={this.state.passwordChanging} status="Changing password...">
            <div className="resetContainer">
              <h3>Please enter the new password for your account and confirm it.</h3>
              <Form>
                <FormGroup>
                  <InputGroup>
                      <InputGroupText>
                        <Icon src={<Image.lock />} />
                      </InputGroupText>
                    <Input type="password" name="password" value={this.state.password} placeholder={"New password"} onChange={this.handlePasswordChanged} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                      <InputGroupText>
                        <Icon src={<Image.lock />} />
                      </InputGroupText>
                    <Input
                      type="password"
                      name="confirmPassword"
                      value={this.state.confirmPassword}
                      placeholder={"Confirm new password"}
                      onChange={this.handleConfirmPasswordChanged}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Button
                    onClick={this.resetPassword}
                    outline
                    color="primary"
                    disabled={this.state.password !== this.state.confirmPassword || Convert.isEmptyOrSpaces(this.state.password)}
                  >
                    Reset password
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </Loading>
        </div>
      </div>
    );
  }
}
