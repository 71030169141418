import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Label } from 'reactstrap';
import {
    UserFormSubmissionState, UserFormSubmissionStateToString
} from 'src/models/dto/UserFormRequest';

export interface IUserFormStateFilterProps {
  selectedFilterChanged: (newState: UserFormSubmissionState) => void;
  currentSelection: UserFormSubmissionState;
}
export interface IUserFormStateFilterState {
  dropdownOpen: boolean;
}
export class UserFormStateFilter extends React.Component<IUserFormStateFilterProps, IUserFormStateFilterState> {
  constructor(props: IUserFormStateFilterProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  select = (eventKey: any) => {
    this.props.selectedFilterChanged(Number(eventKey));
  };
  render() {
    let items = [];
    let selected = null;
    for (let item in UserFormSubmissionState) {
      if (!isNaN(Number(item))) {
        if (Number(item) === this.props.currentSelection) {
          selected = (
            <Dropdown.Toggle variant="info" id="dropdown-basic">
              {UserFormSubmissionStateToString.UserFormSubmissionStateToString(Number(item))}
            </Dropdown.Toggle>
          );
        } else {
          items.push(<Dropdown.Item key={Number(item)} eventKey={Number(item)}>{UserFormSubmissionStateToString.UserFormSubmissionStateToString(Number(item))}</Dropdown.Item>);
        }
      }
    }
    return (
      <div className="formStateFilter">
        <Label for="filterControl">Current entry filter: </Label>
        <Dropdown id="filterControl" onToggle={this.toggle} onSelect={this.select}>
          {selected}
          <Dropdown.Menu>{items}</Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
