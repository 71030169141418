import * as React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { Loading } from "src/ui/foundation/Controls";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";

export interface ICreateServerLicensingTokenFormProps {
  dismissDrawer: () => void;
}
export interface ICreateServerLicensingTokenFormState {
  editingLicensingToken: Models.ILicensingToken;
  tokenId: string;
  loading: boolean;
}
export class CreateServerLicensingTokenForm extends React.Component<ICreateServerLicensingTokenFormProps, ICreateServerLicensingTokenFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ICreateServerLicensingTokenFormProps) {
    super(props);
    this.state = {
      editingLicensingToken: {
        CreationDate: new Date(),
        ExpirationDate: new Date(),
        TableGuid: "00000000-0000-0000-0000-000000000000",
        TableId: -1,
      },
      loading: false,
      tokenId: "",
    };
  }
  expirationDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => {
      prevState.editingLicensingToken.ExpirationDate = dato!;
      return prevState;
    });
  };
  tokenChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState(() => ({
      tokenId: e.target.value,
    }));
  };

  createLicensingToken = () => {
    if (!Convert.isEmptyOrSpaces(this.state.tokenId)) {
      this.setState({ loading: true }, async () => {
        let response = await this.context.createLicensingToken({
          LicensingToken: this.state.editingLicensingToken,
          TokenId: this.state.tokenId,
        });
        if (response.valid()) {
          Messages.Notify.success("Licensing token created successfully!");
          this.download("LicenseToken" + this.state.tokenId + ".cogniToken", response.data.Token);
          this.props.dismissDrawer();
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  };
  download = (filename: string, text: string) => {
    let element = document.createElement("a");
    element.setAttribute("href", "data:application/octet-stream," + encodeURIComponent(text));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  render() {
    let expirationDate = Convert.formatDateForForm(new Date(this.state.editingLicensingToken.ExpirationDate));

    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Publication Pack...">
        <div className="form-container full-width full-height">
          <h2>Licensing token creation panel</h2>
          <p>
            This panel is to create licensing tokens that extend the licensing of a remotely (datacenter) implemented Connect/Dashboard system. Input the
            TokenConfig data you wish to use and when this token should expire. Then click &quot;Generate server licensing token&quot;. This will download the
            encrypted token through the browser to be sent to the data center account admin for this install.
          </p>
          <Col>
            <Row>
              <FormGroup>
                <Label for="tokenId">Token Id on the server (TokenConfigs + [This_Input]) see config pairs.</Label>
                <Input value={this.state.tokenId} onChange={this.tokenChanged} type="text" name="tokenId" id="tokenId" placeholder="TokenConfigs +" />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                <Label for="dateCreated">Expiration date for licensing token:</Label>
                <Input type="date" name="dateCreated" id="dateCreated" value={expirationDate} onChange={this.expirationDateChanged} />
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={this.createLicensingToken}>
              Generate server licensing token
            </Button>
          </Col>
        </div>
      </Loading>
    );
  }
}
