import axios from "axios";
import { Dictionary } from "src/collections/Generics";
import { Log } from "src/Logger";
import * as uuid from "uuid";

import { IRequest, IResponse, ISocketResponse, Status } from "./Requests";

export type SocketCallback = (response: ISocketResponse) => any;
/**
 * Contracts the post() method of requestors responsible for backend/frontend communication.
 * post() shall return a IResponse Promise, enabling asynchronicity.
 */
export interface IRequestor {
  post(request: IRequest): Promise<IResponse>;
  createSocket(id: string, callback: SocketCallback, refreshTimeout: number): void;
}

abstract class BaseRequestor implements IRequestor {
  protected sockets: Dictionary<string, SocketCallback[]>;
  constructor() {
    this.sockets = new Dictionary<string, SocketCallback[]>();
  }
  abstract post(request: IRequest): Promise<IResponse>;

  createSocket(id: string, callback: SocketCallback, refreshTimeout: number): void {
    if (!this.sockets.containsKey(id)) {
      this.sockets.set(id, new Array<SocketCallback>());
    }
    this.sockets.get(id)!.push(callback);
    this.socketConnected(id, refreshTimeout);
  }

  protected abstract socketConnected(id: string, refreshTimeout: number): void;
  protected abstract socketDisconnected(id: string): void;

  hasSockets(): boolean {
    return this.sockets.length > 0;
  }

  isSocketMessage(id: string) {
    return this.sockets.containsKey(id);
  }

  handleSocketMessage(response: ISocketResponse) {
    if (!this.isSocketMessage(response.Sequence)) {
      return;
    }
    for (const callback of this.sockets.get(response.Sequence)!) {
      try {
        callback(response);
      } catch (e) {
        Log.error(`Error not handled in Socket callback ${response.Url}`, e);
      }
    }
    if (!response.KeepAlive) {
      this.socketDisconnected(response.Sequence);
      Log.info(`Socket named ${response.Url}, id: ${response.Sequence} disconnected by server`);
    }
  }
}

export class CefRequestor extends BaseRequestor {
  private pending: Dictionary<string, (value?: any) => void>;
  constructor() {
    super();
    this.pending = new Dictionary<string, (value?: any) => void>();
    const thisWindow: any = window;
    thisWindow.receiveData = this.receive.bind(this);
  }

  post(request: IRequest): Promise<IResponse> {
    const promise = new Promise<any>((resolve) => {
      const thisWindow: any = window;
      if (thisWindow.bound !== undefined) {
        request.Sequence = newId();
        request.Timestamp = getTimestamp();
        this.pending.set(request.Sequence, resolve);
        thisWindow.bound.javascriptEvent(JSON.stringify(request));
      }
    });
    return promise;
  }

  receive(payload: string) {
    let response: IResponse = JSON.parse(payload);
    if (this.isSocketMessage(response.Sequence)) {
      let socketResponse: ISocketResponse = response as any;
      this.handleSocketMessage(socketResponse);
    } else {
      if (this.pending.containsKey(response.Sequence)) {
        this.pending.get(response.Sequence)!(response);
        this.pending.remove(response.Sequence);
      }
    }
  }

  socketConnected() {
    // Cef doesn't care
  }

  socketDisconnected() {
    // Cef doesn't care
  }
}
export class AjaxRequestor extends BaseRequestor {
  private baseUrl: string;
  private socketWatchers: Dictionary<string, NodeJS.Timeout>;
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
    this.checkSocket = this.checkSocket.bind(this);
    this.socketWatchers = new Dictionary<string, any>();
  }

  post(request: IRequest): Promise<IResponse> {
    const promise = new Promise<any>((resolve, reject) => {
      request.Sequence = newId();
      request.Timestamp = getTimestamp();
      axios
        .post(this.baseUrl + request.Url, request)
        .then((r) => {
          let response: IResponse = r.data;
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  socketConnected(id: string, refreshTimeout: number) {
    this.socketWatchers.set(
      id,
      setTimeout(() => {
        this.checkSocket(id, refreshTimeout);
      }, refreshTimeout)
    );
  }

  socketDisconnected(id: string) {
    if (this.socketWatchers.containsKey(id)) {
      clearTimeout(this.socketWatchers.get(id));
      this.socketWatchers.remove(id);
    }
  }

  private async checkSocket(id: string, refreshTimeout: number) {
    let response: IResponse | undefined;
    let socketResponse: ISocketResponse | undefined;
    try {
      do {
        response = await this.post({
          Url: "socket/refresh",
          Data: {
            Id: id,
          },
        } as IRequest);
        if (response) {
          socketResponse = response.Data as ISocketResponse;
          if (socketResponse) {
            this.handleSocketMessage(socketResponse);
            if (!socketResponse.KeepAlive) {
              break;
            }
          }
        }
      } while (
        response &&
        response.Status !== Status.NotFound // consume all the pending response
      );
    } catch (e) {
      Log.error("Error checking socket", e);
    }

    let shouldContinue = this.socketWatchers.containsKey(id);
    if (response !== undefined) {
      shouldContinue = response.Status !== Status.BadRequest;
      if (shouldContinue && socketResponse) {
        shouldContinue = socketResponse.KeepAlive;
      }
    }
    if (shouldContinue) {
      this.socketWatchers.set(
        id,
        setTimeout(() => {
          this.checkSocket(id, refreshTimeout);
        }, refreshTimeout)
      );
    }
  }
}

export class AndroidRequestor extends BaseRequestor {
  private pending: Dictionary<string, (value?: any) => void>;
  constructor() {
    super();
    this.pending = new Dictionary<string, (value?: any) => void>();
    const thisWindow: any = window;
    thisWindow.receiveData = this.receive.bind(this);
  }

  post(request: IRequest): Promise<IResponse> {
    const promise = new Promise<any>((resolve) => {
      const thisWindow: any = window;
      if (thisWindow.AndroidPostInterface !== undefined) {
        request.Sequence = newId();
        request.Timestamp = getTimestamp();
        this.pending.set(request.Sequence, resolve);
        thisWindow.AndroidPostInterface.Post(JSON.stringify(request));
      }
    });
    return promise;
  }

  receive(payload: string) {
    let response: IResponse = JSON.parse(payload);
    if (this.isSocketMessage(response.Sequence)) {
      let socketResponse: ISocketResponse = response as any;
      this.handleSocketMessage(socketResponse);
    } else {
      if (this.pending.containsKey(response.Sequence)) {
        this.pending.get(response.Sequence)!(response);
        this.pending.remove(response.Sequence);
      }
    }
  }

  socketConnected() {
    // Android doesn't care
  }

  socketDisconnected() {
    // Android doesn't care
  }
}

export class AppleRequestor extends BaseRequestor {
  private pending: Dictionary<string, (value?: any) => void>;
  iframe: HTMLElement;
  constructor() {
    super();
    this.pending = new Dictionary<string, (value?: any) => void>();
    const thisWindow: any = window;
    thisWindow.receiveData = this.receive.bind(this);
    this.iframe = document.createElement("IFRAME");
  }

  post(request: IRequest): Promise<IResponse> {
    const promise = new Promise<any>((resolve) => {
      request.Sequence = newId();
      request.Timestamp = getTimestamp();
      this.pending.set(request.Sequence, resolve);
      this.iframe.setAttribute("src", encodeURIComponent(JSON.stringify(request)));
      document.documentElement.appendChild(this.iframe);
      this.iframe.parentNode!.removeChild(this.iframe);
    });
    return promise;
  }

  receive(payload: string) {
    try {
      let response: IResponse = JSON.parse(payload);
      if (this.isSocketMessage(response.Sequence)) {
        let socketResponse: ISocketResponse = response as any;
        this.handleSocketMessage(socketResponse);
      } else {
        if (this.pending.containsKey(response.Sequence)) {
          this.pending.get(response.Sequence)!(response);
          this.pending.remove(response.Sequence);
        }
      }
    } catch (e) {
      Log.error("Failed to handle reception!", e);
    }
  }

  socketConnected() {
    // Mac and OS doesn't care
  }

  socketDisconnected() {
    // Mac and OS doesn't care
  }
}

const newId = (): string => uuid.v4();

const getTimestamp = (): number => new Date().getTime();
