import * as React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import * as Models from "../../../models/dto/DashboardModels";

export interface IAuthenticationProviderFormProps {
  initialNode: Models.IAuthenticationProvider;
  deleteRequested?: (node: Models.IAuthenticationProvider) => void;
  saveRequested?: (node: Models.IAuthenticationProvider) => void;
}
export interface IAuthenticationProviderFormState {
  editingNode: Models.IAuthenticationProvider;
}
export class AuthenticationProviderForm extends React.Component<IAuthenticationProviderFormProps, IAuthenticationProviderFormState> {
  constructor(props: IAuthenticationProviderFormProps) {
    super(props);
    this.state = { editingNode: this.props.initialNode };
  }
  componentDidMount() {}

  templateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Template: e.target.value } }));
  };
  providerChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Provider: +e.target.value } }));
  };

  methodChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Method: +e.target.value } }));
  };

  templateTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, TemplateType: +e.target.value } }));
  };

  formValid() {
    return (
      this.state.editingNode.Method !== 0 &&
      this.state.editingNode.Provider !== 0 &&
      this.state.editingNode.TemplateType !== 0 &&
      this.state.editingNode.Template.length > 0
    );
  }
  render() {
    return (
      <div className="formContainer">
        <h2>Authentication Provider management</h2>
        {this.props.deleteRequested ? (
          <p>You have selected an Authentication Provider item to consult or edit.</p>
        ) : (
          <p>You have chosen to create a new Authentication Provider item. Enter the properties below and click &quot;save&quot;</p>
        )}
        <Form>
          <Col>
            <Row>
              <FormGroup style={{ flex: "1" }}>
                <Label for="providerType">Provider Type</Label>
                <Input
                  onChange={this.providerChanged}
                  value={this.state.editingNode.Provider}
                  disabled={this.props.deleteRequested !== undefined}
                  type="select"
                  name="providerType"
                  id="providerType"
                >
                  {Object.keys(Models.AuthProviderType)
                    .filter((key) => isNaN(Number(Models.AuthProviderType[key as keyof typeof Models.AuthProviderType])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {Models.AuthProviderType[it as keyof typeof Models.AuthProviderType]}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup style={{ flex: "1", margin: "0px 15px 0px 15px" }}>
                <Label for="providerType">Method Type</Label>
                <Input
                  onChange={this.methodChanged}
                  value={this.state.editingNode.Method}
                  disabled={this.props.deleteRequested !== undefined}
                  type="select"
                  name="methodType"
                  id="providerType"
                >
                  {Object.keys(Models.AuthMethod)
                    .filter((key) => isNaN(Number(Models.AuthMethod[key as keyof typeof Models.AuthMethod])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {Models.AuthMethod[it as keyof typeof Models.AuthMethod]}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup style={{ flex: "1" }}>
                <Label for="providerType">Template Type</Label>
                <Input
                  onChange={this.templateTypeChanged}
                  value={this.state.editingNode.TemplateType}
                  disabled={this.props.deleteRequested !== undefined}
                  type="select"
                  name="templateType"
                  id="providerType"
                >
                  {Object.keys(Models.AuthTemplateType)
                    .filter((key) => isNaN(Number(Models.AuthTemplateType[key as keyof typeof Models.AuthTemplateType])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {Models.AuthTemplateType[it as keyof typeof Models.AuthTemplateType]}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Row>
            <Row>
              <Label for="templateValue">Template Value</Label>
              <Input
                value={this.state.editingNode.Template}
                onChange={this.templateChanged}
                type="text"
                name="configValue"
                id="templateValue"
                placeholder="Template Value"
              />
            </Row>
          </Col>
          <Col>
            <Row className="formButtons">
              {this.props.deleteRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>
                  Delete
                </Button>
              )}
              {this.props.saveRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                  Save
                </Button>
              )}
            </Row>
          </Col>
        </Form>
      </div>
    );
  }
}
