import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { Loading } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { Action, IBatch, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";

export interface IProductFormProps {
  initialNode: Models.IProductViewModel;
  deleteRequested?: (node: Models.IProductViewModel) => Promise<void>;
  saveRequested?: (node: Models.IProductViewModel, applyToAllSubscriptions: boolean, newThumb: string) => Promise<Models.IProductViewModel | null>;
  reloadProducts?: () => void;
}
export interface IProductFormState {
  editingNode: Models.IProductViewModel;
  editingCondition: number;
  activeTab: ProductTab;
  newThumb: string;
  neverExpires: boolean;
  rightsChanged: boolean;
  loading: boolean;
}
enum ProductTab {
  Properties,
  Rights,
  Titles,
  Conditions,
}
export class ProductForm extends React.Component<IProductFormProps, IProductFormState> {
  context: AppSession;
  static contextType = AppContext;
  thumbnailInput = React.createRef<HTMLInputElement>();
  associatedTitlesTable = React.createRef<DataTable>();
  availableTitlesTable = React.createRef<DataTable>();
  conditionTable = React.createRef<DataTable>();
  constructor(props: IProductFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      activeTab: ProductTab.Properties,
      newThumb: "",
      neverExpires: false,
      editingCondition: -1,
      rightsChanged: false,
      loading: false,
    };
  }
  saveRequested = () => {
    if (this.props.saveRequested && this.productValid()) {
      this.setState({ loading: true }, async () => {
        let success = null;
        if (this.state.rightsChanged) {
          let result = await Messages.Dialog.confirm(
            'You have changed the rights of this product. Do you want existing subscriptions to this product to be changed in order to use these new rights, or only apply them to new subscriptions to this product? Selecting "Yes" will require processing time to update existing subscriptions.',
            "Product Rights Changed",
            Messages.Dialog.Buttons.YesNo
          );
          success = await this.props.saveRequested!(this.state.editingNode, result === "true", this.state.newThumb);
        } else {
          success = await this.props.saveRequested!(this.state.editingNode, false, this.state.newThumb);
        }
        if (success !== null) {
          this.thumbnailInput.current!.value = "";
          this.setState({ editingNode: success, newThumb: "" }, () => {
            this.conditionTable.current!.reload();
            this.availableTitlesTable.current!.reload();
            this.associatedTitlesTable.current!.reload();
          });
        }
        this.setState({ loading: false });
      });
    }
  };
  deleteRequested = () => {
    if (this.props.deleteRequested) {
      this.setState({ loading: true }, async () => {
        await this.props.deleteRequested!(this.state.editingNode);
        this.setState({ loading: false });
      });
    }
  };
  async componentDidMount() {
    // Only complete product if this is an edit.
    if (this.props.deleteRequested) {
      let response = await this.context.getFullProduct({ TableId: this.props.initialNode.ProductDef.TableId });
      if (response.valid()) {
        this.setState({ editingNode: response.data.Product }, () => {
          this.associatedTitlesTable.current!.reload();
          this.availableTitlesTable.current!.reload();
        });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  }

  productValid = () => {
    let node = this.state.editingNode;
    if (node.AssociatedTitles.length === 0) {
      Messages.Notify.error("No titles have been associated to this product");
      return false;
    }
    if (node.Conditions.length > 0) {
      if (node.Conditions.some((x) => Convert.isEmptyOrSpaces(x.ConditionData))) {
        Messages.Notify.error("One or more conditions set on this product have empty data.");
        return false;
      }
    }
    if (node.ProductDef.ContentPermissions > 3 || node.ProductDef.ContentPermissions < 0) {
      Messages.Notify.error("The content permissions for this product are incorrect.");
      return false;
    }
    if (Convert.isEmptyOrSpaces(node.ProductDef.MasterCode)) {
      Messages.Notify.error("Master code cannot be empty.");
      return false;
    }
    if (Convert.isEmptyOrSpaces(node.ProductDef.ProductCode)) {
      Messages.Notify.error("Product code cannot be empty.");
      return false;
    }
    if (Convert.isEmptyOrSpaces(node.ProductDef.Name)) {
      Messages.Notify.error("Name cannot be empty.");
      return false;
    }
    if (node.ProductDef.OfflineAccessDurationType === Models.DurationType.Undefined) {
      Messages.Notify.error("Offline duration type must be set.");
      return false;
    }
    if (node.ProductDef.OfflineAccessDurationType !== Models.DurationType.Permanent && node.ProductDef.OfflineAccessDuration <= 0) {
      Messages.Notify.error("Offline duration must be set.");
      return false;
    }
    if (node.ProductDef.OfflineLoginsNumber < 0) {
      Messages.Notify.error("Offline Logins invalid.");
      return false;
    }
    return true;
  };

  // #region Properties and Rights
  handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, Name: e.target.value } } }));
  };
  handleMasterCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, MasterCode: e.target.value } },
    }));
  };
  handleProductCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ProductCode: e.target.value } },
    }));
  };
  handleProductDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, Description: e.target.value } },
    }));
  };
  availableForSaleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, AvailableForSale: e.target.checked } },
    }));
  };
  isFreeProductChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, IsFreeProduct: e.target.checked } },
    }));
  };
  handleNewThumbnail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0] === undefined) {
      this.setState({ newThumb: "" });
    } else {
      this.setNewThumb(e.target.files![0]!);
    }
  };
  setNewThumb = (file: File) => {
    let reader = new FileReader();
    reader.onload = () => {
      const dataReplace = /data: ?.*; ?base64. ?/g;
      this.setState({ newThumb: (reader.result! as string).replace(dataReplace, "") });
    };
    reader.readAsDataURL(file);
  };

  startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, StartDate: dato } },
      rightsChanged: true,
    }));
  };
  endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, EndDate: dato } },
      rightsChanged: true,
    }));
  };
  offlineDurationChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, OfflineAccessDuration: +e.target.value } },
      rightsChanged: true,
    }));
  };
  offlineLoginsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, OfflineLoginsNumber: +e.target.value } },
      rightsChanged: true,
    }));
  };
  offlineDurationTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, OfflineAccessDurationType: +e.target.value } },
      rightsChanged: true,
    }));
  };

  accessAfterExpiryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, AccessAfterExpiration: e.target.checked } },
      rightsChanged: true,
    }));
  };
  neverExpiresChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        ProductDef: {
          ...prevState.editingNode.ProductDef,
          EndDate: e.target.checked ? new Date(9998, 1, 1) : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        },
      },
      neverExpires: e.target.checked,
      rightsChanged: true,
    }));
  };
  canPrintChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState({ rightsChanged: true });
    if (this.state.editingNode.ProductDef.ContentPermissions === 1 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 3 } },
      }));
    } else if (this.state.editingNode.ProductDef.ContentPermissions === 3 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 1 } },
      }));
    } else if (this.state.editingNode.ProductDef.ContentPermissions === 0 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 2 } },
      }));
    } else if (this.state.editingNode.ProductDef.ContentPermissions === 2 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 0 } },
      }));
    }
  };
  canCopyChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState({ rightsChanged: true });
    if (this.state.editingNode.ProductDef.ContentPermissions === 2 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 3 } },
      }));
    } else if (this.state.editingNode.ProductDef.ContentPermissions === 3 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 2 } },
      }));
    } else if (this.state.editingNode.ProductDef.ContentPermissions === 0 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 1 } },
      }));
    } else if (this.state.editingNode.ProductDef.ContentPermissions === 1 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, ProductDef: { ...prevState.editingNode.ProductDef, ContentPermissions: 0 } },
      }));
    }
  };

  // #endregion
  // #region Titles
  generateTitle = (node: INode) => {
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={2} className="" key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={2} value={node.TableGuid} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.changeTitleAttribution} />;
  };
  changeTitleAttribution = (node: INode) => {
    // Click is in the Associated Titles and should be removed.
    let holder: Models.ITitle[] = [];
    holder = this.state.editingNode.AssociatedTitles;
    if (this.state.editingNode.AssociatedTitles.some((x) => x.TableId === node.TableId)) {
      const index = holder.indexOf(node as Models.ITitle);
      if (index > -1) {
        holder.splice(index, 1);
      }
    }
    // Click is in the AvailableTitles and should be added
    else {
      holder.push(node as Models.ITitle);
    }
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, AssociatedTitles: holder },
      }),
      () => {
        this.availableTitlesTable.current!.reload();
        this.associatedTitlesTable.current!.reload();
      }
    );
  };

  private initializeLocalTitles = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.state.editingNode.AssociatedTitles;
      if (result === null) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query,
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0,
      });
    });
  private availableTitleFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: request.Batches[0],
        PublisherId: this.state.editingNode.ProductDef.PublisherId,
        ExcludeTitles: this.state.editingNode.AssociatedTitles.map((x) => x.TableId),
        HideInactiveTitles: true!,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private initializeAvailableTitles = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.AssociatedTitles === null) {
        reject();
        return;
      }
      let result = await this.context.flowTitles({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.editingNode.ProductDef.PublisherId,
        ExcludeTitles: this.state.editingNode.AssociatedTitles.map((x) => x.TableId),
        HideInactiveTitles: true!,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private localTitlesFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });
  productQueryExecute = (query: string) => {
    this.availableTitlesTable.current!.reload(query);
  };
  // #endregion
  // #region Conditions
  private initializeConditions = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.state.editingNode.Conditions;
      if (result === null || result === undefined) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query,
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0,
      });
    });
  private conditionFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });
  generateCondition = (n: INode) => {
    let node = n as Models.IProductCondition;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={1} className="" key={1} value={Models.ProductConditionType[node.ConditionType]} />);
    dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={2} value={node.ConditionData} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.conditionEdit} />;
  };
  conditionEdit = (node: INode) => {
    this.setState({ editingCondition: node.Index });
  };
  addCondition = () => {
    let holder = this.state.editingNode.Conditions;
    let newCondition: Models.IProductCondition = {
      ConditionData: "",
      ConditionType: Models.ProductConditionType.IpList,
      DefinitionId: this.state.editingNode.ProductDef.TableId,
      TableId: 0,
      Index: 0,
      IsFirst: false,
      IsLast: false,
    };
    holder.push(newCondition);
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
        editingCondition: holder.length - 1,
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  conditionTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Conditions;
    holder[this.state.editingCondition].ConditionType = +e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  conditionDataChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Conditions;
    holder[this.state.editingCondition].ConditionData = e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  deleteCondition = () => {
    let holder = this.state.editingNode.Conditions;
    holder.splice(this.state.editingCondition, 1);
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
        editingCondition: -1,
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  // #endregion
  render() {
    let startDate = Convert.formatDateForForm(new Date(this.state.editingNode.ProductDef.StartDate));
    let endDate = Convert.formatDateForForm(new Date(this.state.editingNode.ProductDef.EndDate));
    let canPrint = this.state.editingNode.ProductDef.ContentPermissions === 3 || this.state.editingNode.ProductDef.ContentPermissions === 2;
    let canCopy = this.state.editingNode.ProductDef.ContentPermissions === 3 || this.state.editingNode.ProductDef.ContentPermissions === 1;

    let setting = JSON.parse(JSON.stringify(Models.genericDataSettings));
    setting.batchSize = 100;
    setting.maxHeight = "300px";
    return (
      <Loading
        className="full-width full-height"
        isLoading={this.state.editingNode.ProductDef.Description === null || this.state.loading}
        theme="opaque"
        status="Loading Product..."
      >
        <div className="form-container full-width full-height">
          <h3>Product management: {this.state.editingNode.ProductDef.Name}</h3>
          <div className="product-tabs">
            <Tabs defaultActiveKey={this.state.activeTab} id="productTabs">
              <Tab eventKey={ProductTab.Properties} title={"Properties"}>
                <div className="full-width full-height productProperties">
                  <Col>
                    <Row>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="productName">Product Name</Label>
                        <Input
                          value={this.state.editingNode.ProductDef.Name}
                          onChange={this.handleNameChanged}
                          type="text"
                          name="productName"
                          id="productName"
                          placeholder="Product Name"
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ marginRight: "15px", flex: "1" }}>
                        <Label for="productMasterCode">Product Master Code</Label>
                        <Input
                          value={this.state.editingNode.ProductDef.MasterCode}
                          onChange={this.handleMasterCodeChanged}
                          type="text"
                          name="productMasterCode"
                          id="productMasterCode"
                          placeholder="Product Master Code"
                        />
                      </FormGroup>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="productCode">Product Code</Label>
                        <Input
                          value={this.state.editingNode.ProductDef.ProductCode}
                          onChange={this.handleProductCodeChanged}
                          type="text"
                          name="productCode"
                          id="productCode"
                          placeholder="Product Code"
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1", marginLeft: "35px" }}>
                        <Label check for="availableForSale">
                          <Input
                            onChange={this.availableForSaleChanged}
                            checked={this.state.editingNode.ProductDef.AvailableForSale}
                            type="checkbox"
                            name="availableForSale"
                            id="availableForSale"
                          />
                          Available for Sale
                        </Label>
                      </FormGroup>
                      <FormGroup style={{ flex: "1", marginLeft: "35px" }}>
                        <Label check for="isFreeProduct">
                          <Input
                            onChange={this.isFreeProductChanged}
                            checked={this.state.editingNode.ProductDef.IsFreeProduct}
                            type="checkbox"
                            name="isFreeProduct"
                            id="isFreeProduct"
                          />
                          Is this a free product? (Ignored for reporting)
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="productDescription">Product Description</Label>
                        <Input
                          style={{ minHeight: "250px", maxHeight: "500px" }}
                          type="textarea"
                          name="productDescription"
                          id="productDescription"
                          onChange={this.handleProductDescriptionChanged}
                          value={this.state.editingNode.ProductDef.Description === null ? "" : this.state.editingNode.ProductDef.Description}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1", marginBottom: "0px" }}>
                        <Label for="productThumbnail">Thumbnail (.png)</Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1", display: "flex" }}>
                        <Input
                          style={{ width: "initial" }}
                          accept=".png"
                          type="file"
                          name="productThumbnail"
                          id="productThumbnail"
                          onChange={this.handleNewThumbnail}
                          innerRef={this.thumbnailInput}
                        />
                        {this.state.newThumb.length > 0 ? (
                          <Button
                            outline
                            color="danger"
                            onClick={() => {
                              this.setState({ newThumb: "" });
                              this.thumbnailInput.current!.value = "";
                            }}
                          >
                            Delete new thumbnail
                          </Button>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Row>
                    <Row>
                      <div className="thumbnailTitles">
                        <span className="currentThumbTitle">Current Thumbnail</span>
                        <span className="newThumbTitle">New Thumbnail</span>
                      </div>
                    </Row>
                    <Row>
                      <div className="thumbnails">
                        <div>
                          {this.state.editingNode.ProductDef.Thumbnail === null || this.state.editingNode.ProductDef.Thumbnail.length === 0 ? (
                            <span>No thumbnail defined</span>
                          ) : (
                            <img
                              title="currentThumb"
                              src={"data:image/png;base64, " + this.state.editingNode.ProductDef.Thumbnail.toString()}
                              className="currentThumb"
                            />
                          )}
                        </div>
                        <div>
                          {this.state.newThumb.length === 0 ? (
                            <span>None</span>
                          ) : (
                            <img title="newThumb" src={"data:image/png;base64, " + this.state.newThumb.toString()} className="newThumb" />
                          )}
                        </div>
                      </div>
                    </Row>
                  </Col>
                </div>
              </Tab>
              <Tab eventKey={ProductTab.Rights} title={"Rights"}>
                <div className="full-width full-height productRights">
                  <Col>
                    <Row>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="startDate">Starting date</Label>
                        <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
                      </FormGroup>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="endDate">Ending date</Label>
                        <Input disabled={this.state.neverExpires} type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="offlineDuration">Offline Access Duration</Label>
                        <Input
                          disabled={this.state.editingNode.ProductDef.OfflineAccessDurationType === Models.DurationType.Permanent}
                          type="number"
                          name="offlineDuration"
                          id="offlineDuration"
                          value={this.state.editingNode.ProductDef.OfflineAccessDuration}
                          onChange={this.offlineDurationChanged}
                        />
                      </FormGroup>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="offlineDurationType">Duration Type</Label>
                        <Input
                          type="select"
                          name="offlineDurationType"
                          id="offlineDurationType"
                          value={this.state.editingNode.ProductDef.OfflineAccessDurationType}
                          onChange={this.offlineDurationTypeChanged}
                        >
                          {Object.keys(Models.DurationType)
                            .filter((key) => isNaN(Number(Models.DurationType[key as keyof typeof Models.DurationType])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.DurationType[it as keyof typeof Models.DurationType]}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="offlineLoginsNumber">Offline logins</Label>
                        <Input
                          type="number"
                          name="offlineLoginsNumber"
                          id="offlineLoginsNumber"
                          value={this.state.editingNode.ProductDef.OfflineLoginsNumber}
                          onChange={this.offlineLoginsChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="neverExpires">
                          <Input onChange={this.neverExpiresChanged} checked={this.state.neverExpires} type="checkbox" name="neverExpires" id="neverExpires" />
                          Never Expires
                        </Label>
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="accessAfterExpiration">
                          <Input
                            onChange={this.accessAfterExpiryChanged}
                            checked={this.state.editingNode.ProductDef.AccessAfterExpiration}
                            type="checkbox"
                            name="accessAfterExpiration"
                            id="accessAfterExpiration"
                          />
                          Allow access after expiry
                        </Label>
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="managePub">
                          <Input onChange={this.canCopyChanged} checked={canCopy} type="checkbox" name="managePub" id="managePub" />
                          Can Copy
                        </Label>
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="canPrint">
                          <Input onChange={this.canPrintChanged} checked={canPrint} type="checkbox" name="canPrint" id="canPrint" />
                          Can Print
                        </Label>
                      </FormGroup>
                    </Row>
                  </Col>
                </div>
              </Tab>
              <Tab eventKey={ProductTab.Titles} title={"Titles"}>
                <div className="full-width full-height productTitles">
                  <p>
                    Select titles that should make up this product. Users with a Licence to a subscription of this product will receive access to <i>all</i>{" "}
                    titles associated to the product. Click a title on the top to remove it from the product and click a title on the bottom to add it to the
                    product.
                  </p>
                  <span className="tableTitle">Associated titles</span>
                  <div className="titleTables">
                    <div className="associatedTitles">
                      <DataTable
                        tableClassName="full-height"
                        headers={["Title", "Ref"]}
                        headerFlexes={[2, 1]}
                        flowProvider={this.localTitlesFlowProvider}
                        initializeFlowProvider={this.initializeLocalTitles}
                        objectBuilder={this.generateTitle}
                        ref={this.associatedTitlesTable}
                        settingsOverride={setting}
                      />
                    </div>
                    <span className="tableTitle">Available titles</span>
                    <div className="availableTitles">
                      <DataTable
                        tableClassName="full-height"
                        headers={["Title", "Ref"]}
                        headerFlexes={[2, 1]}
                        flowProvider={this.availableTitleFlowProvider}
                        initializeFlowProvider={this.initializeAvailableTitles}
                        objectBuilder={this.generateTitle}
                        ref={this.availableTitlesTable}
                        settingsOverride={setting}
                        searchQueryComitted={this.productQueryExecute}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={ProductTab.Conditions} title={"Conditions"}>
                <div className="full-width full-height productConditions">
                  <p>
                    Product conditions are rules that a subscription will check on their product. If one of them is fulfilled, access to the product is granted.
                    Only one condition needs to pass to grant access. They come in the following types, all in JSON data format:
                  </p>
                  <ul>
                    <li>Ip List (a specific list of comma separated public IP adresses)</li>
                    <li>Ip Range (a comma separate list of IP ranges)</li>
                    <li>Concurrent (a concurrent limit of users, the subscription imposes N users using that subscription at a given time)</li>
                    <li>Concurrent Ip Range (combination of Ip Range AND Concurrent)</li>
                    <li>Concurrent Ip List (combination of Ip List AND Concurrent)</li>
                  </ul>
                  <div className="productConditionsTable">
                    <DataTable
                      headers={["Condition Type", "Data"]}
                      headerFlexes={[1, 2]}
                      flowProvider={this.conditionFlowProvider}
                      initializeFlowProvider={this.initializeConditions}
                      objectBuilder={this.generateCondition}
                      ref={this.conditionTable}
                      settingsOverride={setting}
                      rowAddRequested={this.addCondition}
                    />
                  </div>
                  {this.state.editingCondition > -1 && (
                    <Col>
                      <Row>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="conditionType">Condition Type</Label>
                          <Input
                            type="select"
                            name="conditionType"
                            id="conditionType"
                            value={this.state.editingNode.Conditions[this.state.editingCondition].ConditionType}
                            onChange={this.conditionTypeChanged}
                          >
                            {Object.keys(Models.ProductConditionType)
                              .filter((key) => isNaN(Number(Models.ProductConditionType[key as keyof typeof Models.ProductConditionType])))
                              .map((it) => (
                                <option value={it} key={it} data-providerval={it}>
                                  {Models.ProductConditionType[it as keyof typeof Models.ProductConditionType]}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="productDescription">Condition Data</Label>
                          <Input
                            type="textarea"
                            name="productDescription"
                            id="productDescription"
                            onChange={this.conditionDataChanged}
                            value={this.state.editingNode.Conditions[this.state.editingCondition].ConditionData}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <Button onClick={this.deleteCondition} outline color="danger">
                          Delete Condition
                        </Button>
                      </Row>
                    </Col>
                  )}
                </div>
              </Tab>
            </Tabs>

            <FormGroup>
              <Col>
                <Row>
                  <Button onClick={this.saveRequested} style={{ flex: "1", marginRight: this.props.deleteRequested ? "10px" : "" }} outline color="primary">
                    Save Product
                  </Button>
                  {this.props.deleteRequested && this.context.canManageSystem() && (
                    <Button onClick={this.deleteRequested} style={{ flex: "1", marginLeft: "10px" }} outline color="danger">
                      Delete Product
                    </Button>
                  )}
                </Row>
              </Col>
            </FormGroup>
          </div>
        </div>
      </Loading>
    );
  }
}
