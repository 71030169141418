import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { Loading } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";

export interface IEditPublicationPackFormProps {
  initialNode: Models.IPublicationPackViewModel;
  createMode: boolean;
  reloadPublicationPacks?: () => void;
  dismissDrawer: () => void;
}
export interface IEditPublicationPackFormState {
  editingNode: Models.IPublicationPackViewModel;
  activeTab: PublicationPackTab;
  isCreateMode: boolean;
  loading: boolean;
  editingModel: null | number;
}
enum PublicationPackTab {
  Properties,
  PublicationPackDefinition,
}
export class EditPublicationPackForm extends React.Component<IEditPublicationPackFormProps, IEditPublicationPackFormState> {
  context: AppSession;
  static contextType = AppContext;
  publicationPackDefinitionTable = React.createRef<DataTable>();
  userLicenseRangeTable = React.createRef<DataTable>();

  constructor(props: IEditPublicationPackFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      activeTab: props.createMode === true ? PublicationPackTab.PublicationPackDefinition : PublicationPackTab.Properties,
      loading: false,
      editingModel: props.initialNode.PublicationPackDefinition.TableId,
      isCreateMode: props.createMode,
    };
  }

  frontListPricePerAnnumChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.FlatPerAnnum) {
      this.setState((prevState) => ({
        editingNode: {
          ...prevState.editingNode,
          PublicationPack: {
            ...prevState.editingNode.PublicationPack,
            FrontListPricePerAnnum: +e.target.value,
            BackListPricePerAnnum: +e.target.value,
            MaintenancePricePerAnnum: +e.target.value,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, FrontListPricePerAnnum: +e.target.value } },
      }));
    }
  };
  backListPricePerAnnumChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, BackListPricePerAnnum: +e.target.value } },
    }));
  };
  maintenancePricePerAnnumChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, MaintenancePricePerAnnum: +e.target.value } },
    }));
  };
  slotsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, Slots: Math.floor(+e.target.value) } },
    }));
  };
  packNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, PackName: e.target.value } },
    }));
  };
  packDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, PackDescription: e.target.value } },
    }));
  };
  lastBilledChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, LastBilled: dato } },
    }));
  };
  dateCreatedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, DateCreated: dato } },
    }));
  };
  nextBillingChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, PublicationPack: { ...prevState.editingNode.PublicationPack, NextBilling: dato } },
    }));
  };
  describePublicationPack = () => {
    let val = "";
    if (this.state.editingNode.EmptySlotCostPerSlot === this.state.editingNode.PublicationPack.FrontListPricePerAnnum) {
      val = "Front List";
    } else if (this.state.editingNode.EmptySlotCostPerSlot === this.state.editingNode.PublicationPack.BackListPricePerAnnum) {
      val = "Back List";
    } else {
      val = "Maintenance";
    }

    return (
      <div>
        <h4>Summary</h4>
        <p>
          This is your publication pack named &quot;
          {!Convert.isEmptyOrSpaces(this.state.editingNode.PublicationPack.PackName)
            ? this.state.editingNode.PublicationPack.PackName
            : this.state.editingNode.PublicationPackDefinition.PackDefName}
          &quot; created on {Convert.dateToFormattedString(this.state.editingNode.PublicationPack.DateCreated, Languages.English)}. It contains the following
          description:
        </p>
        <p>
          &quot;
          {!Convert.isEmptyOrSpaces(this.state.editingNode.PublicationPack.PackDescription)
            ? this.state.editingNode.PublicationPack.PackDescription
            : this.state.editingNode.PublicationPackDefinition.PackDefDescription}
          &quot;
        </p>
        <h5>
          Current cycle cost breakdown <i>(ending {Convert.dateToFormattedString(this.state.editingNode.PublicationPack.NextBilling, Languages.English)})</i>
        </h5>
        {this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.PerPublication && (
          <div>
            <p>
              <b>Front list slots in use: </b>
              {this.state.editingNode.FrontListSlotsInUse} (Subtotal:{" "}
              {Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.FrontListSubtotal)}).
            </p>
            <p>
              <b>Back list slots in use: </b>
              {this.state.editingNode.BackListSlotsInUse} (Subtotal:{" "}
              {Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.BackListSubtotal)}).
            </p>
            <p>
              <b>Maintenance list slots in use: </b>
              {this.state.editingNode.MaintenanceSlotsInUse} (Subtotal:{" "}
              {Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.MaintenanceSubtotal)}).
            </p>
            <p>
              <b>
                This pack currently has {this.state.editingNode.PublicationPack.RemainingSlots} empty slots at a subtotal of{" "}
                {Convert.toMoney(
                  this.state.editingNode.PublicationPackDefinition.BillingCurrency,
                  this.state.editingNode.EmptySlotCostPerSlot * this.state.editingNode.PublicationPack.RemainingSlots
                )}{" "}
                ({Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.EmptySlotCostPerSlot)} per empty
                slot). Empty slots in this pack are currently in &quot;
                {val}
                &quot; pricing.
              </b>
            </p>
          </div>
        )}
        {this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.FlatPerAnnum && (
          <div>
            <p>
              This pack is billed at a flat rate of{" "}
              {Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.PublicationPack.FrontListPricePerAnnum)}{" "}
              per year.
            </p>
          </div>
        )}
        {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.OneTimeInvoice &&
          this.state.editingNode.UserLicenseRanges.length > 0 && (
            <p>
              <b>Current user licensing cost accrued so far this cycle:</b>{" "}
              {Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.CalculatedUserLicensingCost)} based on{" "}
              {this.state.editingNode.LicensesForInterval} new user licenses during this cycle.
            </p>
          )}
        {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.RecurringPerAnnum &&
          this.state.editingNode.UserLicenseRanges.length > 0 && (
            <p>
              <b>Current user licensing cost accrued so far this cycle:</b>{" "}
              {Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.CalculatedUserLicensingCost)} based on{" "}
              {this.state.editingNode.LicensesForInterval} user licenses existing for titles in this pack.
            </p>
          )}
        {this.state.editingNode.UserLicenseRanges.length <= 0 && (
          <p>This pack has no user licensing ranges defined. No user licensing costs will be accrued for titles in this pack.</p>
        )}

        <p>
          <b>
            Current Subtotal:{" "}
            {Convert.toMoney(
              this.state.editingNode.PublicationPackDefinition.BillingCurrency,
              this.state.editingNode.CalculatedUserLicensingCost + this.state.editingNode.CalculatedPublicationsCost
            )}
          </b>
        </p>
        <h5>Publication pack pricing terms</h5>
        <span>These are the terms for pricing specific to the creation of publications (titles) within the Connect system.</span>
        {this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.FlatPerAnnum && (
          <p>
            This pack is billed at a flat rate of{" "}
            {Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, this.state.editingNode.PublicationPack.FrontListPricePerAnnum)}{" "}
            per year.
          </p>
        )}
        {this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.PerPublication && (
          <div>
            <p>
              This pack is billed at a &quot;per slot&quot; rate where the pack contains <b>{this.state.editingNode.PublicationPack.Slots}</b> slots that are
              priced based on the age of the publication&apos;s latest version. The pricing for this pack goes as follows:
            </p>
            <ul>
              <li>
                Front list price per slot:{" "}
                {Convert.toMoney(
                  this.state.editingNode.PublicationPackDefinition.BillingCurrency,
                  this.state.editingNode.PublicationPack.FrontListPricePerAnnum
                )}{" "}
                (first year)
              </li>
              <li>
                Back list price per slot:{" "}
                {Convert.toMoney(
                  this.state.editingNode.PublicationPackDefinition.BillingCurrency,
                  this.state.editingNode.PublicationPack.BackListPricePerAnnum
                )}{" "}
                (years 2 and 3)
              </li>
              <li>
                Maintenance list price per slot:{" "}
                {Convert.toMoney(
                  this.state.editingNode.PublicationPackDefinition.BillingCurrency,
                  this.state.editingNode.PublicationPack.MaintenancePricePerAnnum
                )}{" "}
                (3+ years)
              </li>
            </ul>
            <p>
              Empty slots go through the same &quot;front/back/maintenance&quot; pricing. If a slot is cleared (title is deleted), the slot may revert to
              maintenance pricing (if applicable).
            </p>
          </div>
        )}
        <h5>User license pricing terms</h5>
        <span>
          These are the terms for pricing licenses created for titles in this pack. A user has a license to a product containing titles that are part of this
          pack.
        </span>
        {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.OneTimeInvoice &&
          this.state.editingNode.UserLicenseRanges.length > 0 && (
            <p>
              This pack is set to &quot;One time invoicing&quot; mode. Only the licenses created during the current billing cycle are counted towards the next
              invoice. Discounts may be attributed at the end of the cycle for bulk license creation (see the user licenses table below). This number is reset
              at the end of each billing cycle.
            </p>
          )}
        {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.RecurringPerAnnum &&
          this.state.editingNode.UserLicenseRanges.length > 0 && (
            <p>
              This pack is set to &quot;Recurring&quot; mode. All (non-expired) licenses existing for titles under this pack are tabulated for invoicing.
              Discounts may be attributed at the end of each cycle based on the number of licenses related to this pack (see the user licenses table below).
            </p>
          )}
        {this.state.editingNode.UserLicenseRanges.length <= 0 && (
          <p>This pack has no user licensing ranges defined. No user licensing costs will be accrued for titles in this pack.</p>
        )}
      </div>
    );
  };
  private initializePublicationPackDefinitions = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowPublicationPackDefinitions({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private publicationPackDefinitionFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowPublicationPackDefinitions({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  firendlyUserLicenseInvoicing(val: Models.UserLicenseInvoicing) {
    switch (val) {
      case Models.UserLicenseInvoicing.RecurringPerAnnum:
        return "Per license Recurring";
      case Models.UserLicenseInvoicing.OneTimeInvoice:
        return "Charged at creation";
      default:
        return Models.UserLicenseInvoicing[val];
    }
  }
  friendlyPublicationPackInvoicing(val: Models.PublicationPackInvoicing) {
    switch (val) {
      case Models.PublicationPackInvoicing.FlatPerAnnum:
        return "Flat rate per pack";
      case Models.PublicationPackInvoicing.PerPublication:
        return "Per publication recurring";
      default:
        return Models.PublicationPackInvoicing[val];
    }
  }

  generatePublicationPackDefinition = (n: INode) => {
    let node = n as Models.IPublicationPackDefinitionViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.PublicationPackDefinition.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={1} key={1} className="centerText" value={node.PublicationPackDefinition.PackDefName} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="centerText" value={node.PublicationPackDefinition.PackDefDescription} />);
    dataItems.push(
      <DataItem flexVal={1} key={3} className="centerText" value={this.firendlyUserLicenseInvoicing(node.PublicationPackDefinition.UserLicenseInvoicing)} />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={4}
        className="centerText"
        value={this.friendlyPublicationPackInvoicing(node.PublicationPackDefinition.PublicationPackInvoicing)}
      />
    );
    dataItems.push(<DataItem flexVal={1} key={5} className="centerText" value={Models.CurrencyCodes[node.PublicationPackDefinition.BillingCurrency]} />);

    return (
      <DataRow
        className={this.state.editingModel !== null && node.PublicationPackDefinition.TableId === this.state.editingModel ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.setPackDefinition}
      />
    );
  };
  setPackDefinition = (e: INode) => {
    if (this.state.isCreateMode) {
      this.setState((prevState) => {
        prevState.editingNode.PublicationPackDefinition = (e as Models.IPublicationPackDefinitionViewModel).PublicationPackDefinition;
        prevState.editingNode.PublicationPack.PublicationPackDefinitionId = (e as Models.IPublicationPackDefinitionViewModel).PublicationPackDefinition.TableId;
        return prevState;
      });
    }
    this.setState({ editingModel: (e as Models.IPublicationPackDefinitionViewModel).PublicationPackDefinition.TableId }, () => {
      this.publicationPackDefinitionTable.current!.reRender();
    });
  };

  private initializeUserLicenseRanges = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowUserLicenseRanges({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublicationPackDefId: this.state.editingNode.PublicationPackDefinition.TableId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private userLicenseRangeFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowUserLicenseRanges({
        FlowRequest: request.Batches[0],
        PublicationPackDefId: this.state.editingNode.PublicationPackDefinition.TableId,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  generateUserLicenseRange = (node: INode) => {
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={1} key={1} className="centerText" value={node.StartRange.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="centerText" value={node.EndRange === -1 ? "Unlimited" : node.EndRange.toString()} />);
    dataItems.push(
      <DataItem
        flexVal={5}
        key={3}
        className="centerText"
        value={Convert.toMoney(this.state.editingNode.PublicationPackDefinition.BillingCurrency, node.FrontListPricePerUser as number)}
      />
    );

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };

  savePublicationPack = () => {
    if (this.isFormValid()) {
      this.setState({ loading: true }, async () => {
        let node = this.state.editingNode.PublicationPack;
        if (this.state.editingModel !== null && this.state.editingModel !== this.state.editingNode.PublicationPackDefinition.TableId) {
          let result = await Messages.Dialog.confirm(
            "You have changed the definition to which this pack belongs. Billing will be calculated on the new pack definition and user ranges will be taken from the new pack definition. Do you with to continue with saving?",
            "Publication Pack Definition Changed",
            Messages.Dialog.Buttons.YesNo
          );
          if (result !== "true") {
            return;
          }
          node.PublicationPackDefinitionId = this.state.editingModel;
        }
        let response = await this.context.insertOrUpdatePublicationPack({ PublicationPack: node });
        if (response.valid()) {
          Messages.Notify.success("Publication pack saved successfully!");
          this.setState({ editingNode: response.data.PublicationPack, isCreateMode: false }, () => {
            if (this.userLicenseRangeTable.current !== null) {
              this.userLicenseRangeTable.current.reload();
            }
            this.publicationPackDefinitionTable.current!.reload();
            this.props.reloadPublicationPacks!();
          });
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  };
  deletePublicationPack = async () => {
    let result = await Messages.Dialog.confirm(
      `Are you sure you wish to delete this Publication Pack? Billing will not be calculated for the current cycle.`,
      `Delete User License Range`
    );
    if (result === "true") {
      if (this.state.editingNode.PublicationPack.RemainingSlots !== this.state.editingNode.PublicationPack.Slots) {
        Messages.Notify.error("Cannot delete a pack containing titles. Move the titles to a different pack first.");
        return;
      }
      let response = await this.context.deletePublicationPack({ PublicationPack: this.state.editingNode.PublicationPack });
      if (response.valid()) {
        Messages.Notify.success("Publication pack deleted successfully!");
        this.props.dismissDrawer();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error(response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  isFormValid = () => {
    if (
      this.state.editingNode.PublicationPack.FrontListPricePerAnnum < 0 ||
      this.state.editingNode.PublicationPack.BackListPricePerAnnum < 0 ||
      this.state.editingNode.PublicationPack.MaintenancePricePerAnnum < 0
    ) {
      Messages.Notify.error("Billing price cannot be negative.");
      return false;
    } else if (this.state.editingNode.PublicationPack.Slots < 0) {
      Messages.Notify.error("Slot count cannot be negative.");
      return false;
    } else if (this.state.editingNode.PublicationPackDefinition.TableId <= 0 && (this.state.editingModel === null || this.state.editingModel <= 0)) {
      Messages.Notify.error("A publication pack definition must be set.");
      return false;
    }
    return true;
  };

  render() {
    let lastBilled = Convert.formatDateForForm(new Date(this.state.editingNode.PublicationPack.LastBilled));
    let nextBilling = Convert.formatDateForForm(new Date(this.state.editingNode.PublicationPack.NextBilling));
    let dateCreated = Convert.formatDateForForm(new Date(this.state.editingNode.PublicationPack.DateCreated));

    let allRanges = JSON.parse(JSON.stringify(Models.genericDataSettings));
    allRanges.batchSize = 10000;
    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Publication Pack...">
        <div className="form-container full-width full-height">
          <h3>
            Publication pack management:{" "}
            {!Convert.isEmptyOrSpaces(this.state.editingNode.PublicationPack.PackName)
              ? this.state.editingNode.PublicationPack.PackName
              : this.state.editingNode.PublicationPackDefinition.PackDefName}
          </h3>
          <div className="billing-tabs">
            <Tabs defaultActiveKey={this.state.activeTab} id="billingTabs">
              {this.context.canManageSystem() && (
                <Tab eventKey={PublicationPackTab.PublicationPackDefinition} title={"Pack definition"}>
                  <div className="full-width full-height publicationPackRights">
                    <p>
                      As an admin you can transfer Publication Packs to other publication pack definitions. This can be useful when you want to create new terms
                      and user licensing ranges specific to a pack without affecting other publishers with packs in the same definition.
                    </p>
                    <p>
                      To change the publication pack definition of the current pack, click on the new pack to assign it to and click &quot;save publication
                      pack&quot; at the bottom of this window. The new publication pack definition will be used to calculate billing for this term.
                    </p>
                    <DataTable
                      headers={["Definition name", "Definition description", "User license invoicing type", "Pack invoicing type", "Billing currency"]}
                      headerFlexes={[1, 2, 1, 1, 1]}
                      flowProvider={this.publicationPackDefinitionFlowProvider}
                      initializeFlowProvider={this.initializePublicationPackDefinitions}
                      objectBuilder={this.generatePublicationPackDefinition}
                      ref={this.publicationPackDefinitionTable}
                      settingsOverride={Models.genericDataSettings}
                    />
                  </div>
                </Tab>
              )}
              <Tab eventKey={PublicationPackTab.Properties} title={"Summary"}>
                <div className="full-width full-height publicationPackProperties">
                  {!this.state.isCreateMode && this.describePublicationPack()}
                  {this.state.editingNode.UserLicenseRanges.length > 0 && (
                    <div>
                      <p>
                        Here are the user licensing ranges for the current publication pack. Licenses created or associated to titles in this pack are charged
                        along the tiers outlined below.
                      </p>
                      {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.OneTimeInvoice && (
                        <p>
                          This selected pack is billed once per license creation and does not recurr during subsequent billing cycles. Billing is calculated
                          based on the number of license associations made during the active cycle and discounts may apply. This count is resent every cycle.
                          See ranges below.
                        </p>
                      )}
                      {this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.RecurringPerAnnum && (
                        <p>
                          This selected pack is billed based on the number of licenses existing for titles in this pack. Discounts may be applied based on the
                          number of active (non-expired) licenses for titles in this pack. See ranges below.
                        </p>
                      )}
                      <DataTable
                        headers={[
                          "License start range",
                          "License end range",
                          "Cost per license" +
                            (this.state.editingNode.PublicationPackDefinition.UserLicenseInvoicing === Models.UserLicenseInvoicing.RecurringPerAnnum
                              ? " (per year)"
                              : " created in a year"),
                        ]}
                        headerFlexes={[1, 1, 5]}
                        flowProvider={this.userLicenseRangeFlowProvider}
                        initializeFlowProvider={this.initializeUserLicenseRanges}
                        objectBuilder={this.generateUserLicenseRange}
                        ref={this.userLicenseRangeTable}
                        settingsOverride={allRanges}
                      />
                    </div>
                  )}
                  {this.context.canManageSystem() && (
                    <Col>
                      <Row>
                        <h2 style={{ marginTop: "15px" }}>Admin section</h2>
                        {this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.PerPublication && (
                          <div className="full-height full-width">
                            <FormGroup>
                              <Label for="frontListPricePerAnnum">Price per frontlist slot yearly (this pack definition is invoiced by the slot)</Label>
                              <Input
                                value={this.state.editingNode.PublicationPack.FrontListPricePerAnnum}
                                onChange={this.frontListPricePerAnnumChanged}
                                type="number"
                                name="frontListPricePerAnnum"
                                id="frontListPricePerAnnum"
                                placeholder="Front List Price Per Annum"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="backListPricePerAnnum">Price per backlist slot yearly (this pack definition is invoiced by the slot)</Label>
                              <Input
                                value={this.state.editingNode.PublicationPack.BackListPricePerAnnum}
                                onChange={this.backListPricePerAnnumChanged}
                                type="number"
                                name="backListPricePerAnnum"
                                id="backListPricePerAnnum"
                                placeholder="Back List Price Per Annum"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="maintenancePricePerAnnum">Price per maintenance slot yearly (this pack definition is invoiced by the slot)</Label>
                              <Input
                                value={this.state.editingNode.PublicationPack.MaintenancePricePerAnnum}
                                onChange={this.maintenancePricePerAnnumChanged}
                                type="number"
                                name="maintenancePricePerAnnum"
                                id="maintenancePricePerAnnum"
                                placeholder="Maintenance List Price Per Annum"
                              />
                            </FormGroup>
                          </div>
                        )}
                        {this.state.editingNode.PublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.FlatPerAnnum && (
                          <div className="full-height full-width">
                            <FormGroup>
                              <Label for="frontListPricePerAnnum">Publication pack flat price per year</Label>
                              <Input
                                value={this.state.editingNode.PublicationPack.FrontListPricePerAnnum}
                                onChange={this.frontListPricePerAnnumChanged}
                                type="number"
                                name="frontListPricePerAnnum"
                                id="frontListPricePerAnnum"
                                placeholder="Front List Price Per Annum"
                              />
                            </FormGroup>
                          </div>
                        )}
                      </Row>
                      <Row>
                        <FormGroup style={{ marginRight: "15px" }}>
                          <Label for="slotsInPack">Slots in this pack</Label>
                          <Input
                            value={this.state.editingNode.PublicationPack.Slots}
                            onChange={this.slotsChanged}
                            type="number"
                            name="slotsInPack"
                            id="slotsInPack"
                            placeholder="Slots in this pack"
                          />
                        </FormGroup>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="packName">Pack name</Label>
                          <Input
                            value={this.state.editingNode.PublicationPack.PackName}
                            onChange={this.packNameChanged}
                            type="text"
                            name="packName"
                            id="packName"
                            placeholder="Pack name"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="packDescription">Pack description</Label>
                          <Input
                            value={this.state.editingNode.PublicationPack.PackDescription}
                            onChange={this.packDescriptionChanged}
                            type="text"
                            name="packDescription"
                            id="packDescription"
                            placeholder="Pack description"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                          <Label for="dateCreated">Date created</Label>
                          <Input type="date" name="dateCreated" id="dateCreated" value={dateCreated} onChange={this.dateCreatedChanged} />
                        </FormGroup>
                        <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                          <Label for="lastBilled">Last billed</Label>
                          <Input type="date" name="lastBilled" id="lastBilled" value={lastBilled} onChange={this.lastBilledChanged} />
                        </FormGroup>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="endDate">Next billing date</Label>
                          <Input type="date" name="endDate" id="endDate" value={nextBilling} onChange={this.nextBillingChanged} />
                        </FormGroup>
                      </Row>
                    </Col>
                  )}
                </div>
              </Tab>
            </Tabs>
            {this.context.canManageSystem() && (
              <FormGroup>
                <Col>
                  <Row>
                    <Button style={{ flex: "1", marginRight: "10px" }} outline color="primary" onClick={this.savePublicationPack}>
                      Save Publication Pack
                    </Button>
                    {!this.state.isCreateMode && (
                      <Button style={{ flex: "1", marginLeft: "10px" }} outline color="danger" onClick={this.deletePublicationPack}>
                        Delete Publication Pack
                      </Button>
                    )}
                  </Row>
                </Col>
              </FormGroup>
            )}
          </div>
        </div>
      </Loading>
    );
  }
}
