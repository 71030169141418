import * as React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { PermissionInviteForm } from "src/ui/foundation/Controls/PermissionInviteForm";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";

export interface ICuratorPermissionFormProps {
  initialNode: Models.IPermissionViewModel;
  saveRequested?: (node: Models.IPermissionViewModel) => void;
  reloadPermissions: () => void;
  goBackBtnClicked?: () => void;
}
export interface ICuratorPermissionFormState {
  editingNode: Models.IPermissionViewModel;
  currentStep: number;
  expiresNever: boolean;
}
export class CuratorPermissionForm extends React.Component<ICuratorPermissionFormProps, ICuratorPermissionFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ICuratorPermissionFormProps) {
    super(props);
    this.state = { editingNode: this.props.initialNode, currentStep: 1, expiresNever: false };
  }
  expiryChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    if (arg.target.valueAsDate === null) {
      return;
    }
    arg.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Permission: { ...prevState.editingNode.Permission, ExpirationDate: new Date(arg.target.valueAsDate!) } },
    }));
  };
  formValid = () => {
    if (this.state.currentStep === 1) {
      if (
        !this.state.editingNode.Permission.ManageProducts &&
        !this.state.editingNode.Permission.ManagePublishers &&
        !this.state.editingNode.Permission.ManageSubscription &&
        !this.state.editingNode.Permission.ManageSystem &&
        !this.state.editingNode.Permission.ManageTitles
      ) {
        return false;
      }
      if (this.state.editingNode.Permission.ExpirationDate !== null && new Date(this.state.editingNode.Permission.ExpirationDate) < new Date()) {
        return false;
      }
    }
    return true;
  };

  moveStepForward = () => {
    if (this.state.editingNode.Permission.ManageSystem) {
      this.setState({ currentStep: this.state.currentStep + 2 });
    } else {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };
  moveStepBackwards = () => {
    if (this.state.editingNode.Permission.ManageSystem) {
      this.setState({ currentStep: this.state.currentStep - 2 });
    } else {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  };
  expiresNeverChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ExpirationDate: arg.target.checked === false ? new Date(new Date().setFullYear(new Date().getFullYear() + 1)) : null,
        },
      },
      expiresNever: arg.target.checked,
    }));
  };
  getStepControls = (): JSX.Element => {
    if (this.state.currentStep === 1) {
      let isExpired = false;
      if (this.state.editingNode.Permission.ExpirationDate !== null && new Date(this.state.editingNode.Permission.ExpirationDate) < new Date()) {
        isExpired = true;
      }

      let expiryControl: JSX.Element;
      if (this.state.editingNode.Permission.ExpirationDate === null) {
        expiryControl = <Input disabled readOnly style={{ color: isExpired ? "red" : "" }} value={"Never"} type="text" name="expiration" id="expiration" />;
      } else {
        let date = Convert.formatDateForForm(new Date(this.state.editingNode.Permission.ExpirationDate));
        expiryControl = (
          <Input
            disabled={this.state.expiresNever}
            readOnly={this.state.expiresNever}
            style={{ color: isExpired ? "red" : "" }}
            type="date"
            name="expiration"
            id="expiration"
            value={date}
            onChange={this.expiryChanged}
          />
        );
      }

      return (
        <Col>
          <Row>
            <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
              <Label for="expiration">Expiry Date (UTC)</Label>
              {expiryControl}
              <Label style={{ marginLeft: "25px", marginTop: "15px" }} for="neverExp">
                <Input name="neverExp" id="neverExp" type="checkbox" onChange={this.expiresNeverChanged} />
                Never Expires
              </Label>
            </FormGroup>
          </Row>
          <h3>Permissions</h3>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageSubs">
                <Input readOnly checked={true} disabled={true} type="checkbox" name="manageSubs" id="manageSubs" />
                Manage Subscription
              </Label>
            </FormGroup>
          </Row>
        </Col>
      );
    } else if (this.state.currentStep === 2) {
      return (
        <Col>
          <h3>Additional Details</h3>
          <Row>
            <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
              <Label for="publisher">Publisher</Label>
              <Input
                id="publisher"
                type="select"
                name="brandKey"
                value={this.state.editingNode.Permission.PublisherId === null ? undefined : this.state.editingNode.Permission.PublisherId}
                disabled
                readOnly
              >
                <option value={-1}>All</option>
                {this.context.loginLibraries
                  .rows()
                  .sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))
                  .map((lib) => (
                    <option value={lib.PublisherId} key={lib.PublisherId}>
                      {lib.DisplayName}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Row>
          {this.state.editingNode.Permission.ManageSubscription && (
            <Row>
              <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                <h3>Subscription ID</h3>
                <span>
                  If set, user will be able to extend Licences to this subscription, but not manipulate the subscription itself. If left empty, the user can
                  create, modify, view all subscriptions and customers for the publisher and extend Licences.
                </span>
                <Input
                  type="number"
                  name="subId"
                  value={this.state.editingNode.Permission.SubscriptionId === null ? "" : this.state.editingNode.Permission.SubscriptionId}
                  disabled={this.state.editingNode.Permission.PublisherId === null}
                  readOnly={this.state.editingNode.Permission.PublisherId === null}
                />
              </FormGroup>
            </Row>
          )}
        </Col>
      );
    } else if (this.state.currentStep === 3) {
      return (
        <PermissionInviteForm
          goBackBtnClicked={this.props.goBackBtnClicked}
          permission={this.state.editingNode.Permission}
          wrapInForm={false}
          reloadPermissions={this.props.reloadPermissions}
        />
      );
    }
    return <span>Invalid step</span>;
  };
  render() {
    let controls = this.getStepControls();

    return (
      <Form>
        <Col>
          {controls}
          <Row className="formButtons">
            {this.state.currentStep > 1 && (
              <Button disabled={!this.formValid()} onClick={this.moveStepBackwards} color="info" outline>
                Previous
              </Button>
            )}
            {this.state.currentStep < 3 && (
              <Button disabled={!this.formValid()} onClick={this.moveStepForward} color="info" outline>
                Next
              </Button>
            )}
          </Row>
        </Col>
      </Form>
    );
  }
}
