import * as React from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';

export interface IAboutInfoFormProps {
  initialNode: Models.IAboutInfoViewModel;
  internationalization: Models.ILibraryInternationalizationViewModel;
  deleteRequested?: (node: Models.IAboutInfoViewModel) => void;
  saveRequested?: (node: Models.IAboutInfoViewModel) => void;
  dismissRequested?: () => void;
}
export interface IAboutInfoFormState {
  editingNode: Models.IAboutInfoViewModel;
}
export class AboutInfoForm extends React.Component<IAboutInfoFormProps, IAboutInfoFormState> {
  constructor(props: IAboutInfoFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
    };
  }
  checkFileType = (event: any) => {
    // getting file object
    let files = event.target.files;
    // define message container
    let err = "";
    // list allow mime type
    const types = ["text/html"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err = "Invalid file type (expected file type is .html)";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      Messages.Notify.error(err);
      return false;
    }
    return true;
  };

  handleAboutHtmlUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkFileType(e)) {
      if (files && files.length > 0) {
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = () => {
          let basedata = reader.result;
          if (basedata === null) {
            return;
          }
          basedata = basedata.toString();
          let baseDataString = basedata;
          this.setState((prevState) => ({
            editingNode: {
              ...prevState.editingNode,
              AboutInfo: {
                LibraryInternationalizationTableId: prevState.editingNode.AboutInfo.LibraryInternationalizationTableId,
                Title: prevState.editingNode.AboutInfo.Title,
                TableId: prevState.editingNode.AboutInfo.TableId,
                Html: baseDataString,
              },
            },
          }));
        };
        reader.onerror = (error) => error;
        Messages.Notify.success("File uploaded successfully");
      }
    }
  };

  htmlChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        AboutInfo: {
          ...prevState.editingNode.AboutInfo,
          Html: e.target.value,
        },
      },
    }));
  };

  titleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        AboutInfo: {
          ...prevState.editingNode.AboutInfo,
          Title: e.target.value,
        },
      },
    }));
  };

  downloadHtml = () => {
    let element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(this.state.editingNode.AboutInfo.Html));
    element.setAttribute("download", this.state.editingNode.AboutInfo.Title + "_html.html");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  formValid = () => {
    if (this.state.editingNode.AboutInfo.Html === null) {
      return false;
    }

    return true;
  };

  render() {
    return (
      <Form className="aboutInfoForm">
        <Col>
          <Row>
            <FormGroup check style={{ flex: "1" }} className="titleInput">
              <Label check style={{ paddingLeft: "10px", paddingTop: "10px" }} for="title-text" className="input-label">
                Title
              </Label>
              <Input type="text" value={this.state.editingNode.AboutInfo.Title} onChange={this.titleChanged} name="title" id="title-text" />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }} className="htmlInput">
              <div style={{ paddingLeft: "10px", paddingTop: "10px", flex: "1" }}>HTML</div>
              <InputGroup className="htmlInputs">
                <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="html-input" className="input-label">
                  Click to Upload an .html file
                  <Input onChange={this.handleAboutHtmlUpload} type="file" name="aboutHtml" id="html-input" accept=".html" />
                </Label>
                <Button onClick={this.downloadHtml}>Download current HTML</Button>
              </InputGroup>
            </FormGroup>
          </Row>
        </Col>
        <Col>
          <Row className="formButtons">
            {this.props.saveRequested && (
              <Button onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                Save
              </Button>
            )}
            {this.props.deleteRequested && (
              <Button onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>
                Delete
              </Button>
            )}
            {this.props.dismissRequested && (
              <Button onClick={() => this.props.dismissRequested!()} color="info" outline>
                Cancel
              </Button>
            )}
          </Row>
        </Col>
      </Form>
    );
  }
}