import * as React from "react";
import { Button, Col, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession, LoginType } from "src/models/AppSession";
import { IInvitation, IInvitationViewModel, ILicenceInviteViewModel, InvitationType, IPermissionViewModel } from "src/models/dto/DashboardModels";
import { Convert } from "src/utilities/Helpers";

import { Loading } from "../foundation/Controls";
import * as Messages from "../foundation/Messages";
import { AppContext } from "../state/Contextes";
import { LoginView } from "./LoginView/LoginView";

export interface IInviteViewProps {
  inviteCode: string;
  clearInvite: () => void;
}
export interface IInviteViewState {
  invitation: IInvitationViewModel | null;
}
export class InviteView extends React.Component<IInviteViewProps, IInviteViewState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IInviteViewProps) {
    super(props);
    this.state = { invitation: null };
  }
  async componentDidMount() {
    let response = await this.context.getInvitationAndItem({ InvitationCode: this.props.inviteCode });
    if (response.valid()) {
      this.setState({ invitation: response.data.Invitation });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Invitation failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      this.props.clearInvite();
    }
  }
  render() {
    return (
      <div className="darkOverlay">
        <div className="inviteView">
          <Loading isLoading={this.state.invitation === null}>
            {this.state.invitation !== null && this.state.invitation.Invitation.InvitationType === InvitationType.Permission && (
              <PermissionInvite
                clearInvite={this.props.clearInvite}
                invitation={this.state.invitation.Invitation}
                permission={this.state.invitation.PermissionModel!}
              />
            )}
            {this.state.invitation !== null && this.state.invitation.Invitation.InvitationType === InvitationType.Licence && (
              <LicenceInvite invitation={this.state.invitation.Invitation} licence={this.state.invitation.Licence!} clearInvite={this.props.clearInvite} />
            )}
          </Loading>
        </div>
      </div>
    );
  }
}
interface IPermissionInviteProps {
  invitation: IInvitation;
  permission: IPermissionViewModel;
  clearInvite: () => void;
}
interface IPermissionInviteState {
  localLogin: LoginType;
}
class PermissionInvite extends React.Component<IPermissionInviteProps, IPermissionInviteState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IPermissionInviteProps) {
    super(props);
  }
  componentDidMount() {
    this.context.loginSuccess.on(this.loginChanged);
    this.loginChanged();
  }
  componentWillUnmount() {
    this.context.loginSuccess.off(this.loginChanged);
  }
  loginChanged = () => {
    this.setState({ localLogin: this.context.login });
  };
  describePermission = (): JSX.Element => {
    let permission = this.props.permission.Permission;
    let perms: JSX.Element[] = [];
    if (permission.ManageSystem) {
      perms.push(
        <li key="system">
          Can manage the <b>entire system.</b>
        </li>
      );
    }
    if (permission.ManagePublishers) {
      perms.push(
        <li key="publisher">
          Can manage the properties of the publisher and interact with all child elements such as <b>Subscription, Products and Titles</b>. Highest level of
          power that is not admin. Can modify and view the <b>LibraryConfig</b>.
        </li>
      );
    }
    if (permission.ManageProducts) {
      perms.push(
        <li key="product">
          Can manage the products for the publisher. This does not give access to <b>Subscriptions</b> or <b>Titles</b>.
        </li>
      );
    }
    if (permission.ManageTitles) {
      perms.push(
        <li key="title">
          Can manage the titles for the publisher. Will see all titles in the <b>Reader</b> and can use Build to publish and upload new content.
        </li>
      );
    }
    if (permission.ManageSubscription && permission.SubscriptionId === null) {
      perms.push(
        <li key="subscription">
          Can manage all the <b>subscriptions</b> for the publisher. This includes their <b>creation, attribution, removal and customer management</b>.
        </li>
      );
    }
    if (permission.ManageSubscription && permission.SubscriptionId !== null) {
      perms.push(
        <li key="subscription">
          Can attribute Licences to a subscription with ID <b>{permission.SubscriptionId}</b>.
        </li>
      );
    }
    return (
      <div>
        <span>
          You are accepting a permission expiring{" "}
          <b>{permission.ExpirationDate === null ? "Never" : Convert.dateToFormattedString(permission.ExpirationDate, Languages.English)}</b> granting the
          following abilities on publisher{" "}
          <b>
            {permission.PublisherId !== null ? this.context.loginLibraries.rows().find((x) => permission.PublisherId === x.PublisherId)!.DisplayName : "All"}
          </b>
          :
        </span>
        <ul>{perms}</ul>
        <span>
          If you are logged in, you may attribute this permission to your logged in account or logout and login with a different account to accept the
          permission on.
        </span>
      </div>
    );
  };
  logout = async () => {
    let res = await this.context.logoutDashboard({});
    if (!res.valid()) {
      if (res.errors.length > 0) {
        Messages.Notify.error("Invitation failed. Server reported: " + res.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  acceptPermission = async () => {
    let response = await this.context.executePermissionInvitation({ InvitationCode: this.props.invitation.TableGuid });
    if (response.valid()) {
      Messages.Notify.success(`The Permission was attributed successfully!`);
      this.props.clearInvite();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  render() {
    let loginView = <LoginView />;
    return (
      <div className="permissionInviteContainer">
        <div className="permissionDescription">
          <h1>You have been invited to accept a permission!</h1>
          {this.describePermission()}
        </div>
        <div className="loginInfo">
          {this.context.login > LoginType.None ? (
            <div className="loggedIn">
              <span>
                You&apos;re logged in as user {this.context.userName}. Would you like to accepth this permission on your account or log into a different
                account?
              </span>
              <Col>
                <Row>
                  <Button onClick={this.logout} outline color="danger">
                    Logout
                  </Button>
                  <Button onClick={this.acceptPermission} outline color="primary">
                    Accept Permission
                  </Button>
                </Row>
              </Col>
            </div>
          ) : (
            loginView
          )}
        </div>
      </div>
    );
  }
}

interface ILicenceInviteProps {
  invitation: IInvitation;
  licence: ILicenceInviteViewModel;
  clearInvite: () => void;
}

interface ILicenceInviteState {
  localLogin: LoginType;
}
class LicenceInvite extends React.Component<ILicenceInviteProps, ILicenceInviteState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ILicenceInviteProps) {
    super(props);
  }
  componentDidMount() {
    this.context.loginSuccess.on(this.loginChanged);
    this.loginChanged();
  }
  componentWillUnmount() {
    this.context.loginSuccess.off(this.loginChanged);
  }
  loginChanged = () => {
    this.setState({ localLogin: this.context.login });
  };
  describeLicence = (): JSX.Element => (
    <div>
      <span>
        You are accepting a Licence expiring{" "}
        <b>
          {this.props.licence.Subscription.EndDate === null
            ? "Never"
            : Convert.dateToFormattedString(this.props.licence.Subscription.EndDate, Languages.English)}
        </b>{" "}
        granting access to the following product from publisher{" "}
        <b>
          {this.props.licence.Product.PublisherId !== null
            ? this.context.loginLibraries.rows().find((x) => this.props.licence.Product.PublisherId === x.PublisherId)!.DisplayName
            : "All"}
        </b>
        :{" "}
      </span>
      <p>
        <i>{this.props.licence.Product.Name + " (" + this.props.licence.Product.ProductCode + ")"}</i>
      </p>
      <p>
        If you are logged in, you may attribute this licence to your logged in account or logout and login with a different account to accept the licence on.
      </p>
    </div>
  );
  logout = async () => {
    let res = await this.context.logoutDashboard({});
    if (!res.valid()) {
      if (res.errors.length > 0) {
        Messages.Notify.error("Invitation failed. Server reported: " + res.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  acceptLicence = async () => {
    let response = await this.context.executeLicenceInvitation({ InvitationCode: this.props.invitation.TableGuid });
    if (response.valid()) {
      Messages.Notify.success(`The Licence was attributed successfully!`);
      this.props.clearInvite();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  render() {
    let loginView = <LoginView />;
    return (
      <div className="permissionInviteContainer">
        <div className="permissionDescription">
          <h1>You have been invited to accept a Licence!</h1>
          <Row>
            {this.props.licence.Product.Thumbnail.length > 0 && (
              <Col>
                <img title="productThumbnail" className={"productImage"} src={"data:image/png;base64, " + this.props.licence.Product.Thumbnail.toString()} />
              </Col>
            )}
            <Col>{this.describeLicence()}</Col>
          </Row>
        </div>
        <div className="loginInfo">
          {this.context.login > LoginType.None ? (
            <div className="loggedIn">
              <span>
                You&apos;re logged in as user {this.context.userName}. Would you like to accept this licence on your account or log into a different account?
              </span>
              <Col>
                <Row>
                  <Button onClick={this.logout} outline color="danger">
                    Logout
                  </Button>
                  <Button onClick={this.acceptLicence} outline color="primary">
                    Accept Licence
                  </Button>
                </Row>
              </Col>
            </div>
          ) : (
            loginView
          )}
        </div>
      </div>
    );
  }
}
