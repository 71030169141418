import { Dictionary } from 'src/collections/Generics';
import { Single, Table } from 'src/collections/Observable';
import { AjaxRequestor, IRequestor } from 'src/network/Requestor';
import { Wire } from 'src/network/Wire';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Dialog, IDialogItemProps, INotification, Notify } from 'src/ui/foundation/Messages';
import { EventHandler, LoadingReport, SingleLoadingReport } from 'src/utilities/Events';
import { Communication } from 'src/utilities/Helpers';

import { Locale } from '../localization/Locale';
import * as AccountRequest from './dto/AccountRequest';
import * as AppRequest from './dto/AppRequest';
import * as BillingRequest from './dto/BillingRequest';
import * as CustomersRequest from './dto/CustomerRequest';
import * as Models from './dto/DashboardModels';
import * as EmailTemplateRequest from './dto/EmailTemplateRequest';
import * as InviteRequest from './dto/InviteRequest';
import * as LicenceRequest from './dto/LicenceRequest';
import * as LoginRequest from './dto/LoginRequest';
import * as ProductRequest from './dto/ProductRequest';
import * as PublisherRequest from './dto/PublisherRequest';
import * as ReportingRequest from './dto/ReportingRequest';
import * as SubscriptionRequest from './dto/SubscriptionRequest';
import * as SystemRequest from './dto/SystemRequest';
import * as TitlesRequest from './dto/TitlesRequest';
import * as UserFormRequest from './dto/UserFormRequest';
import { ActionResult } from './Result';

export enum AppLoadingSteps {
  data = "data",
}
export enum LoadingState {
  Seen,
  Loading,
  Loaded,
}
export enum LoginType {
  None = 0,
  Anonymous = 1,
  User = 2,
}
export class AppSession {
  notifications: Table<INotification>;
  dialog: Single<IDialogItemProps | undefined>;
  viewedViews: Dictionary<DashboardView, SingleLoadingReport>;
  wire: Wire;
  appLoading: LoadingReport;
  localization: Locale;
  currentConfig: Single<SettingsTarget>;
  originalConfig: Single<SettingsTarget>;
  appBrandKey: string;
  login: LoginType;
  loginLibraries: Table<Models.ILoginLibrary>;
  session: string | undefined;
  userName: string;
  userPermissions: Models.IPermission[];
  navigateToView: EventHandler<DashboardView>;
  loginSuccess: EventHandler<any>;
  logoutSuccess: EventHandler<any>;
  user: Models.IUserAccount | null;
  ssoIdToken: string;
  ssoLogout: boolean;
  externalLinkingCurrentToken: string;
  protocolId: number;
  constructor() {
    let requestor: IRequestor;

    this.localization = new Locale();

    let location = window.location.href;
    let parts = location.split("/");
    let apiUrl = parts[0] + "//" + parts[2] + "/api/";
    requestor = new AjaxRequestor(apiUrl);

    this.currentConfig = new Single<SettingsTarget>(0);
    this.originalConfig = new Single<SettingsTarget>(0);
    this.wire = Wire.create(requestor);
    this.appLoading = new LoadingReport();
    this.notifications = Notify.notificationList.notifications;
    this.dialog = Dialog.currentDialog;
    this.viewedViews = new Dictionary<DashboardView, SingleLoadingReport>();

    this.login = LoginType.None;
    this.loginLibraries = new Table<Models.ILoginLibrary>((i: Models.ILoginLibrary) => i.BrandKey);
    this.navigateToView = new EventHandler<DashboardView>();
    this.loginSuccess = new EventHandler<any>();
    this.logoutSuccess = new EventHandler<any>();
    this.userPermissions = [];
    this.ssoIdToken = "";
    this.ssoLogout = false;
    for (let item in DashboardView) {
      if (!isNaN(Number(item))) {
        this.viewedViews.set(Number(item), new SingleLoadingReport());
      }
    }
    this.wire.addRequestTransform((request) => {
      request.Session = this.getSession();
      return request;
    });
  }

  public async initialize() {
    if (this.appLoading.isLoading(AppLoadingSteps.data) || this.appLoading.isLoaded(AppLoadingSteps.data)) {
      return;
    }
    this.appLoading.setLoading(AppLoadingSteps.data);

    try {
      let result = await this.getConfigs({});
      this.loginLibraries.insertOrUpdateAll(result.data.AvailableConfigs);
      this.appLoading.setLoaded(AppLoadingSteps.data);
      let loginInitResult = await this.initializeLogin({});
      if (loginInitResult.data.LoggedInSession !== null) {
        this.initSession(loginInitResult.data.LoggedInSession);
        this.protocolId = loginInitResult.data.LoggedInSession.ProtocolId;
      }
    } catch (e) {
      console.log(e);
    }
    return;
  }
  // to update the Login Library on Update
  public async updateLoginLibraries() {
    let result = await this.getConfigs({});
    this.loginLibraries.insertOrUpdateAll(result.data.AvailableConfigs);
    this.appLoading.setLoaded(AppLoadingSteps.data);
    return;
  }

  async logInfo(request: AppRequest.ILogInfo): Promise<ActionResult> {
    return Communication.handleResponse<AppRequest.ILogInfoR>(this.wire, "application/logInfo", request, () => {});
  }

  // LOGIN COMMS
  async initializeLogin(request: AppRequest.IInitialize): Promise<ActionResult<AppRequest.IInitializeR>> {
    return Communication.handleResponse<AppRequest.IInitializeR>(this.wire, "application/initialize", request, (data, result) => {
      result.data = data;
    });
  }
  async getConfigs(request: LoginRequest.ILoginGetConfigs): Promise<ActionResult<LoginRequest.ILoginGetConfigsR>> {
    return Communication.handleResponse<LoginRequest.ILoginGetConfigsR>(this.wire, "login/getConfigs", request, (data, result) => {
      result.data = data;
    });
  }
  async loginDashboard(request: {
    brandKey: string;
    username: string;
    password: string;
    rememberMe: boolean;
    width: number;
    height: number;
    isAnonymous: boolean;
  }): Promise<ActionResult<LoginRequest.ILoginR>> {
    return Communication.handleResponse<LoginRequest.ILoginR>(this.wire, "login/login", request, (data, result) => {
      result.data = data;
      if (data.Result === Models.LoginResult.Online) {
        this.initSession(data);
        this.userName = data.Username;
        this.protocolId = data.ProtocolId;
      }
    });
  }
  async ssoLogin(username: string, password: string, width: number, height: number, isAnonymous: boolean) {
    return Communication.handleResponse<LoginRequest.ILoginR>(
      this.wire,
      "login/ssoLogin",
      {
        Username: username,
        Password: password,
        Height: height,
        Width: width,
        IsAnonymous: isAnonymous,
        Token: this.externalLinkingCurrentToken,
      } as LoginRequest.ILogin,
      (data, result) => {
        if (data.Result === Models.LoginResult.Online) {
          this.initSession(data);
          this.userName = data.Username;
          this.protocolId = data.ProtocolId;
        } else {
          if (data.Result === Models.LoginResult.InvalidCredentials) {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_LOGIN_INVALID_PROMPT });
          } else if (data.Result === Models.LoginResult.ConnectionFailed) {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_CONNECTION_FAILED });
          } else {
            result.addError({ Message: this.localization.currentLocale.LoginView.ALERT_LOGIN_ERROR });
          }
        }
      }
    );
  }
  async logoutDashboard(request: LoginRequest.ILogout): Promise<ActionResult<LoginRequest.ILogoutR>> {
    return Communication.handleResponse<LoginRequest.ILogoutR>(this.wire, "login/logout", request, (data, result) => {
      result.data = data;
      this.terminateSession();
    });
  }
  async createUserAccount(request: LoginRequest.ILoginCreateAccount): Promise<ActionResult<LoginRequest.ILoginCreateAccountR>> {
    return Communication.handleResponse<LoginRequest.ILoginCreateAccountR>(this.wire, "login/createUserAccount", request, (data, result) => {
      result.data = data;
    });
  }
  async confirmUserAccount(request: LoginRequest.ILoginConfirmAccount): Promise<ActionResult<LoginRequest.ILoginConfirmAccountR>> {
    return Communication.handleResponse<LoginRequest.ILoginConfirmAccountR>(this.wire, "login/confirmUserAccount", request, (data, result) => {
      result.data = data;
    });
  }
  async resetPassword(request: LoginRequest.ILoginResetPassword): Promise<ActionResult<LoginRequest.ILoginResetPasswordR>> {
    return Communication.handleResponse<LoginRequest.ILoginResetPasswordR>(this.wire, "login/resetPassword", request, (data, result) => {
      result.data = data;
    });
  }
  async confirmResetPassword(request: LoginRequest.ILoginConfirmResetPassword): Promise<ActionResult<LoginRequest.ILoginConfirmResetPasswordR>> {
    return Communication.handleResponse<LoginRequest.ILoginConfirmResetPasswordR>(this.wire, "login/confirmResetPassword", request, (data, result) => {
      result.data = data;
    });
  }
  async validateResetToken(request: LoginRequest.ILoginValidateResetToken): Promise<ActionResult<LoginRequest.ILoginValidateResetTokenR>> {
    return Communication.handleResponse<LoginRequest.ILoginValidateResetTokenR>(this.wire, "login/validateResetToken", request, (data, result) => {
      result.data = data;
    });
  }
  // -----------------
  // PUBLISHER COMMS

  async getPublisher(request: PublisherRequest.IGetPublisher): Promise<ActionResult<PublisherRequest.IGetPublisherR>> {
    return Communication.handleResponse<PublisherRequest.IGetPublisherR>(this.wire, "publishers/getPublisher", request, (data, result) => {
      result.data = data;
    });
  }

  async insertOrUpdatePublisher(request: PublisherRequest.IInsertOrUpdatePublisher): Promise<ActionResult<PublisherRequest.IInsertOrUpdatePublisherR>> {
    return Communication.handleResponse<PublisherRequest.IInsertOrUpdatePublisherR>(
      this.wire,
      "publishers/insertOrUpdatePublisher",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async insertOrUpdateLibraryConfig(
    request: PublisherRequest.IInsertOrUpdateLibraryConfig
  ): Promise<ActionResult<PublisherRequest.IInsertOrUpdateLibraryConfigR>> {
    return Communication.handleResponse<PublisherRequest.IInsertOrUpdateLibraryConfigR>(
      this.wire,
      "publishers/insertOrUpdateLibraryConfig",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async insertPublisherLibraryConfig(
    // for new Publisher Creation
    request: PublisherRequest.IInsertPublisherLibraryConfig
  ): Promise<ActionResult<PublisherRequest.IInsertPublisherLibraryConfigR>> {
    return Communication.handleResponse<PublisherRequest.IInsertPublisherLibraryConfigR>(
      this.wire,
      "publishers/insertPublisherLibraryConfig",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async insertOrUpdateLibraryInternationalization(
    request: PublisherRequest.IInsertOrUpdateLibraryInternationalization
  ): Promise<ActionResult<PublisherRequest.IInsertOrUpdateLibraryInternationalizationR>> {
    return Communication.handleResponse<PublisherRequest.IInsertOrUpdateLibraryInternationalizationR>(
      this.wire,
      "publishers/insertOrUpdateLibraryInternationalization",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deleteLibraryInternationalization(
    request: PublisherRequest.IDeleteLibraryInternationalization
  ): Promise<ActionResult<PublisherRequest.IDeleteLibraryInternationalizationR>> {
    return Communication.handleResponse<PublisherRequest.IDeleteLibraryInternationalizationR>(
      this.wire,
      "publishers/deleteLibraryInternationalization",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async flowInternationalizations(request: PublisherRequest.IFlowInternationalizationRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "publishers/flowInternationalizations", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdateAboutInfo(
    request: PublisherRequest.IInsertOrUpdateAboutInfo
  ): Promise<ActionResult<PublisherRequest.IInsertOrUpdateAboutInfoR>> {
    return Communication.handleResponse<PublisherRequest.IInsertOrUpdateAboutInfoR>(
      this.wire,
      "publishers/insertOrUpdateAboutInfo",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deleteAboutInfo(
    request: PublisherRequest.IDeleteAboutInfo
  ): Promise<ActionResult<PublisherRequest.IDeleteAboutInfoR>> {
    return Communication.handleResponse<PublisherRequest.IDeleteAboutInfoR>(
      this.wire,
      "publishers/deleteAboutInfo",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async flowAboutInfos(request: PublisherRequest.IFlowAboutInfosRequest): Promise<ActionResult<AppRequest.IFlowR>>{
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "publishers/flowAboutInfos", request, (data, result) =>{
      result.data = data;
    });
  }

  async deletePublisher(request: PublisherRequest.IDeletePublisher): Promise<ActionResult<PublisherRequest.IDeletePublisherR>> {
    return Communication.handleResponse<PublisherRequest.IDeletePublisherR>(this.wire, "publishers/deletePublisher", request, (data, result) => {
      result.data = data;
    });
  }
  // -----------------

  // SYSTEM VIEW COMMS
  async flowConfigPairs(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "system/flowConfigPairs", request, (data, result) => {
      result.data = data;
    });
  }
  async flowAuthenticationProviders(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "system/flowAuthProviders", request, (data, result) => {
      result.data = data;
    });
  }
  async flowPermissions(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "system/flowPermissions", request, (data, result) => {
      result.data = data;
    });
  }
  async flowOpenIddictApplications(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "system/flowOpenIddictApplications", request, (data, result) => {
      result.data = data;
    });
  }
  async getOpenIddictApplication(request: SystemRequest.IGetOpenIddictApplication): Promise<ActionResult<SystemRequest.IGetOpenIddictApplicationR>> {
    return Communication.handleResponse<SystemRequest.IGetOpenIddictApplicationR>(
      this.wire,
      "system/getOpenIddictApplicationByClientId",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async insertOrUpdateOpenIddictApplication(
    request: SystemRequest.IInsertOrUpdateOpenIddictApplication
  ): Promise<ActionResult<SystemRequest.IInsertOrUpdateOpenIddictApplicationR>> {
    return Communication.handleResponse<SystemRequest.IInsertOrUpdateOpenIddictApplicationR>(
      this.wire,
      "system/insertOrUpdateOpenIddictApplication",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deleteOpenIddictApplication(request: SystemRequest.IDeleteOpenIddictApplication): Promise<ActionResult<SystemRequest.IDeleteOpenIddictApplicationR>> {
    return Communication.handleResponse<SystemRequest.IDeleteOpenIddictApplicationR>(
      this.wire,
      "system/deleteOpenIddictApplication",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async getActiveUserFormDefinition(
    request: UserFormRequest.IGetActiveUserFormDefinition
  ): Promise<ActionResult<UserFormRequest.IGetActiveUserFormDefinitionR>> {
    return Communication.handleResponse<UserFormRequest.IGetActiveUserFormDefinitionR>(
      this.wire,
      "userForms/getActiveUserFormDefinition",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deleteUserForm(request: UserFormRequest.IDeleteUserForm): Promise<ActionResult<UserFormRequest.IDeleteUserFormR>> {
    return Communication.handleResponse<UserFormRequest.IDeleteUserFormR>(this.wire, "userForms/deleteUserForm", request, (data, result) => {
      result.data = data;
    });
  }
  async changeUserFormState(request: UserFormRequest.IChangeUserFormState): Promise<ActionResult<UserFormRequest.IChangeUserFormStateR>> {
    return Communication.handleResponse<UserFormRequest.IChangeUserFormStateR>(this.wire, "userForms/changeUserFormState", request, (data, result) => {
      result.data = data;
    });
  }
  async flowUserForms(request: UserFormRequest.IFlowUserFormsRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(
      this.wire,
      "userForms/flowUserForms",
      request,
      (data, result) => {
        result.data = data;
      },
      (data, result) => {
        console.log(result?.Error);
      }
    );
  }
  async insertOrUpdateUserForm(request: SystemRequest.IInsertOrUpdateUserForm): Promise<ActionResult<SystemRequest.IInsertOrUpdateUserFormR>> {
    return Communication.handleResponse<SystemRequest.IInsertOrUpdateUserFormR>(this.wire, "userForms/insertOrUpdateUserForm", request, (data, result) => {
      result.data = data;
    });
  }
  async getAttachments(request: SystemRequest.IGetAttachments): Promise<ActionResult<SystemRequest.IGetAttachmentsR>> {
    return Communication.handleResponse<SystemRequest.IGetAttachmentsR>(this.wire, "userForms/getAttachments", request, (data, result) => {
      result.data = data;
    });
  }
  async getAssociatedTitles(request: SystemRequest.IGetAssociatedTitles): Promise<ActionResult<SystemRequest.IGetAssociatedTitlesR>> {
    return Communication.handleResponse<SystemRequest.IGetAssociatedTitlesR>(this.wire, "userForms/getAssociatedTitles", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteConfigPair(request: SystemRequest.IDeleteConfigPair): Promise<ActionResult<SystemRequest.IDeleteConfigPairR>> {
    return Communication.handleResponse<SystemRequest.IDeleteConfigPairR>(this.wire, "system/deleteConfigPair", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdateConfigPair(request: SystemRequest.IInsertOrUpdateConfigPair): Promise<ActionResult<SystemRequest.IInsertOrUpdateConfigPairR>> {
    return Communication.handleResponse<SystemRequest.IInsertOrUpdateConfigPairR>(this.wire, "system/insertOrUpdateConfigPair", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteAuthenticationProvider(
    request: SystemRequest.IDeleteAuthenticationProvider
  ): Promise<ActionResult<SystemRequest.IDeleteAuthenticationProviderR>> {
    return Communication.handleResponse<SystemRequest.IDeleteAuthenticationProviderR>(
      this.wire,
      "system/deleteAuthenticationProvider",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async insertOrUpdateAuthenticationProvider(
    request: SystemRequest.IInsertOrUpdateAuthenticationProvider
  ): Promise<ActionResult<SystemRequest.IInsertOrUpdateAuthenticationProviderR>> {
    return Communication.handleResponse<SystemRequest.IInsertOrUpdateAuthenticationProviderR>(
      this.wire,
      "system/insertOrUpdateAuthenticationProvider",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deletePermission(request: SystemRequest.IDeletePermission): Promise<ActionResult<SystemRequest.IDeletePermissionR>> {
    return Communication.handleResponse<SystemRequest.IDeletePermissionR>(this.wire, "system/deletePermission", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdatePermission(request: SystemRequest.IInsertOrUpdatePermission): Promise<ActionResult<SystemRequest.IInsertOrUpdatePermissionR>> {
    return Communication.handleResponse<SystemRequest.IInsertOrUpdatePermissionR>(this.wire, "system/insertOrUpdatePermission", request, (data, result) => {
      result.data = data;
    });
  }
  async createPermissionByUsername(request: SystemRequest.ICreatePermissionByUsername): Promise<ActionResult<SystemRequest.ICreatePermissionByUsernameR>> {
    return Communication.handleResponse<SystemRequest.ICreatePermissionByUsernameR>(this.wire, "system/createPermissionByUsername", request, (data, result) => {
      result.data = data;
    });
  }

  async getServerLicensing(request: AppRequest.IGetServerLicensing): Promise<ActionResult<AppRequest.IGetServerLicensingR>> {
    return Communication.handleResponse<AppRequest.IGetServerLicensingR>(this.wire, "application/getServerLicensing", request, (data, result) => {
      result.data = data;
    });
  }
  async executeLicensingToken(request: AppRequest.IExecuteLicensingToken): Promise<ActionResult<AppRequest.IExecuteLicensingTokenR>> {
    return Communication.handleResponse<AppRequest.IExecuteLicensingTokenR>(this.wire, "application/executeLicensingToken", request, (data, result) => {
      result.data = data;
    });
  }
  async createLicensingToken(request: AppRequest.ICreateLicensingToken): Promise<ActionResult<AppRequest.ICreateLicensingTokenR>> {
    return Communication.handleResponse<AppRequest.ICreateLicensingTokenR>(this.wire, "application/createLicensingToken", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------
  // BILLING COMMS
  async flowPublicationPacks(request: BillingRequest.IFlowPublicationPacksRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "billing/flowPublicationPacks", request, (data, result) => {
      result.data = data;
    });
  }
  async deletePublicationPack(request: BillingRequest.IDeletePublicationPack): Promise<ActionResult<BillingRequest.IDeletePublicationPackR>> {
    return Communication.handleResponse<BillingRequest.IDeletePublicationPackR>(this.wire, "billing/deletePublicationPack", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdatePublicationPack(
    request: BillingRequest.IInsertOrUpdatePublicationPack
  ): Promise<ActionResult<BillingRequest.IInsertOrUpdatePublicationPackR>> {
    return Communication.handleResponse<BillingRequest.IInsertOrUpdatePublicationPackR>(
      this.wire,
      "billing/insertOrUpdatePublicationPack",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async insertOrUpdateUserLicenseRange(
    request: BillingRequest.IInsertOrUpdateUserLicenseRange
  ): Promise<ActionResult<BillingRequest.IInsertOrUpdateUserLicenseRangeR>> {
    return Communication.handleResponse<BillingRequest.IInsertOrUpdateUserLicenseRangeR>(
      this.wire,
      "billing/insertOrUpdateUserLicenseRange",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deleteUserLicenseRange(request: BillingRequest.IDeleteUserLicenseRange): Promise<ActionResult<BillingRequest.IDeleteUserLicenseRangeR>> {
    return Communication.handleResponse<BillingRequest.IDeleteUserLicenseRangeR>(this.wire, "billing/deleteUserLicenseRange", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdatePublicationPackDefinition(
    request: BillingRequest.IInsertOrUpdatePublicationPackDefinition
  ): Promise<ActionResult<BillingRequest.IInsertOrUpdatePublicationPackDefinitionR>> {
    return Communication.handleResponse<BillingRequest.IInsertOrUpdatePublicationPackDefinitionR>(
      this.wire,
      "billing/insertOrUpdatePublicationPackDefinition",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deletePublicationPackDefinition(
    request: BillingRequest.IDeletePublicationPackDefinition
  ): Promise<ActionResult<BillingRequest.IDeletePublicationPackDefinitionR>> {
    return Communication.handleResponse<BillingRequest.IDeletePublicationPackDefinitionR>(
      this.wire,
      "billing/deletePublicationPackDefinition",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async createPublicationPackToken(request: BillingRequest.ICreatePublicationPackToken): Promise<ActionResult<BillingRequest.ICreatePublicationPackTokenR>> {
    return Communication.handleResponse<BillingRequest.ICreatePublicationPackTokenR>(
      this.wire,
      "billing/createPublicationPackToken",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async executePublicationPackToken(request: BillingRequest.IExecutePublicationPackToken): Promise<ActionResult<BillingRequest.IExecutePublicationPackTokenR>> {
    return Communication.handleResponse<BillingRequest.IExecutePublicationPackTokenR>(
      this.wire,
      "billing/executePublicationPackToken",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async flowPublicationPackDefinitions(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "billing/flowPublicationPackDefinitions", request, (data, result) => {
      result.data = data;
    });
  }
  async flowUserLicenseRanges(request: BillingRequest.IFlowUserLicenseRanges): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "billing/flowUserLicenseRanges", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------
  // INVITE COMMS
  async getInvitationAndItem(request: InviteRequest.IGetInvitation): Promise<ActionResult<InviteRequest.IGetInvitationR>> {
    return Communication.handleResponse<InviteRequest.IGetInvitationR>(this.wire, "invitation/getInvitation", request, (data, result) => {
      result.data = data;
    });
  }
  async createPermissionInvitationWithLink(
    request: InviteRequest.ICreatePermissionInvitationWithLink
  ): Promise<ActionResult<InviteRequest.ICreatePermissionInvitationWithLinkR>> {
    return Communication.handleResponse<InviteRequest.ICreatePermissionInvitationWithLinkR>(
      this.wire,
      "invitation/createPermissionInvitationWithLink",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async createPermissionInvitationWithEmail(
    request: InviteRequest.ICreatePermissionInvitationWithEmail
  ): Promise<ActionResult<InviteRequest.ICreatePermissionInvitationWithEmailR>> {
    return Communication.handleResponse<InviteRequest.ICreatePermissionInvitationWithEmailR>(
      this.wire,
      "invitation/createPermissionInvitationWithEmail",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }

  async createLicenceInvitationWithLink(
    request: InviteRequest.ICreateLicenceInvitationWithLink
  ): Promise<ActionResult<InviteRequest.ICreateLicenceInvitationWithLinkR>> {
    return Communication.handleResponse<InviteRequest.ICreateLicenceInvitationWithLinkR>(
      this.wire,
      "invitation/createLicenceInvitationWithLink",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async createLicenceInvitationWithEmail(
    request: InviteRequest.ICreateLicenceInvitationWithEmail
  ): Promise<ActionResult<InviteRequest.ICreateLicenceInvitationWithEmailR>> {
    return Communication.handleResponse<InviteRequest.ICreateLicenceInvitationWithEmailR>(
      this.wire,
      "invitation/createLicenceInvitationWithEmail",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async executePermissionInvitation(request: InviteRequest.IGetInvitation): Promise<ActionResult<AppRequest.IEmpty>> {
    return Communication.handleResponse<AppRequest.IEmpty>(this.wire, "invitation/executePermissionInvitation", request, (data, result) => {
      result.data = data;
    });
  }

  async executeLicenceInvitation(request: InviteRequest.IGetInvitation): Promise<ActionResult<AppRequest.IEmpty>> {
    return Communication.handleResponse<AppRequest.IEmpty>(this.wire, "invitation/executeLicenceInvitation", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // LICENCE COMMS
  async deleteLicence(request: LicenceRequest.IDeleteLicence): Promise<ActionResult<LicenceRequest.IDeleteLicenceR>> {
    return Communication.handleResponse<LicenceRequest.IDeleteLicenceR>(this.wire, "licences/deleteLicence", request, (data, result) => {
      result.data = data;
    });
  }
  async insertLicence(request: LicenceRequest.IInsertLicence): Promise<ActionResult<LicenceRequest.IInsertLicenceR>> {
    return Communication.handleResponse<LicenceRequest.IInsertLicenceR>(this.wire, "licences/insertLicence", request, (data, result) => {
      result.data = data;
    });
  }
  async createLicenceByUsername(request: LicenceRequest.ICreateLicenceByUsername): Promise<ActionResult<LicenceRequest.ICreateLicenceByUsernameR>> {
    return Communication.handleResponse<LicenceRequest.ICreateLicenceByUsernameR>(this.wire, "licences/createLicenceByUsername", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // EMAIL TEMPLATE COMMS
  async flowEmailTemplates(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "emailTemplates/flowEmailTemplates", request, (data, result) => {
      result.data = data;
    });
  }

  async insertOrUpdateEmailTemplate(
    request: EmailTemplateRequest.IInsertOrUpdateEmailTemplate
  ): Promise<ActionResult<EmailTemplateRequest.IInsertOrUpdateEmailTemplateR>> {
    return Communication.handleResponse<EmailTemplateRequest.IInsertOrUpdateEmailTemplateR>(
      this.wire,
      "emailTemplates/insertOrUpdateEmailTemplate",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deleteEmailTemplate(request: EmailTemplateRequest.IDeleteEmailTemplate): Promise<ActionResult<EmailTemplateRequest.IDeleteEmailTemplateR>> {
    return Communication.handleResponse<EmailTemplateRequest.IDeleteEmailTemplateR>(
      this.wire,
      "emailTemplates/deleteEmailTemplate",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async testEmailTemplate(request: EmailTemplateRequest.ITestEmailTemplate): Promise<ActionResult<EmailTemplateRequest.ITestEmailTemplateR>> {
    return Communication.handleResponse<EmailTemplateRequest.ITestEmailTemplateR>(this.wire, "emailTemplates/testEmailTemplate", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // PRODUCT COMMS
  async flowProducts(request: ProductRequest.IFlowProductsRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "products/flowProducts", request, (data, result) => {
      result.data = data;
    });
  }
  async getFullProduct(request: ProductRequest.IGetFullProduct): Promise<ActionResult<ProductRequest.IGetFullProductR>> {
    return Communication.handleResponse<ProductRequest.IGetFullProductR>(this.wire, "products/getFullProduct", request, (data, result) => {
      result.data = data;
    });
  }

  async insertOrUpdateProduct(request: ProductRequest.IInsertOrUpdateProduct): Promise<ActionResult<ProductRequest.IInsertOrUpdateProductR>> {
    return Communication.handleResponse<ProductRequest.IInsertOrUpdateProductR>(this.wire, "products/insertOrUpdateProduct", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteProduct(request: ProductRequest.IDeleteProduct): Promise<ActionResult<ProductRequest.IDeleteProductR>> {
    return Communication.handleResponse<ProductRequest.IDeleteProductR>(this.wire, "products/deleteProduct", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // SUBSCRIPTION COMMS
  async getFullSubscription(request: SubscriptionRequest.IGetFullSubscription): Promise<ActionResult<SubscriptionRequest.IGetFullSubscriptionR>> {
    return Communication.handleResponse<SubscriptionRequest.IGetFullSubscriptionR>(this.wire, "subscriptions/getFullSubscription", request, (data, result) => {
      result.data = data;
    });
  }

  async flowSubscriptionLicences(request: SubscriptionRequest.IFlowSubscriptionLicencesRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "subscriptions/flowSubscriptionLicences", request, (data, result) => {
      result.data = data;
    });
  }
  async flowSubscriptions(request: SubscriptionRequest.IFlowSubscriptions): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "subscriptions/flowSubscriptions", request, (data, result) => {
      result.data = data;
    });
  }
  async flowCuratedSubscriptions(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "subscriptions/flowCuratedSubscriptions", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdateSubscription(
    request: SubscriptionRequest.IInsertOrUpdateSubscription
  ): Promise<ActionResult<SubscriptionRequest.IInsertOrUpdateSubscriptionR>> {
    return Communication.handleResponse<SubscriptionRequest.IInsertOrUpdateSubscriptionR>(
      this.wire,
      "subscriptions/insertOrUpdateSubscription",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deleteSubscription(request: SubscriptionRequest.IDeleteSubscription): Promise<ActionResult<SubscriptionRequest.IDeleteSubscriptionR>> {
    return Communication.handleResponse<SubscriptionRequest.IDeleteSubscriptionR>(this.wire, "subscriptions/deleteSubscription", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteExpiredSubscriptions(
    request: SubscriptionRequest.IDeleteExpiredSubscriptions
  ): Promise<ActionResult<SubscriptionRequest.IDeleteExpiredSubscriptionsR>> {
    return Communication.handleResponse<SubscriptionRequest.IDeleteExpiredSubscriptionsR>(
      this.wire,
      "subscriptions/deleteExpiredSubscriptions",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }

  async deleteExpiredPermissions(
    request: SystemRequest.IDeleteExpiredPermissions
  ): Promise<ActionResult<SystemRequest.IDeleteExpiredPermissionsR>> {
    return Communication.handleResponse<SystemRequest.IDeleteExpiredPermissionsR>(
      this.wire,
      "system/deleteExpiredPermissions",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  // ----------------------------

  // PROMOTION COMMS
  async flowPublisherPromotions(request: ProductRequest.IFlowPublisherPromotionsRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "products/flowPublisherPromotions", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdatePublisherPromotion(
    request: ProductRequest.IInsertOrUpdatePublisherPromotion
  ): Promise<ActionResult<ProductRequest.IInsertOrUpdatePublisherPromotionR>> {
    return Communication.handleResponse<ProductRequest.IInsertOrUpdatePublisherPromotionR>(
      this.wire,
      "products/insertOrUpdatePublisherPromotion",
      request,
      (data, result) => {
        result.data = data;
      }
    );
  }
  async deletePublisherPromotion(request: ProductRequest.IDeletePublisherPromotion): Promise<ActionResult<ProductRequest.IDeletePublisherPromotionR>> {
    return Communication.handleResponse<ProductRequest.IDeletePublisherPromotionR>(this.wire, "products/deletePublisherPromotion", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // TITLE COMMS
  async getAllTitles(request: TitlesRequest.IGetAllTitlesRequest): Promise<ActionResult<TitlesRequest.IGetAllTitlesR>> {
    return Communication.handleResponse<TitlesRequest.IGetAllTitlesR>(this.wire, "titles/getAllTitles", request, (data, result) => {
      result.data = data;
    });
  }
  async flowTitles(request: TitlesRequest.IFlowTitlesRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "titles/flowTitles", request, (data, result) => {
      result.data = data;
    });
  }
  async flowVersionsForTitle(request: TitlesRequest.IFlowVersionsForTitleRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "titles/flowVersionsForTitle", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdateTitle(request: TitlesRequest.IInsertOrUpdateTitle): Promise<ActionResult<TitlesRequest.IInsertOrUpdateTitleR>> {
    return Communication.handleResponse<TitlesRequest.IInsertOrUpdateTitleR>(this.wire, "titles/insertOrUpdateTitle", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdateVersion(request: TitlesRequest.IInsertOrUpdateVersion): Promise<ActionResult<TitlesRequest.IInsertOrUpdateVersionR>> {
    return Communication.handleResponse<TitlesRequest.IInsertOrUpdateVersionR>(this.wire, "titles/insertOrUpdateVersion", request, (data, result) => {
      result.data = data;
    });
  }
  async getVersionMeta(request: TitlesRequest.IGetVersionMeta): Promise<ActionResult<TitlesRequest.IGetVersionMetaR>> {
    return Communication.handleResponse<TitlesRequest.IGetVersionMetaR>(this.wire, "titles/getVersionMeta", request, (data, result) => {
      result.data = data;
    });
  }
  // async updateVersionMeta(request: TitlesRequest.IUpdateVersionMeta): Promise<ActionResult<TitlesRequest.IUpdateVersionMetaR>> {
  //   return Communication.handleResponse<TitlesRequest.IUpdateVersionMetaR>(this.wire, "titles/updateVersionMeta", request, (data, result) => {
  //     result.data = data;
  //   });
  // }
  async getPublicationPacksByPublisher(request: TitlesRequest.IGetPublicationPacks): Promise<ActionResult<TitlesRequest.IGetPublicationPacksR>> {
    return Communication.handleResponse<TitlesRequest.IGetPublicationPacksR>(this.wire, "titles/getPublicationPacksByPublisher", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteTitle(request: TitlesRequest.IDeleteTitle): Promise<ActionResult<TitlesRequest.IDeleteTitleR>> {
    return Communication.handleResponse<TitlesRequest.IDeleteTitleR>(this.wire, "titles/deleteTitle", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // CUSTOMER COMMS
  async flowCustomers(request: CustomersRequest.IFlowCustomersRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "customers/flowCustomers", request, (data, result) => {
      result.data = data;
    });
  }
  async flowCustomerSubscriptions(request: CustomersRequest.IFlowCustomerSubscriptionsRequest): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "customers/flowCustomerSubscriptions", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteEmptyCustomers(request: CustomersRequest.IDeleteEmptyCustomers): Promise<ActionResult<CustomersRequest.IDeleteEmptyCustomersR>> {
    return Communication.handleResponse<CustomersRequest.IDeleteEmptyCustomersR>(this.wire, "customers/deleteEmptyCustomers", request, (data, result) => {
      result.data = data;
    });
  }
  async insertOrUpdateCustomer(request: CustomersRequest.IInsertOrUpdateCustomer): Promise<ActionResult<CustomersRequest.IInsertOrUpdateCustomerR>> {
    return Communication.handleResponse<CustomersRequest.IInsertOrUpdateCustomerR>(this.wire, "customers/insertOrUpdateCustomer", request, (data, result) => {
      result.data = data;
    });
  }

  async deleteCustomer(request: CustomersRequest.IDeleteCustomer): Promise<ActionResult<CustomersRequest.IDeleteCustomerR>> {
    return Communication.handleResponse<CustomersRequest.IDeleteCustomerR>(this.wire, "customers/deleteCustomer", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // ACCOUNT COMMS
  async flowUsers(request: AppRequest.IFlow): Promise<ActionResult<AppRequest.IFlowR>> {
    return Communication.handleResponse<AppRequest.IFlowR>(this.wire, "accounts/flowUsers", request, (data, result) => {
      result.data = data;
    });
  }
  async getFullAccount(request: AccountRequest.IGetFullAccount): Promise<ActionResult<AccountRequest.IGetFullAccountR>> {
    return Communication.handleResponse<AccountRequest.IGetFullAccountR>(this.wire, "accounts/getFullAccount", request, (data, result) => {
      result.data = data;
    });
  }
  async deleteUserAccount(request: AccountRequest.IDeleteUserAccount): Promise<ActionResult<AccountRequest.IDeleteUserAccountR>> {
    return Communication.handleResponse<AccountRequest.IDeleteUserAccountR>(this.wire, "accounts/deleteUserAccount", request, (data, result) => {
      result.data = data;
    });
  }
  async changeUserLoginName(request: AccountRequest.IChangeUserLoginName): Promise<ActionResult<AccountRequest.IChangeUserLoginNameR>> {
    return Communication.handleResponse<AccountRequest.IChangeUserLoginNameR>(this.wire, "accounts/changeUserLoginName", request, (data, result) => {
      result.data = data;
    });
  }
  async updateUserProfile(request: AccountRequest.IUpdateUserProfile): Promise<ActionResult<AccountRequest.IUpdateUserProfileR>> {
    return Communication.handleResponse<AccountRequest.IUpdateUserProfileR>(this.wire, "accounts/updateUserProfile", request, (data, result) => {
      result.data = data;
    });
  }
  async getOfflineLicenseKey(request: AccountRequest.IGetOfflineLicenseKey): Promise<ActionResult<AccountRequest.IGetOfflineLicenseKeyR>> {
    return Communication.handleResponse<AccountRequest.IGetOfflineLicenseKeyR>(this.wire, "accounts/getOfflineLicenseKey", request, (data, result) => {
      result.data = data;
    });
  }
  async nullifyPassphrase(request: AccountRequest.IGetOfflineLicenseKey): Promise<ActionResult<AccountRequest.IGetOfflineLicenseKeyR>> {
    return Communication.handleResponse<AccountRequest.IGetOfflineLicenseKeyR>(this.wire, "accounts/nullifyPassphrase", request, (data, result) => {
      result.data = data;
    });
  }
  // ----------------------------

  // REPORTING COMMS
  async getAdminStatistics(request: ReportingRequest.IGetAdminStatistics): Promise<ActionResult<ReportingRequest.IGetAdminStatisticsR>> {
    return Communication.handleResponse<ReportingRequest.IGetAdminStatisticsR>(this.wire, "reporting/getAdminStatistics", request, (data, result) => {
      result.data = data;
    });
  }
  async getPublisherStatistics(request: ReportingRequest.IGetPublisherStatistics): Promise<ActionResult<ReportingRequest.IGetPublisherStatisticsR>> {
    return Communication.handleResponse<ReportingRequest.IGetPublisherStatisticsR>(this.wire, "reporting/getPublisherStatistics", request, (data, result) => {
      result.data = data;
    });
  }
  async getTopLicensesByPublisher(request: ReportingRequest.IGetTopLicensesByPublisher): Promise<ActionResult<ReportingRequest.IGraphingR>> {
    return Communication.handleResponse<ReportingRequest.IGraphingR>(this.wire, "reporting/getTopLicensesByPublisher", request, (data, result) => {
      result.data = data;
    });
  }
  async getTopProductsForPublisher(request: ReportingRequest.IGetTopProductsForPublisher): Promise<ActionResult<ReportingRequest.IGraphingR>> {
    return Communication.handleResponse<ReportingRequest.IGraphingR>(this.wire, "reporting/getTopProductsForPublisher", request, (data, result) => {
      result.data = data;
    });
  }
  async getVersionsByPublisher(request: AppRequest.IEmpty): Promise<ActionResult<ReportingRequest.IGraphingR>> {
    return Communication.handleResponse<ReportingRequest.IGraphingR>(this.wire, "reporting/getVersionsByPublisher", request, (data, result) => {
      result.data = data;
    });
  }
  async getPublicationsByPublisher(request: AppRequest.IEmpty): Promise<ActionResult<ReportingRequest.IGraphingR>> {
    return Communication.handleResponse<ReportingRequest.IGraphingR>(this.wire, "reporting/getPublicationsByPublisher", request, (data, result) => {
      result.data = data;
    });
  }
  async getTitleActivityCsv(request: ReportingRequest.IGetTitleActivityCsv): Promise<ActionResult<ReportingRequest.ICsvR>> {
    return Communication.handleResponse<ReportingRequest.ICsvR>(this.wire, "reporting/getTitleActivityCsv", request, (data, result) => {
      result.data = data;
    });
  }
  async generateReport(request: ReportingRequest.IReportingRequest): Promise<ActionResult<ReportingRequest.IReportingResponse>> {
    return Communication.handleResponse<ReportingRequest.IReportingResponse>(this.wire, "reporting/generateReport", request, (data, result) => {
      result.data = data;
    });
  }
  async getNewProviderAccountsCsv(request: ReportingRequest.IGetNewProviderAccountsCsv): Promise<ActionResult<ReportingRequest.ICsvR>> {
    return Communication.handleResponse<ReportingRequest.ICsvR>(this.wire, "reporting/getNewProviderAccountsCsv", request, (data, result) => {
      result.data = data;
    });
  }
  async getNewLicensesCsv(request: ReportingRequest.IGetNewLicensesCsv): Promise<ActionResult<ReportingRequest.ICsvR>> {
    return Communication.handleResponse<ReportingRequest.ICsvR>(this.wire, "reporting/getNewLicensesCsv", request, (data, result) => {
      result.data = data;
    });
  }
  async getNewLicensesGraphing(request: ReportingRequest.IGetNewLicensesCsv): Promise<ActionResult<ReportingRequest.IGraphingR>> {
    return Communication.handleResponse<ReportingRequest.IGraphingR>(this.wire, "reporting/getNewLicensesGraphing", request, (data, result) => {
      result.data = data;
    });
  }
  async amILoggedIn(): Promise<ActionResult<AppRequest.IAmILoggedInR>> {
    return Communication.handleResponse<AppRequest.IAmILoggedInR>(this.wire, "application/amILoggedIn", {}, (data, result) => {
      result.data = data;
    });
  }
  startSessionWatch() {
    setTimeout(async () => {
      if (this.login > LoginType.None) {
        let res = await this.amILoggedIn();
        if (res.data.LoggedIn === false) {
          window.location.reload();
        }
        this.startSessionWatch();
      }
    }, 30000);
  }
  // ----------------------------

  // SESSIONS AND PERMISSIONS
  terminateSession() {
    this.login = LoginType.None;
    this.session = undefined;
    this.userName = "";
    this.userPermissions = [];
    this.user = null;
    if (this.ssoIdToken !== "") {
      this.ssoLogout = true;
    }
    this.ssoIdToken = "";
    this.navigateToView.dispatch(DashboardView.Login, this);
    this.logoutSuccess.dispatch(null, this);
    this.viewedViews = new Dictionary<DashboardView, SingleLoadingReport>();
    for (let item in DashboardView) {
      if (!isNaN(Number(item))) {
        this.viewedViews.set(Number(item), new SingleLoadingReport());
      }
    }
  }
  initSession(result: LoginRequest.ILoginR) {
    this.session = result.Session;
    this.userPermissions = result.Permissions;
    this.userName = result.Username;
    this.user = result.User;
    if (result.Result === Models.LoginResult.OfflineAnonymous || result.Result === Models.LoginResult.OnlineAnonymous) {
      this.login = LoginType.Anonymous;
    } else {
      this.login = LoginType.User;
    }
    this.startSessionWatch();
    this.navigateToView.dispatch(DashboardView.Home, this);
    this.loginSuccess.dispatch(null, this);
  }
  public getSession(): string | undefined {
    return this.session;
  }

  getManageablePublishers = (): Models.ILoginLibrary[] => {
    if (this.canManagePublisher(-1)) {
      return this.loginLibraries.rows();
    }
    if (this.userPermissions.some((x) => x.ManagePublishers)) {
      return this.userPermissions
        .filter((x) => x.ManagePublishers && x.PublisherId !== null)
        .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);
    } else {
      return []; // You shouldn't see this page at all...
    }
  };
  getManageableTitles = (): Models.ILoginLibrary[] => {
    let firstFilter = this.getManageablePublishers();
    if (firstFilter.length === this.loginLibraries.length) {
      return firstFilter;
    }
    if (this.canManageTitles(-1)) {
      return this.loginLibraries.rows();
    }
    if (this.userPermissions.some((x) => x.ManageTitles)) {
      let secondFilter = this.userPermissions
        .filter((x) => x.ManageTitles && x.PublisherId !== null)
        .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);
      return this.mergeLibs(firstFilter, secondFilter);
    } else if (firstFilter.length > 0) {
      return firstFilter;
    } else {
      return []; // You shouldn't see this page at all...
    }
  };
  getManageableUserFormsPublishers = (type: UserFormRequest.UserFormSubmissionType): Models.ILoginLibrary[] => {
    let firstFilter = this.getManageablePublishers();
    if (firstFilter.length === this.loginLibraries.length) {
      return firstFilter;
    }
    if (this.userPermissions.some((x) => x.ManageBulletins || x.ManageFeedback || x.ManageTips)) {
      let secondFilter: Models.ILoginLibrary[] = [];
      switch (type) {
        case UserFormRequest.UserFormSubmissionType.Bulletin:
          secondFilter = this.userPermissions
            .filter((x) => x.ManageBulletins && x.PublisherId !== null)
            .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);
          break;
        case UserFormRequest.UserFormSubmissionType.Feedback:
          secondFilter = this.userPermissions
            .filter((x) => x.ManageFeedback && x.PublisherId !== null)
            .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);
          break;
        case UserFormRequest.UserFormSubmissionType.Tip:
          secondFilter = this.userPermissions
            .filter((x) => x.ManageTips && x.PublisherId !== null)
            .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);
          break;
      }
      return this.mergeLibs(firstFilter, secondFilter);
    } else if (firstFilter.length > 0) {
      return firstFilter;
    } else {
      return []; // You shouldn't see this page at all...
    }
  };

  getManageableProducts = (): Models.ILoginLibrary[] => {
    let firstFilter = this.getManageablePublishers();
    if (firstFilter.length === this.loginLibraries.length) {
      return firstFilter;
    }
    if (this.canManageProducts(-1)) {
      return this.loginLibraries.rows();
    }
    if (this.userPermissions.some((x) => x.ManageProducts)) {
      let secondFilter = this.userPermissions
        .filter((x) => x.ManageProducts && x.PublisherId !== null)
        .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);

      return this.mergeLibs(firstFilter, secondFilter);
    } else if (firstFilter.length > 0) {
      return firstFilter;
    } else {
      return []; // You shouldn't see this page at all...
    }
  };
  getManageableReporting = (): Models.ILoginLibrary[] => {
    let firstFilter = this.getManageablePublishers();
    if (firstFilter.length === this.loginLibraries.length) {
      return firstFilter;
    }
    if (this.canManageReporting(-1)) {
      return this.loginLibraries.rows();
    }
    if (this.userPermissions.some((x) => x.ManageReporting)) {
      let secondFilter = this.userPermissions
        .filter((x) => x.ManageReporting && x.PublisherId !== null)
        .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);

      return this.mergeLibs(firstFilter, secondFilter);
    } else if (firstFilter.length > 0) {
      return firstFilter;
    } else {
      return []; // You shouldn't see this page at all...
    }
  };

  getManageableSubscriptions = (): Models.ILoginLibrary[] => {
    let firstFilter = this.getManageablePublishers();
    if (firstFilter.length === this.loginLibraries.length) {
      return firstFilter;
    }
    if (this.canManageSubscriptions(-1)) {
      return this.loginLibraries.rows();
    }
    if (this.userPermissions.some((x) => x.ManageProducts)) {
      let secondFilter = this.userPermissions
        .filter((x) => x.ManageSubscription && x.PublisherId !== null && x.SubscriptionId === null)
        .map((x) => this.loginLibraries.rows().find((y) => y.PublisherId === x.PublisherId!)!);
      return this.mergeLibs(firstFilter, secondFilter);
    } else if (firstFilter.length > 0) {
      return firstFilter;
    } else {
      return []; // You shouldn't see this page at all...
    }
  };

  private mergeLibs = (firstSet: Models.ILoginLibrary[], secondSet: Models.ILoginLibrary[]) => {
    let merged: Models.ILoginLibrary[] = [];
    let long: Models.ILoginLibrary[] = [];
    let short: Models.ILoginLibrary[] = [];
    if (firstSet.length >= secondSet.length) {
      long = firstSet;
      short = secondSet;
    } else {
      long = secondSet;
      short = firstSet;
    }
    for (let i = 0; i < long.length; i++) {
      if (!merged.find((x) => x.PublisherId === long[i].PublisherId)) {
        merged.push({
          ...long[i],
          ...short.find((itmInner) => itmInner.PublisherId === long[i].PublisherId),
        });
      }
    }
    return merged;
  };

  canManageUserForms(formType: UserFormRequest.UserFormSubmissionType, publisherId: number) {
    if (this.canManageSystem()) {
      return true;
    }
    if (this.canManagePublisher(publisherId)) {
      return true;
    }
    switch (formType) {
      case UserFormRequest.UserFormSubmissionType.Bulletin:
        return this.userPermissions.some((x) => (x.ManageBulletins && x.PublisherId === publisherId) || (x.ManageBulletins && x.PublisherId === null));
      case UserFormRequest.UserFormSubmissionType.Tip:
        return this.userPermissions.some((x) => (x.ManageTips && x.PublisherId === publisherId) || (x.ManageTips && x.PublisherId === null));
      case UserFormRequest.UserFormSubmissionType.Feedback:
        return this.userPermissions.some((x) => (x.ManageFeedback && x.PublisherId === publisherId) || (x.ManageFeedback && x.PublisherId === null));
    }
  }

  canManageSystem = () => this.userPermissions.some((x) => x.ManageSystem);
  canManageAccounts = () => this.canManageSystem() || this.userPermissions.some((x) => x.ManageAccounts);
  canManageOfflinePackages = () => this.canManageAccounts() || this.userPermissions.some((x) => x.ManageOfflinePackages);

  canManageProducts = (publisherId: number) => {
    if (this.canManagePublisher(publisherId)) {
      return true;
    }
    return this.userPermissions.some((x) => (x.ManageProducts && x.PublisherId === null) || (x.ManageProducts && x.PublisherId === publisherId));
  };
  canManageTitles = (publisherId: number) => {
    if (this.canManagePublisher(publisherId)) {
      return true;
    }
    return this.userPermissions.some((x) => (x.ManageTitles && x.PublisherId === null) || (x.ManageTitles && x.PublisherId === publisherId));
  };
  canManageSubscriptions = (publisherId: number) => {
    if (this.canManagePublisher(publisherId)) {
      return true;
    }
    return this.userPermissions.some((x) => (x.ManageSubscription && x.PublisherId === null) || (x.ManageSubscription && x.PublisherId === publisherId));
  };
  canCurateSubscription = (publisherId: number, subscriptionId: number) => {
    if (this.canManageSubscriptions(publisherId)) {
      return true;
    }
    return this.userPermissions.some((x) => x.ManageSubscription && x.SubscriptionId === subscriptionId);
  };
  canCurateAnySubscription = () => this.userPermissions.some((x) => x.ManageSubscription && x.SubscriptionId !== null && x.PublisherId !== null);
  canManagePublisher = (publisherId: number) => {
    if (this.canManageSystem()) {
      return true;
    }
    return this.userPermissions.some((x) => (x.ManagePublishers && x.PublisherId === null) || (x.ManagePublishers && x.PublisherId === publisherId));
  };
  canManageReporting = (publisherId: number) => {
    if (this.canManagePublisher(publisherId)) {
      return true;
    }
    return this.userPermissions.some((x) => (x.ManageReporting && x.PublisherId === null) || (x.ManageReporting && x.PublisherId === publisherId));
  };
  // -----------
  static current: AppSession = new AppSession();
}
export enum FontSizes {
  Smallest = 1,
  Smaller = 2,
  Normal = 3,
  Larger = 4,
  Largest = 5,
}
export enum SearchEngines {
  Google = 1,
  Bing = 2,
  Yahoo = 3,
  Wikipedia = 4,
}
export enum LibraryViewModes {
  Cards = 1,
  Tiles = 2,
  List = 3,
}
export enum SettingsTarget {
  Release,
  Debug,
  Staging,
  Testing,
  LocalStack,
}
