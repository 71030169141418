import * as _ from "underscore";

export class Validator {
  private constructor() {} // force static class, hide constructor

  static notNullOrEmpty(value: any): boolean {
    return _.isString(value) && value.length > 0;
  }
  static notNullOrWhitespace(value: any): boolean {
    return _.isString(value) && value.trim().length > 0;
  }
  static isEmail(value: any): boolean {
    let validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    return validEmailRegex.test(value as string);
  }
  static isPostalCode(value: any): boolean {
    let validPostalRegex = RegExp(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/);
    return validPostalRegex.test(value as string);
  }
  static isPhoneNumber(value: any): boolean {
    let validPhoneNumberRegex = RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
    return validPhoneNumberRegex.test(value as string);
  }
}
