import React from "react";
import ReactDOMClient from "react-dom/client";

import App from "./App";

const container = document.getElementById("root") as HTMLElement;
document.documentElement.setAttribute("data-color-mode", "light");
// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(<App />);
