import * as React from "react";
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { AppSession, LoginType } from "src/models/AppSession";
import { IPublisher } from "src/models/dto/DashboardModels";
import { FormState } from "src/utilities/FormState";
import { Validator } from "src/utilities/Validator";

import { Image } from "../../foundation/Assets";
import { Icon } from "../../foundation/Controls";
import * as Messages from "../../foundation/Messages";
import { AppContext } from "../../state/Contextes";

export interface IPublisherFormProps {
  selectedPublisher: number;
}
export interface IPublisherFormState {
  isInitialized: boolean;
  name: string;
  form: FormState;
}
export class PublisherForm extends React.Component<IPublisherFormProps, IPublisherFormState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IPublisherFormProps) {
    super(props);
    let form = new FormState({
      address: "",
      address2: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      phone: "",
      phone2: "",
      fax: "",
      fax2: "",
      email: "",
      email2: "",
      socialAddr: "",
      socialAddr2: "",
      url2: "",
      about: "",
      url: "",
      name: "",
      tableGuid: "",
      tableId: "",
    });
    this.state = {
      isInitialized: false,
      name: "",
      form,
    };
  }

  initializePublisher = (publisher: IPublisher) => {
    let form = new FormState({
      address: publisher.Address ?? "",
      address2: publisher.Address2 ?? "",
      city: publisher.City ?? "",
      province: publisher.Province ?? "",
      country: publisher.Country ?? "",
      postalCode: publisher.PostalCode ?? "",
      phone: publisher.Phone ?? "",
      phone2: publisher.Phone2 ?? "",
      fax: publisher.Fax ?? "",
      fax2: publisher.Fax2 ?? "",
      email: publisher.Email ?? "",
      email2: publisher.Email2 ?? "",
      socialAddr: publisher.SocialAddr ?? "",
      socialAddr2: publisher.SocialAddr2 ?? "",
      url: publisher.URL ?? "",
      url2: publisher.URL2 ?? "",
      about: publisher.About ?? "",
      name: publisher.Name ?? "",
      tableGuid: publisher.TableGuid,
      tableId: publisher.TableId,
    });
    let name = publisher.Name;

    form.addValidator("name", Validator.notNullOrWhitespace, "");
    this.setState({
      name: name,
      form: form,
      isInitialized: true,
    });
  };
  handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      form: this.state.form.change(event.target),
    });
  };

  submit = async (event: any) => {
    event.preventDefault();
    if (this.state.form.values === undefined) {
      return;
    }
    let publisher: IPublisher = {
      About: this.state.form.values.about,
      Address: this.state.form.values.address,
      Address2: this.state.form.values.address2,
      City: this.state.form.values.city,
      Country: this.state.form.values.country,
      Email: this.state.form.values.email,
      Email2: this.state.form.values.email2,
      Phone: this.state.form.values.phone,
      Phone2: this.state.form.values.phone2,
      Fax: this.state.form.values.fax,
      Fax2: this.state.form.values.fax2,
      Name: this.state.form.values.name,
      PostalCode: this.state.form.values.postalCode,
      Province: this.state.form.values.province,
      URL: this.state.form.values.url,
      URL2: this.state.form.values.url2,
      SocialAddr: this.state.form.values.socialAddr,
      SocialAddr2: this.state.form.values.socialAddr2,
      TableGuid: this.state.form.values.tableGuid,
      TableId: this.state.form.values.tableId,
    };
    let response = await this.context.insertOrUpdatePublisher({ Publisher: publisher });

    if (response.valid()) {
      Messages.Notify.success(`The Publisher  ${publisher.Name} was updated successfully!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  render() {
    if (
      this.context.login === LoginType.None ||
      !(this.context.userPermissions.some((x) => x.ManagePublishers) || this.context.userPermissions.some((x) => x.ManageSystem))
    ) {
      return (
        <div className="section">
          <h1>You cannot view this page</h1>
        </div>
      );
    }
    let selectedPublisher = this.props.selectedPublisher;
    let form = this.state.form;

    return (
      <div className="formContainer">
        <Form className="p-2" onSubmit={this.submit}>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} /> &nbsp; Name
              </InputGroupText>
              <Input
                type="text"
                name="name"
                disabled={!this.context.canManagePublisher(selectedPublisher)}
                value={form.values.name}
                invalid={form.invalid("name") || form.values.name === ""}
                placeholder="Name"
                onChange={this.handleInput}
              />
            </InputGroup>
          </FormGroup>
          <Row style={{ flex: "1", display: "flex" }}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.address_book />} /> &nbsp; Address
                  </InputGroupText>
                  <Input
                    type="text"
                    name="address"
                    value={form.values.address}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("address")}
                    placeholder="Address"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.address_book />} /> &nbsp; Address 2
                  </InputGroupText>
                  <Input
                    type="text"
                    name="address2"
                    value={form.values.address2}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("address2")}
                    placeholder="Address2"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.location />} /> &nbsp; City
                  </InputGroupText>
                  <Input
                    type="text"
                    name="city"
                    value={form.values.city}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("city")}
                    placeholder="City"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.location />} />
                    &nbsp; Province
                  </InputGroupText>
                  <Input
                    type="text"
                    name="province"
                    value={form.values.province}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("province")}
                    placeholder="Province"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.location />} /> &nbsp; Country
                  </InputGroupText>
                  <Input
                    type="text"
                    name="country"
                    value={form.values.country}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("country")}
                    placeholder="Country"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.location />} /> &nbsp; Postal Code
                  </InputGroupText>
                  <Input
                    type="text"
                    name="postalcode"
                    value={form.values.postalcode}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("postalcode")}
                    placeholder="Postal Code"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.envelop />} /> &nbsp; Email
                  </InputGroupText>
                  <Input
                    type="email"
                    name="email"
                    value={form.values.email}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("email")}
                    placeholder="Email"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.envelop />} /> &nbsp; Email 2
                  </InputGroupText>
                  <Input
                    type="email"
                    name="email2"
                    value={form.values.email2}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("email2")}
                    placeholder="Email2"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; Phone
                  </InputGroupText>
                  <Input
                    type="text"
                    name="phone"
                    value={form.values.phone}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("phone")}
                    placeholder="Phone"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; Phone 2
                  </InputGroupText>
                  <Input
                    type="text"
                    name="phone2"
                    value={form.values.phone2}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("phone2")}
                    placeholder="Phone 2"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; Fax
                  </InputGroupText>
                  <Input
                    type="text"
                    name="fax"
                    value={form.values.fax}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("fax")}
                    placeholder="Fax"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; Fax 2
                  </InputGroupText>
                  <Input
                    type="text"
                    name="fax2"
                    value={form.values.fax2}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("fax2")}
                    placeholder="Fax 2"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; URL
                  </InputGroupText>
                  <Input
                    type="text"
                    name="url"
                    value={form.values.url}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("url")}
                    placeholder="url"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; URL 2
                  </InputGroupText>
                  <Input
                    type="text"
                    name="url2"
                    value={form.values.url2}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("url2")}
                    placeholder="url 2"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; Social Address
                  </InputGroupText>
                  <Input
                    type="text"
                    name="socialAddr"
                    value={form.values.socialAddr}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("socialAddr")}
                    placeholder="Social Address"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.phone />} /> &nbsp; Social Address 2
                  </InputGroupText>
                  <Input
                    type="text"
                    name="socialAddr2"
                    value={form.values.socialAddr2}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("socialAddr2")}
                    placeholder="Social Address 2"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup style={{ flex: "1", display: "flex", justifyContent: "center" }}>
            <Col xs={8}>
              <Button color="primary" outline block disabled={!form.formValid()} onClick={this.submit}>
                Save
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
