export interface IRequest {
  Data: any;
  Url: string;
  Sequence: string;
  Timestamp: number;
  [key: string]: any;
}

export enum Status {
  NotSet = 0,
  Success = 1, // OK
  BadRequest = 2, // request invalid/endpoint doesn't exist/payload can't be parsed
  DataError = 3, // data in payload invalid (data error)
  NotFound = 4, // requested item doesn't not exist
  AuthorizationFailed = 5, // authentification failed with backend (user not logged in/session expired)
  ServerError = 6, // a generic error
  ConnectionFailed = 7,
  SyncOffline = 8, // Sync server is offline
  NotAllowed = 9, // Indicates a route was called when it is not permitted.
}

export interface IResponse<T = any> {
  Sequence: string;
  Timestamp: number;
  Data: T;
  Status: Status;
  Error: IError | undefined;
  Url: string;
}

export interface ISocketResponse<T = any> extends IResponse<T> {
  KeepAlive: boolean;
}

export interface IError {
  Parameter?: string;
  Message: string;
  Status?: Status;
}
