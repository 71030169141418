import * as React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { Loading } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";

export interface IPublisherPromotionFormProps {
  initialNode: Models.IPublisherPromoViewModel;
  publisherId: number;
  deleteRequested?: (node: Models.IPublisherPromoViewModel) => void;
  saveRequested?: (node: Models.IPublisherPromoViewModel) => void;
  reloadProducts?: () => void;
}
export interface IPublisherPromotionFormState {
  editingNode: Models.IPublisherPromoViewModel;
  loading: boolean;
}
export class PublisherPromotionForm extends React.Component<IPublisherPromotionFormProps, IPublisherPromotionFormState> {
  context: AppSession;
  static contextType = AppContext;
  productTable = React.createRef<DataTable>();
  constructor(props: IPublisherPromotionFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      loading: false,
    };
  }
  handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Promotion: { ...prevState.editingNode.Promotion, Name: e.target.value } } }));
  };
  handlePromoDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Promotion: { ...prevState.editingNode.Promotion, Description: e.target.value } },
    }));
  };
  startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Promotion: { ...prevState.editingNode.Promotion, StartDate: dato } },
    }));
  };
  endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Promotion: { ...prevState.editingNode.Promotion, EndDate: dato } },
    }));
  };

  private initializeProduct = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowProducts({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.props.publisherId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private productFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowProducts({ FlowRequest: request.Batches[0], PublisherId: this.props.publisherId });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private generateProduct = (node: INode): JSX.Element => {
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.ProductDef.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={1} className="centerText" key={1} value={node.ProductDef.MasterCode} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={2} value={node.ProductDef.ProductCode} />);
    dataItems.push(<DataItem flexVal={3} className="" key={3} value={node.ProductDef.Name} />);
    let startDate = Convert.dateToFormattedString(node.ProductDef.StartDate as Date, Languages.English);
    dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={4} value={startDate} />);

    let value = "";
    if (node.ProductDef.EndDate === null) {
      value = "Never";
    } else if (new Date(node.ProductDef.EndDate as Date) < new Date()) {
      value = "Expired";
    } else {
      value = Convert.dateToFormattedString(node.ProductDef.EndDate as Date, Languages.English);
    }
    if (value === "Expired") {
      dataItems.push(
        <DataItem flexVal={2} className="rightBorder leftBorder centerText" key={5} value={null}>
          <span style={{ color: "red" }}>{value}</span>
        </DataItem>
      );
    } else if (value === "Never") {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText bolded" key={5} value={value} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={5} value={value} />);
    }
    return (
      <DataRow
        className={this.state.editingNode.ProductDef !== null && node.ProductDef.TableId === this.state.editingNode.ProductDef.TableId ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectProduct}
      />
    );
  };
  private selectProduct = (node: INode) => {
    this.setState(
      (prevState) => {
        let thing = { ...prevState };
        thing.editingNode.Promotion.DefinitionId = node.ProductDef.TableId;
        thing.editingNode.ProductDef = node.ProductDef;
        return thing;
      },
      () => {
        this.productTable.current!.reRender();
      }
    );
  };
  private productQueryExecute = (query: string) => {
    this.productTable.current!.reload(query);
  };
  private isFormValid = () => {
    let node = this.state.editingNode;

    if (node.ProductDef === null) {
      Messages.Notify.error("Please select a product for this promotion.");
      return false;
    }
    if (Convert.isEmptyOrSpaces(node.Promotion.Name)) {
      Messages.Notify.error("Name cannot be empty.");
      return false;
    }
    if (new Date(node.Promotion.EndDate) > new Date(node.ProductDef.EndDate)) {
      Messages.Notify.error("The end date of the promotion cannot be after the end date of the product.");
      return false;
    }
    if (new Date(node.Promotion.StartDate) < new Date(node.ProductDef.StartDate)) {
      Messages.Notify.error("The start date of the promotion cannot be before the start date of the product.");
      return false;
    }
    return true;
  };
  saveRequested = () => {
    if (this.props.saveRequested && this.isFormValid()) {
      this.props.saveRequested(this.state.editingNode);
    }
  };
  render() {
    let startDate = Convert.formatDateForForm(new Date(this.state.editingNode.Promotion.StartDate));
    let endDate = Convert.formatDateForForm(new Date(this.state.editingNode.Promotion.EndDate));
    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Promotion...">
        <div className="form-container full-width full-height">
          <h3>Publisher promotion management: {this.state.editingNode.Promotion.Name}</h3>
          <div className="full-width full-height promoProperties">
            <Col>
              <Row>
                <FormGroup style={{ flex: "1" }}>
                  <Label for="promoName">Promotion Name</Label>
                  <Input
                    value={this.state.editingNode.Promotion.Name}
                    onChange={this.handleNameChanged}
                    type="text"
                    name="promoName"
                    id="promoName"
                    placeholder="Promotion Name"
                  />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                  <Label for="startDate">Starting date</Label>
                  <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
                </FormGroup>
                <FormGroup style={{ flex: "1" }}>
                  <Label for="endDate">Ending date</Label>
                  <Input type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup style={{ flex: "1" }}>
                  <Label for="promoDescription">Promotion Description</Label>
                  <Input
                    style={{ minHeight: "250px", maxHeight: "500px" }}
                    type="textarea"
                    name="promoDescription"
                    id="promoDescription"
                    onChange={this.handlePromoDescriptionChanged}
                    value={this.state.editingNode.Promotion.Description === null ? "" : this.state.editingNode.Promotion.Description}
                  />
                </FormGroup>
              </Row>
              <h3>Selected product: {this.state.editingNode.ProductDef === null ? "None selected" : this.state.editingNode.ProductDef.Name}</h3>
              <p>Click a product in the list below to select it as the promotional product that will be served to all your publisher&apos;s users.</p>
              <DataTable
                headers={["Master Code", "Product Code", "Name", "Product Start Date", "Product End Date"]}
                headerFlexes={[1, 1, 3, 2, 2]}
                flowProvider={this.productFlowProvider}
                initializeFlowProvider={this.initializeProduct}
                objectBuilder={this.generateProduct}
                ref={this.productTable}
                settingsOverride={Models.genericDataSettings}
                searchQueryComitted={this.productQueryExecute}
              />
            </Col>
            <FormGroup>
              <Col>
                <Row>
                  <Button onClick={this.saveRequested} style={{ flex: "1", marginRight: this.props.deleteRequested ? "10px" : "" }} outline color="primary">
                    Save Promotion
                  </Button>
                  {this.props.deleteRequested && (
                    <Button
                      onClick={() => this.props.deleteRequested!(this.state.editingNode)}
                      style={{ flex: "1", marginLeft: "10px" }}
                      outline
                      color="danger"
                    >
                      Delete Promotion
                    </Button>
                  )}
                </Row>
              </Col>
            </FormGroup>
          </div>
        </div>
      </Loading>
    );
  }
}
