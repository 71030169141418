import * as _ from 'underscore';

import { Log } from '../Logger';
import { IRequestor, SocketCallback } from './Requestor';
import { IRequest, IResponse, ISocketResponse } from './Requests';

/**
 * Abstract communication between frontend and backend. Offers 
 * hook to transform and process communication request and responses.
 */
export abstract class Wire {
    protected middlewares:Middleware[]; 
    protected requestTransformers: Array<(request:IRequest)=>IRequest>;

    protected constructor() 
    {
        this.middlewares = new Array<Middleware>(); 
        this.requestTransformers = new Array<(request:IRequest)=>IRequest>();
    }

    /**
     * Add a request transform to this instance. Wire performs all the transforms,
     * in order, before sending it through the requestor. 
     *
     * @param transform 
     */
    addRequestTransform(transform:(request:IRequest)=>IRequest):void {
        this.requestTransformers.push(transform);
    }

    /**
     * Attach a middleware to this instance. Each middleware receive all
     * the responses from the backend and are given the opportunity to act
     * on them (or not). They are processed in order; if a Middleware returns
     * false it will interrupt the chain which prevent the response to get 
     * to the final handler.
     */
    addMiddleware(middleware:Middleware):void
    {
        this.middlewares.push(middleware);
    }

    /**
     * Attach many middlewares to this instance. Each middleware receive all
     * the responses from the backend and are given the opportunity to act
     * on them (or not). They are processed in order; if a Middleware returns
     * false it will interrupt the chain, which prevent the response to get 
     * to the final handler.
     */
    addMiddlewares(middlewares:Middleware[]):void
    {
        for(const middleware of middlewares) {
            this.addMiddleware(middleware); 
        }
    }

    /**
     * Remove a middleware from this instance. Future responses will no longer 
     * be processed by the middleware.W 
     */
    removeMiddleware(middleware:Middleware)
    {
        const removeIndex = this.middlewares.indexOf(middleware);
        if(removeIndex > -1){
            this.middlewares.splice(removeIndex, 1);
        }
    }

    /**
     * Remove many middleware from this instance. Future responses will no longer 
     * be processed by the middlewares. 
     */
    removeMiddlewares(middlewares:Middleware[]): void {
        for(const middleware of middlewares) {
            this.removeMiddleware(middleware); 
        }
    }


    protected applyRequestTransform(request:IRequest):IRequest
    {
        for(const transform of this.requestTransformers)  {
            try {
                request = transform(request);
            }
            catch (ex) {
                Log.error('Transformation error', ex); 
            }
        }
        return request; 
    }

    protected applyMiddlewares(promise: Promise<IResponse>):Promise<IResponse>
    {
        for(const middleware of this.middlewares) {
            promise = promise.then((response) => new Promise<IResponse>((resolve, reject) => {                   
                    if(middleware.process(response))  {
                        resolve(response); 
                    }
                    else {
                        reject(response);
                    }
                }));
        }
        return promise; 
    }

    /**
     * Send the provided @param data through the requestor. 
     * Will perform the request transformations (see: addRequestTransform),
     * apply the middlewares (see: addMiddleware) and return the resulting
     * promise for the response.  
     */
    abstract send(url:string, data:any, extras?:any):Promise<IResponse>;
    abstract createSocket(id:string, callback: SocketCallback, refreshTimeout:number):void

    static create(requestor:IRequestor):Wire
    {
        return new UnshieldedWire(requestor);
    }
    static shield(wire:Wire):Wire
    {
        return new ShieldedWire(wire); 
    }


}

/**
 * Core of the Wire, performs network request through the requestor. 
 */
class UnshieldedWire extends Wire {
    protected requestor:IRequestor; 
    constructor(requestor:IRequestor) 
    {
        super(); 
        this.requestor = requestor;
    }
    
    send(url:string, data:any, extras?:any):Promise<IResponse>
    {
        let request:IRequest = {
            Url:url,
            Data:data,
            ...extras
        }; 
        request = this.applyRequestTransform(request); 
        let promise:Promise<IResponse> = this.requestor.post(request); 
        promise = this.applyMiddlewares(promise);
        return promise; 
    }

    createSocket(id:string, callback:SocketCallback, refreshTimeout:number):void
    {
        const instanceMiddlewares = this.middlewares;
        let callbackWithMiddlewares:SocketCallback = (response:ISocketResponse) =>
        {
            for(const middleware of instanceMiddlewares) {
                if(middleware.process(response) === false) {
                    return;
                }
            }
            callback(response);
        }
        this.requestor.createSocket(id, callbackWithMiddlewares, refreshTimeout);
    }
}

/**
 * Encapsulate a Wire into a new layer of shielding. Shielding allows to 
 * add request transforms or middlewares to a specific instance, while 
 * chaining middleware/transforms from parent instances and communicate
 * through the same requestor.  
 */
class ShieldedWire extends Wire {
    wire:Wire;
    constructor(wire:Wire)
    {
        super();
        this.wire = wire;
    }

    send(url:string, data:any, extras?:any):Promise<IResponse>
    {
        let request:IRequest = {
            Url:url,
            Data:data,
            ...extras
        }; 
        request = this.applyRequestTransform(request); 
        let promise:Promise<IResponse> =  this.wire.send(request.Url, request.Data, _.omit(request, 'data', 'url'));
        promise = this.applyMiddlewares(promise);
        return promise; 
    }

    createSocket(id:string, callback:SocketCallback, refreshTimeout:number):void
    {
        const instanceMiddlewares = this.middlewares
        let callbackWithMiddlewares:SocketCallback = (response:ISocketResponse) =>
        {
            for(const middleware of instanceMiddlewares) {
                if(middleware.process(response) === false) {
                    return;
                }
            }
            callback(response);
        }
        this.wire.createSocket(id, callbackWithMiddlewares, refreshTimeout);
    }

}


export class Middleware 
{
    private func:(response:IResponse)=>any; 
    constructor(func:(response:IResponse)=>any, context?:any)
    {
        this.func = _.bind(func, context);
    }

    /**
     * Create and return a Middleware instance, is the same as calling constructor
     * but allow to be binded to an enumeration or a map
     *
     * @param action 
     * @param context 
     */
    static create(action:(response:IResponse)=>any, context?:any)
    {
        return new Middleware(action, context); 
    }

    process(response:IResponse): boolean {
         try {
            let funcReturn = this.func(response);
            if(funcReturn === false) {
                return false;
            } // type checking the result, to avoid other falsy values (0, "", {}, undefined)
         } 
         catch(ex) 
         {
            Log.error("Error processing Middleware", ex); 
         }
         return true;  
    }
}