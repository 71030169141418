import React from 'react';
import { Languages } from 'src/localization/Locale';
import { IUserAccount } from 'src/models/dto/DashboardModels';
import { Convert } from 'src/utilities/Helpers';

export interface IUserInfoBlockProps {
  User: IUserAccount;
}
export class UserInfoBlock extends React.Component<IUserInfoBlockProps> {
  constructor(props: IUserInfoBlockProps) {
    super(props);
  }
  render() {
    return (
      <div className="userInfoBlock">
        <span>
          {this.props.User.FirstName} {this.props.User.LastName} ({this.props.User.Email})
        </span>
        <span>
          <span style={{ fontWeight: 800 }}>Last active:</span> {Convert.dateToFormattedString(this.props.User.DateLastUsed, Languages.English)} (UTC)
        </span>
      </div>
    );
  }
}
