import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { Loading } from "src/ui/foundation/Controls";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";

enum VersionTab {
  Info,
  Metadata,
}

export interface IVersionFormProps {
  initialNode: Models.IVersionViewModel;
  createMode: boolean;
  goBackButtonClicked: () => void;
  deleteRequested?: (node: Models.IVersion) => void;
  saveRequested?: (node: Models.IVersion) => void;
  reloadVersion?: () => void;
}
export interface IVersionFormState {
  editingNode: Models.IVersionViewModel;
  activeTab: VersionTab;
  backgroundImage: any;
  backgroundImageTemp: string;
  versionStatus: number;
  versionStatusTemp: string;
  coverImage: any;
  coverImageTemp: string;
  loading: boolean;
  protocolId: number;
}

export class VersionForm extends React.Component<IVersionFormProps, IVersionFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IVersionFormProps) {
    super(props);
    this.state = {
      activeTab: VersionTab.Info,
      editingNode: JSON.parse(JSON.stringify(this.props.initialNode)),
      backgroundImage: String(this.props.initialNode.Metadata.BackgroundImage),
      backgroundImageTemp: "",
      versionStatus: this.props.initialNode.Version.VersionStatus,
      versionStatusTemp: String(Models.VersionStatus[this.props.initialNode.Version.VersionStatus]),
      coverImage: String(this.props.initialNode.Metadata.CoverImage),
      coverImageTemp: "",
      loading: false,
      protocolId: 0,
    };
  }

  componentDidMount = () => {
    // this.getVersionMeta();
  };

  // #region Version Information
  nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Version: { ...prevState.editingNode.Version, Name: e.target.value },
      },
    }));
  };

  versionStatusChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState({
      versionStatusTemp: e.target.value,
    });
    let value: Models.VersionStatus;
    if (this.state.versionStatus === 5) {
      if (e.target.value === "Review") {
        value = Models.VersionStatus.Review;
      } else if (e.target.value === "Release") {
        value = Models.VersionStatus.Release;
      } else {
        value = Models.VersionStatus.Inactive;
      }
    } else if (this.state.versionStatus === 2) {
      if (e.target.value === "Release") {
        value = Models.VersionStatus.Release;
      } else if (e.target.value === "Reject") {
        value = Models.VersionStatus.Inactive;
      } else {
        value = Models.VersionStatus.Review;
      }
    } else if (this.state.versionStatus === 3) {
      if (e.target.value === "Rescind") {
        value = Models.VersionStatus.Rescinded;
      } else {
        value = Models.VersionStatus.Release;
      }
    }

    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Version: { ...prevState.editingNode.Version, VersionStatus: value },
      },
    }));
  };

  effectiveStartDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Version: { ...prevState.editingNode.Version, EffectiveStartDate: new Date(e.target.valueAsDate!) },
        // EffectiveStartDate: new Date(e.target.valueAsDate!),
      },
    }));
  };

  effectiveEndDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Version: { ...prevState.editingNode.Version, EffectiveEndDate: new Date(e.target.valueAsDate!) },
        // EffectiveEndDate: new Date(e.target.valueAsDate!),
      },
    }));
  };
  // #endregion

  // #region Metadata
  backgroundImageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (files && files.length > 0) {
      this.getBase64(files[0], () => {
        this.setState({
          // backgroundImage: basedata,
          backgroundImageTemp: URL.createObjectURL(files![0]),
        });
      });
      Messages.Notify.success("The background image uploaded successfully!");
    }
  };

  titleChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Title: arg.target.value },
        // Title: arg.target.value,
      },
    }));
  };

  coverImageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (files && files.length > 0) {
      this.getBase64(files[0], (basedata) => {
        this.setState({
          coverImage: basedata,
          coverImageTemp: URL.createObjectURL(files![0]),
        });
      });
      Messages.Notify.success("The cover image uploaded successfully!");
    }
  };

  creatorChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Creator: arg.target.value },
        // Creator: arg.target.value,
      },
    }));
  };

  descriptionChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Description: arg.target.value },
        // Description: arg.target.value,
      },
    }));
  };

  languageChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Language: arg.target.value },
        // Language: arg.target.value,
      },
    }));
  };

  extentChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Extent: arg.target.value },
        // Extent: arg.target.value,
      },
    }));
  };

  identifierChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Identifier: arg.target.value },
        // Identifier: arg.target.value,
      },
    }));
  };

  subjectChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Subject: arg.target.value },
        // Subject: arg.target.value,
      },
    }));
  };

  actualDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Date: new Date(e.target.valueAsDate!) },
        // EffectiveEndDate: new Date(e.target.valueAsDate!),
      },
    }));
  };

  typeChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Type: arg.target.value },
        // Type: arg.target.value,
      },
    }));
  };

  coverageChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Coverage: arg.target.value },
        // Coverage: arg.target.value,
      },
    }));
  };

  contributorChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Contributor: arg.target.value },
        // Contributor: arg.target.value,
      },
    }));
  };

  rightsChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Rights: arg.target.value },
        // Rights: arg.target.value,
      },
    }));
  };

  sourceChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Source: arg.target.value },
        // Source: arg.target.value,
      },
    }));
  };

  formatChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, Format: arg.target.value },
        // Format: arg.target.value,
      },
    }));
  };

  urlChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Metadata: { ...prevState.editingNode.Metadata, URL: arg.target.value },
        // URL: arg.target.value,
      },
    }));
  };
  // #endregion

  getBase64 = (file: Blob, stateFunction: (basedata: string) => void): void => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let basedata = reader.result;
      if (basedata === null) {
        return;
      }
      basedata = basedata.toString();
      // Validating file type
      if (!basedata.includes("png")) {
        alert("Invalid file type");
        return;
      }
      // use regex to remove headers (data:image/png)
      if (basedata.includes("data")) {
        basedata = basedata.replace("data:", "").replace(/^.+,/, "");
      }
      stateFunction(basedata);
    };
    reader.onerror = (error) => error;
  };

  saveRequested = () => {
    if (this.formValid()) {
      let metadata: Models.IVersionMeta = {
        CoverImage: this.state.coverImage,
        BackgroundImage: this.state.backgroundImage,
        Title: this.state.editingNode.Metadata.Title === "" ? this.state.editingNode.Version.Name : this.state.editingNode.Metadata.Title,
        Creator: this.state.editingNode.Metadata.Creator,
        Publisher: this.state.editingNode.Metadata.Publisher,
        Date: this.state.editingNode.Metadata.Date,
        Description: this.state.editingNode.Metadata.Description,
        Language: this.state.editingNode.Metadata.Language,
        Extent: this.state.editingNode.Metadata.Extent,
        Identifier: this.state.editingNode.Metadata.Identifier,
        Subject: this.state.editingNode.Metadata.Subject,
        Type: this.state.editingNode.Metadata.Type,
        Coverage: this.state.editingNode.Metadata.Coverage,
        Contributor: this.state.editingNode.Metadata.Contributor,
        Rights: this.state.editingNode.Metadata.Rights,
        Source: this.state.editingNode.Metadata.Source,
        Format: this.state.editingNode.Metadata.Format,
        URL: this.state.editingNode.Metadata.URL,
      };
      console.log(metadata);

      if (this.context.protocolId === undefined || this.context.protocolId === 0) {
        Messages.Notify.error("Session expiry detected.  Please refresh your session and login again.");
      } else {
        this.setState(
          (prevState) => ({
            editingNode: {
              ...prevState.editingNode,
              Version: {
                ...prevState.editingNode.Version,
                ProtocolId: this.context.protocolId,
              },
            },
          }),
          async () => {
            let response = await this.context.insertOrUpdateVersion({
              Version: this.state.editingNode.Version,
              VersionMeta: metadata,
            });
            if (response.valid()) {
              Messages.Notify.success(`The Version was saved successfully!`);
              this.props.goBackButtonClicked();
            } else {
              if (response.errors.length > 0) {
                Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
              } else {
                Messages.Notify.error("An error occured while executing the communication");
              }
            }
          }
        );
      }
    } else {
      Messages.Notify.error("Save failed. Please enter a version name.");
    }
  };

  isUnchanged() {
    if (this.state.versionStatus !== 5 && this.state.versionStatus !== 3 && this.state.versionStatus !== 2) {
      return true;
    } else {
      return false;
    }
  }

  formValid = () => {
    if (this.state.editingNode.Version.Name === "") {
      return false;
    }
    return true;
  };

  render() {
    let effectiveStartDate = Convert.formatDateForForm(new Date(this.state.editingNode.Version.EffectiveStartDate));
    let effectiveEndDate = Convert.formatDateForForm(new Date(this.state.editingNode.Version.EffectiveEndDate));
    let actualDate = Convert.formatDateForForm(new Date(this.state.editingNode.Metadata.Date));
    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Version...">
        <div className="full-width full-height version-tabs-root">
          <h2> Version Management </h2>
          <p> You have chosen to create a new version. Enter the properties below and select a means of saving.</p>
          <div className="version-tabs full-height full-width">
            <Tabs defaultActiveKey={this.state.activeTab} id="versionTabs">
              <Tab eventKey={VersionTab.Info} title={"Information"}>
                <div className="full-width full-height versionProperty">
                  <Col>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="name">
                          {" "}
                          Name{" "}
                        </Label>
                        <Input onChange={this.nameChanged} type="text" name="name" id="name" width="8px" value={this.state.editingNode.Version.Name} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="tableGuid">
                          {" "}
                          Table Guid{" "}
                        </Label>
                        <Input type="text" name="tableGuid" id="tableGuid" width="8px" value={this.state.editingNode.Version.TableGuid} readOnly={true} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="versionStatus">
                          {" "}
                          Version Status{" "}
                        </Label>
                        {this.isUnchanged() && (
                          <Input type="select" name="versionStatus" value={this.state.versionStatusTemp} readOnly={true}>
                            <option>{Models.VersionStatus[this.state.versionStatus]}</option>
                          </Input>
                        )}

                        {this.state.versionStatus === 5 && (
                          <Input type="select" name="versionStatus" value={this.state.versionStatusTemp} onChange={this.versionStatusChanged}>
                            <option value="Review"> Review </option>
                            <option value="Release"> Release </option>
                            <option value="Inactive"> {Models.VersionStatus[this.state.versionStatus]} </option>
                          </Input>
                        )}

                        {this.state.versionStatus === 3 && (
                          <Input type="select" name="versionStatus" value={this.state.versionStatusTemp} onChange={this.versionStatusChanged}>
                            <option value="Release"> {Models.VersionStatus[this.state.versionStatus]} </option>
                            <option value="Rescind"> Rescind </option>
                          </Input>
                        )}

                        {this.state.versionStatus === 2 && (
                          <Input type="select" name="versionStatus" value={this.state.versionStatusTemp} onChange={this.versionStatusChanged}>
                            <option value="Review"> {Models.VersionStatus[this.state.versionStatus]} </option>
                            <option value="Release"> Release </option>
                            <option value="Reject"> Reject </option>
                          </Input>
                        )}
                        {/* <Input type="select" name="versionStatus" value={this.state.editingNode.Version.VersionStatus} onChange={this.versionStatusChanged}>
                                                        {Object.keys(Models.VersionStatus)
                                                            .filter((key) => isNaN(Number(Models.VersionStatus[key])))
                                                            .map((it) => (
                                                                <option value={it} key={it} data-providerval={it}>
                                                                    {Models.VersionStatus[it]}
                                                                </option>
                                                            ))
                                                        }
                                                    </Input> */}
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="startDate">
                          {" "}
                          Effective Start Date{" "}
                        </Label>
                        <Input onChange={this.effectiveStartDateChanged} type="date" name="startDate" id="startDate" value={effectiveStartDate} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="endDate">
                          {" "}
                          Effective End Date{" "}
                        </Label>
                        <Input onChange={this.effectiveEndDateChanged} type="date" name="endDate" id="endDate" value={effectiveEndDate} />
                      </FormGroup>
                    </Row>
                  </Col>
                </div>
              </Tab>
              <Tab disabled={this.state.editingNode.Metadata === null} eventKey={VersionTab.Metadata} title={"Meta Data"}>
                <div className="full-width full-height versionProperty">
                  <Col>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="backgroundImage">
                          {" "}
                          Background Image{" "}
                        </Label>
                        {this.state.backgroundImage !== "" && (
                          <Label style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                            <img
                              title="backgroundImage"
                              src={`data:image/jpeg;base64,${String(this.state.editingNode.Metadata.BackgroundImage)}`}
                              width={100}
                            />
                          </Label>
                        )}
                        {this.state.backgroundImage === "" && <Label style={{ paddingLeft: "10px" }}>Background image not set</Label>}
                        <Input type="file" accept=".png" name="backgroundImage" id="backgroundImage" onChange={this.backgroundImageChanged} />
                        <div>
                          {this.state.backgroundImageTemp !== "" && (
                            <Label style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                              Preview:
                              <img title="backgroundImageTemp" src={this.state.backgroundImageTemp} width={100} />
                            </Label>
                          )}
                        </div>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="contributor">
                          {" "}
                          Contributor{" "}
                        </Label>
                        <Input
                          type="text"
                          name="contributor"
                          id="contributor"
                          value={this.state.editingNode.Metadata.Contributor || " "}
                          onChange={this.contributorChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="coverage">
                          {" "}
                          Coverage{" "}
                        </Label>
                        <Input
                          type="text"
                          name="coverage"
                          id="coverage"
                          value={this.state.editingNode.Metadata.Coverage || " "}
                          onChange={this.coverageChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="coverImage">
                          {" "}
                          Cover Image{" "}
                        </Label>
                        {this.state.coverImage !== "" && (
                          <Label style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                            <img
                              title="coverImage"
                              src={`data:image/jpeg;base64,${String(this.state.editingNode.Metadata.CoverImage)}`}
                              width={50}
                              height={50}
                            />
                          </Label>
                        )}
                        {this.state.coverImage === "" && <Label style={{ paddingLeft: "10px" }}>Cover image not set</Label>}
                        <Input type="file" accept=".png" name="coverImage" id="coverImage" onChange={this.coverImageChanged} />
                      </FormGroup>
                      <div className="preview-image">
                        <img title="coverImageTemp" src={this.state.coverImageTemp} width={75} />
                      </div>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="creator">
                          {" "}
                          Creator{" "}
                        </Label>
                        <Input
                          type="text"
                          name="creator"
                          id="creator"
                          width="8px"
                          value={this.state.editingNode.Metadata.Creator || " "}
                          onChange={this.creatorChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="actualDate">
                          {" "}
                          Date{" "}
                        </Label>
                        <Input type="date" name="actualDate" id="actualDate" width="8px" value={actualDate} onChange={this.actualDateChanged} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="description">
                          {" "}
                          Description{" "}
                        </Label>
                        <Input
                          type="textarea"
                          name="description"
                          id="description"
                          value={this.state.editingNode.Metadata.Description || " "}
                          onChange={this.descriptionChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="extent">
                          {" "}
                          Extent{" "}
                        </Label>
                        <Input type="text" name="extent" id="extent" value={this.state.editingNode.Metadata.Extent || " "} onChange={this.extentChanged} />
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="format">
                          {" "}
                          Format{" "}
                        </Label>
                        <Input type="text" name="format" id="format" value={this.state.editingNode.Metadata.Format || " "} onChange={this.formatChanged} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="identifier">
                          {" "}
                          Identifier{" "}
                        </Label>
                        <Input
                          type="text"
                          name="identifier"
                          id="identifier"
                          value={this.state.editingNode.Metadata.Identifier || " "}
                          onChange={this.identifierChanged}
                        />
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="language">
                          {" "}
                          Language{" "}
                        </Label>
                        <Input
                          type="text"
                          name="language"
                          id="language"
                          value={this.state.editingNode.Metadata.Language || " "}
                          onChange={this.languageChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="publisher">
                          {" "}
                          Publisher{" "}
                        </Label>
                        <Input type="text" name="publisher" id="publisher" value={this.state.editingNode.Metadata.Publisher || " "} readOnly={true} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="rights">
                          {" "}
                          Rights{" "}
                        </Label>
                        <Input type="text" name="rights" id="rights" value={this.state.editingNode.Metadata.Rights || " "} onChange={this.rightsChanged} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="source">
                          {" "}
                          Source{" "}
                        </Label>
                        <Input type="text" name="source" id="source" value={this.state.editingNode.Metadata.Source || " "} onChange={this.sourceChanged} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="subject">
                          {" "}
                          Subject{" "}
                        </Label>
                        <Input
                          type="textarea"
                          name="subject"
                          id="subject"
                          value={this.state.editingNode.Metadata.Subject || " "}
                          onChange={this.subjectChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="title">
                          {" "}
                          Title{" "}
                        </Label>
                        <Input type="text" name="title" id="title" value={this.state.editingNode.Metadata.Title || " "} onChange={this.titleChanged} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="type">
                          {" "}
                          Type{" "}
                        </Label>
                        <Input type="text" name="type" id="type" value={this.state.editingNode.Metadata.Type || " "} onChange={this.typeChanged} />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="url">
                          {" "}
                          URL{" "}
                        </Label>
                        <Input type="url" name="url" id="url" value={this.state.editingNode.Metadata.URL || " "} onChange={this.urlChanged} />
                      </FormGroup>
                    </Row>
                  </Col>
                </div>
              </Tab>
            </Tabs>
            <FormGroup style={{ position: "relative" }}>
              <Col>
                <Row>
                  {!this.props.createMode && (
                    <Button onClick={this.saveRequested} style={{ flex: "1", marginRight: "10px" }} outline color="primary">
                      Save Version
                    </Button>
                  )}
                  {this.props.createMode && (
                    <Button onClick={this.saveRequested} style={{ flex: "1", marginRight: "10px" }} outline color="primary">
                      Create Version
                    </Button>
                  )}
                  {this.props.goBackButtonClicked && (
                    <Button onClick={this.props.goBackButtonClicked} style={{ flex: "1", marginLeft: "10px " }} outline color="info">
                      Go Back
                    </Button>
                  )}
                </Row>
              </Col>
            </FormGroup>
          </div>
        </div>
      </Loading>
    );
  }
}
