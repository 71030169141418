import * as React from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';
import { AppContext } from 'src/ui/state/Contextes';

import * as Messages from '../Messages';

export interface IOfflineLicenseFormProps {}
export interface IOfflineLicenseFormState {
  offlineLicenseKey: string;
  passphrase: string;
}

export class OfflineLicenseForm extends React.Component<IOfflineLicenseFormProps, IOfflineLicenseFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IOfflineLicenseFormProps) {
    super(props);
    this.state = { offlineLicenseKey: "", passphrase: "" };
  }

  submit = async () => {
    let response = await this.context.getOfflineLicenseKey({ License: this.state.offlineLicenseKey });
    if (response.valid()) {
      let passphrase = response.data.Registration.Passphrase;
      this.setState({ passphrase: passphrase }, async () => {
        Messages.Notify.success(`Passphrase is ` + passphrase);
        let nullResp = await this.context.nullifyPassphrase({ License: this.state.offlineLicenseKey });
        if (!nullResp.valid()) {
          Messages.Notify.error(nullResp.errors[0].Message);
        }
      });
    } else if (response.errors.length > 0) {
      Messages.Notify.error(response.errors[0].Message);
    }
  };

  render() {
    return (
      <div>
        <h1>Offline library package redemption</h1>
        <h2>
          Input an offline license key and retrieve the passphrase for an offline package registration. You will need the license key provided by the end user
          to get the passphrase.
        </h2>
        <Col>
          <Row style={{ marginTop: "15px" }}>
            <FormGroup>
              <Input placeholder="Input offline license key" onChange={(e) => this.setState({ offlineLicenseKey: e.target.value })} />
            </FormGroup>
            <Button onClick={this.submit} outline color="info" style={{ width: "fit-content", marginLeft: "15px", height: "fit-content" }}>
              Submit
            </Button>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            {this.state.passphrase && (
              <div>
                <Label color="info">Pass phrase:</Label>
                <Input type="text" value={this.state.passphrase} readOnly />
                <h3 style={{ color: "red", fontWeight: 800, marginTop: "10pt" }}>Save this passphrase. It cannot be shown again.</h3>
              </div>
            )}
          </Row>
        </Col>
      </div>
    );
  }
}
