import 'src/assets/css/app.css';

import * as React from 'react';
import { AppSession } from 'src/models/AppSession';
import { AppContext } from 'src/ui/state/Contextes';

import { AppView } from './ui/views/AppView';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './utilities/AuthConfig';
import { PublicClientApplication } from '@azure/msal-browser';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
 const msalInstance = new PublicClientApplication(msalConfig);
class App extends React.Component<unknown, { app: AppSession }> {
  constructor(props: any) {
    super(props);
    this.state = {
      app: AppSession.current
    };
  }

  public render() {
    return (
      <MsalProvider instance={msalInstance}>
      <AppContext.Provider value={this.state.app}>
        <AppView />
      </AppContext.Provider>
      </MsalProvider>
    );
  }
}

export default App;
