import * as React from "react";
import { Button } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { ILoginLibrary } from "src/models/dto/DashboardModels";
import { Drawer, DrawerContainer } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { DashboardView } from "src/ui/foundation/Layout";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { PanelView } from "../PanelView";
import { SubscriptionForm } from "./SubscriptionForm";

export interface ISubscriptionViewProps {
  IsLoggedIn: boolean;
}
export interface ISubscriptionViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  allowedPubs: ILoginLibrary[];
  currentPub: ILoginLibrary | null;
  panelDisabled: boolean;
}
export class SubscriptionView extends React.Component<ISubscriptionViewProps, ISubscriptionViewState> {
  context: AppSession;
  static contextType = AppContext;
  subscriptionsTable = React.createRef<DataTable>();
  constructor(props: ISubscriptionViewProps) {
    super(props);
    this.state = { drawerShow: false, currentDrawerContent: null, allowedPubs: [], currentPub: null, panelDisabled: false };
  }
  loginInit = () => {
    let allowedPubs = this.context.getManageableSubscriptions();
    if (allowedPubs.length > 0) {
      this.context.viewedViews.get(DashboardView.Subscriptions)!.progressLoading();

      this.setState({ allowedPubs: allowedPubs, currentPub: allowedPubs[0] });
    }
  };
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Subscriptions)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Subscriptions)!.loading.off(this.loginInit);
  }
  // #region Subscriptions
  private initializeSubscriptions = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowSubscriptions({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });
  private subscriptionFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowSubscriptions({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        } else {
          reject();
        }
      });
    });

  private subscriptionQueryExecute = (query: string) => {
    this.subscriptionsTable.current!.reload(query);
  };
  generateSubscription = (n: INode) => {
    let node = n as Models.ISubscriptionViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Subscription.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} key={1} className="centerText" value={node.Customer!.Name} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="rightBorder leftBorder centerText" value={node.ProductDef!.Name} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="centerText" value={node.Subscription.AuthSubscription} />);
    dataItems.push(
      <DataItem
        flexVal={1}
        key={4}
        className="rightBorder leftBorder centerText"
        value={Convert.dateToFormattedString(node.Subscription.StartDate, Languages.English)}
      />
    );
    if (new Date(node.Subscription.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={1} className="centerText" key={5} value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(
        <DataItem flexVal={1} key={5} className="centerText" value={Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)} />
      );
    }
    dataItems.push(<DataItem flexVal={1} key={6} className="rightBorder leftBorder centerText" value={node.AssignedLicences.length.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={7} className="centerText" value={node.Subscription.TotalLicences.toString()} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.subscriptionEdit} />;
  };
  subscriptionEdit = (n: INode) => {
    let node = n as Models.ISubscriptionViewModel;
    this.setState({
      drawerShow: true,
      currentDrawerContent: <SubscriptionForm initialNode={node} publisherId={this.state.currentPub!.PublisherId} />,
    });
  };
  addSubscription = () => {
    let blank: Models.ISubscriptionViewModel = {
      AssignedLicences: [],
      Conditions: [],
      Index: -1,
      IsFirst: false,
      IsLast: false,
      ProductDef: null,
      Subscription: {
        AccessAfterExpiration: true,
        AuthSubscription: "",
        Canceled: false,
        ContentPermissions: 3,
        CustomerId: -1,
        DateCreated: new Date(),
        DefinitionId: -1,
        EndDate: new Date(),
        OfflineAccessDuration: 1,
        OfflineAccessDurationType: 0,
        OfflineLogins: 0,
        StartDate: new Date(),
        TableGuid: "00000000-0000-0000-0000-000000000000",
        TableId: -1,
        TotalLicences: 0,
      },
      Customer: null,
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: <SubscriptionForm initialNode={blank} publisherId={this.state.currentPub!.PublisherId} />,
    });
  };
  // #endregion
  publisherChanged = (e: ILoginLibrary) => {
    this.setState({ currentPub: this.state.allowedPubs.find((x) => x.PublisherId === e.PublisherId)! }, () => {
      this.subscriptionsTable.current!.reload();
    });
  };
  private deleteAllExpiredSubscriptions = async () => {
    let dResp = await Messages.Dialog.confirm(
      <div>
        <span>
          Are you absolutely sure you wish to delete all subscriptions that are expired for this publisher? All licences and conditions for these subscriptions
          will also be deleted.
        </span>
      </div>,
      "Delete customers?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (dResp === "true") {
      let response = await this.context.deleteExpiredSubscriptions({ PublisherId: this.state.currentPub!.PublisherId });
      if (response.valid()) {
        Messages.Notify.success("Subscriptions deleted successfully!");
        this.subscriptionsTable.current!.reload();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  render() {
    if (!this.props.IsLoggedIn || this.state.allowedPubs.length <= 0 || !this.context.viewedViews.get(DashboardView.Subscriptions)!.isLoaded()) {
      return "";
    }
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 100;
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          publisherList={this.state.allowedPubs}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.currentPub!}
          disabled={this.state.panelDisabled}
        />
        <div className="subscriptionView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="subscriptionViewInner">
              <div className="section">
                <h1>Welcome to the Subscription View</h1>
                <p>
                  These are the subscriptions. Clicking one will take you to the subscription edit screen and clicking the &quot;+&quot; will take you to the
                  subscription creation screen for this customer.
                </p>
                {this.context.canManageSystem() && (
                  <Button style={{ width: "fit-content", marginBottom: "5px" }} outline color="danger" onClick={this.deleteAllExpiredSubscriptions}>
                    Delete all expired subscriptions.
                  </Button>
                )}
                <DataTable
                  headers={["Customer name", "Product name", "Subscription Auth", "Start Date", "End Date", "Used Licences", "Maximum Licences"]}
                  headerFlexes={[2, 2, 2, 1, 1, 1, 1]}
                  flowProvider={this.subscriptionFlowProvider}
                  initializeFlowProvider={this.initializeSubscriptions}
                  objectBuilder={this.generateSubscription}
                  ref={this.subscriptionsTable}
                  settingsOverride={settings}
                  rowAddRequested={this.addSubscription}
                  searchQueryComitted={this.subscriptionQueryExecute}
                />
              </div>
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
