import * as React from "react";
import { AppSession } from "src/models/AppSession";

import { AppContext } from "../state/Contextes";

const power = require("../../assets/img/powered-by.png");
interface IFooterViewProps {}

interface IFooterViewState {}

export class FooterView extends React.Component<IFooterViewProps, IFooterViewState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IFooterViewProps | Readonly<IFooterViewProps>) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    return (
      <div className="footer">
        <img title="footerLogo" className="footer-logo" src={power} />
      </div>
    );
  }
}
