import * as React from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { ILoginLibrary } from "src/models/dto/DashboardModels";
import { Drawer, DrawerContainer } from "src/ui/foundation/Controls";
import { OfflineLicenseForm } from "src/ui/foundation/Controls/OfflineLicenseForm";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { DashboardView } from "src/ui/foundation/Layout";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { RegisterForm } from "../LoginView/RegisterForm";
import { AccountForm } from "./AccountForm";

export interface IAccountManagementViewProps {
  IsLoggedIn: boolean;
}
export interface IAccountManagementViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  allowedPubs: ILoginLibrary[];
  currentPub: ILoginLibrary | null;
  daysToExpiry: number;
  licensingToken: Models.ILicensingToken | null;
  newSiteUrl: string;
  offlineLicenseKey: string;
}
export class AccountManagementView extends React.Component<IAccountManagementViewProps, IAccountManagementViewState> {
  context: AppSession;
  static contextType = AppContext;
  publicationPackTable = React.createRef<DataTable>();
  userTable = React.createRef<DataTable>();
  mainViewRef = React.createRef<HTMLDivElement>();
  constructor(props: IAccountManagementViewProps) {
    super(props);
    this.state = {
      allowedPubs: [],
      currentDrawerContent: null,
      currentPub: null,
      drawerShow: false,
      daysToExpiry: 0,
      licensingToken: null,
      newSiteUrl: "",
      offlineLicenseKey: "",
    };
  }
  componentDidMount() {
    this.getServerExpiryInfo();
    this.context.viewedViews.get(DashboardView.AccountManagement)!.loading.on(this.initialize);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.AccountManagement)!.loading.off(this.initialize);
  }
  initialize = () => {
    this.context.viewedViews.get(DashboardView.AccountManagement)!.progressLoading();
  };
  private initializeUsers = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowUsers({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private userFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowUsers({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  generateUser = (n: INode) => {
    let node = n as Models.IUserViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.User.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} key={1} className="rightBorder leftBorder centerText" value={node.User.DisplayName} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="rightBorder leftBorder centerText" value={node.User.Email} />);
    dataItems.push(<DataItem flexVal={1} key={3} className="rightBorder leftBorder centerText" value={Models.UserStatus[node.User.UserStatus]} />);
    dataItems.push(<DataItem flexVal={1} key={4} className="rightBorder leftBorder centerText" value={node.AuthUser !== null} />);
    dataItems.push(<DataItem flexVal={1} key={5} className="rightBorder leftBorder centerText" value={node.Licenses.length.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={6} className="rightBorder leftBorder centerText" value={node.Permissions.length.toString()} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.userEdit} />;
  };

  userEdit = (n: INode) => {
    let node = n as Models.IUserViewModel;
    this.setState({
      currentDrawerContent: (
        <AccountForm
          reloadUsers={() => this.userTable.current!.reload()}
          closeDrawer={() => this.setState({ currentDrawerContent: null, drawerShow: false })}
          initialNode={node}
        />
      ),
      drawerShow: true,
    });
  };
  private productQueryExecute = (query: string) => {
    this.userTable.current!.reload(query);
  };

  getServerExpiryInfo = async () => {
    let response = await this.context.getServerLicensing({});
    if (response.valid()) {
      this.setState({ daysToExpiry: response.data.DaysToExpiry, licensingToken: response.data.LicensingToken });
    }
  };
  redeemLicensingToken = async () => {
    let result = await Messages.Dialog.confirm(
      "You are going to redeem a licensing token for the server instance. This cannot be undone. Do you wish to continue?",
      `Redeem Licensing Token`
    );
    if (result === "true") {
      let f = document.createElement("input");
      f.style.display = "none";
      f.type = "file";
      f.name = "file";
      f.accept = ".cogniToken";
      f.onchange = this.innerRedeemLicensingToken;
      this.mainViewRef.current!.appendChild(f);
      f.click();
    }
  };
  createAccount = () => {
    this.setState({
      currentDrawerContent: <RegisterForm returnToLogin={() => this.setState({ currentDrawerContent: null, drawerShow: false })} publisherId={-1} />,
      drawerShow: true,
    });
  };
  innerRedeemLicensingToken = (e: Event) => {
    let inp = e.target as HTMLInputElement;
    let reader = new FileReader();
    reader.onload = async () => {
      const dataReplace = /data: ?.*; ?base64. ?/g;
      let rawData = atob((reader.result! as string).replace(dataReplace, ""));
      let response = await this.context.executeLicensingToken({ TokenBytes: rawData });
      if (response.valid()) {
        Messages.Notify.success("Licensing token redeemed successfully!");
        this.getServerExpiryInfo();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    };
    reader.readAsDataURL(inp.files![0]!);
  };
  setServerLicense = () => {
    let f = document.createElement("input");
    f.style.display = "none";
    f.type = "file";
    f.name = "file";
    f.accept = ".dash";
    f.onchange = this.innersetServerLicense;
    this.mainViewRef.current!.appendChild(f);
    f.click();
  };
  setSiteUrl = async () => {
    let response = await this.context.insertOrUpdateConfigPair({
      ConfigPair: { Index: -1, IsFirst: false, IsLast: false, Name: "SiteUrl", Value: this.state.newSiteUrl },
    });
    if (response.valid()) {
      Messages.Notify.success("Site url set successfully!");
      this.getServerExpiryInfo();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  setOfflineLicenseKey = () => {
    this.setState({
      currentDrawerContent: <OfflineLicenseForm />,
      drawerShow: true,
    });
  };
  innersetServerLicense = (e: Event) => {
    let inp = e.target as HTMLInputElement;
    let reader = new FileReader();
    reader.onload = async () => {
      const dataReplace = /data: ?.*; ?base64. ?/g;
      let rawData = atob((reader.result! as string).replace(dataReplace, ""));
      let response = await this.context.insertOrUpdateConfigPair({
        ConfigPair: { Index: -1, IsFirst: false, IsLast: false, Name: "PubTokenHandler", Value: rawData },
      });
      if (response.valid()) {
        Messages.Notify.success("Server License set successfully!");
        this.getServerExpiryInfo();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    };
    reader.readAsDataURL(inp.files![0]!);
  };
  render() {
    if (
      !this.props.IsLoggedIn ||
      (!this.context.canManageAccounts() && !this.context.canManageOfflinePackages()) ||
      !this.context.viewedViews.get(DashboardView.AccountManagement)!.isLoaded()
    ) {
      return "";
    }
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.maxHeight = "500px";
    return (
      <div ref={this.mainViewRef} className="mainView">
        <div className="accountManagementView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="accountManagementViewInner">
              <div className="section">
                <h1>Welcome to the Account Management View</h1>
                <p>
                  This view is available to system admins and account managers to view the user accounts on the system. As an account manager in this view you
                  can:
                </p>
                <ul>
                  <li>View all the users on the system</li>
                  <li>View the licenses for a given user</li>
                  <li>View the Authprovider status of a given user</li>
                  <li>Edit a user&apos;s information</li>
                  <li>Reset a user&apos;s password and optionally get the password reset link to send to the user or use yourself (prolibro accounts only).</li>
                  <li>Input an offline license key and retrieve the passphrase for an offline package registration.</li>
                </ul>
                <p>Click the button below to apply a license extension token. These are issued to data center installations at the invoice period.</p>
                {this.state.licensingToken === null && (
                  <p style={{ color: "red" }}>
                    This Connect & Dashboard instance is currently unlicensed! Reader and Builder requests will be revoked. Please contact CogniLore.
                  </p>
                )}
                {this.state.licensingToken !== null && this.state.daysToExpiry > 0 && (
                  <p style={{ color: "green" }}>
                    This instance of Connect & Dashboard is licensed through to{" "}
                    {Convert.dateToFormattedString(this.state.licensingToken.ExpirationDate, Languages.English)} (UTC) ({this.state.daysToExpiry} days)
                  </p>
                )}
                {this.state.licensingToken !== null && this.state.daysToExpiry < 0 && this.state.daysToExpiry >= -30 && (
                  <p style={{ color: "red" }}>
                    This instance of Connect & Dashboard expired on {Convert.dateToFormattedString(this.state.licensingToken.ExpirationDate, Languages.English)}{" "}
                    (UTC)! Reader and Builder requests will be revoked in {this.state.daysToExpiry + 30} days
                  </p>
                )}
                {this.state.licensingToken !== null && this.state.daysToExpiry < 0 && this.state.daysToExpiry < -30 && (
                  <p style={{ color: "red" }}>
                    This instance of Connect & Dashboard expired on {Convert.dateToFormattedString(this.state.licensingToken.ExpirationDate, Languages.English)}{" "}
                    (UTC) and the 30 day grace period has elapsed. Reader and Builder requests are now being revoked. Please contact CogniLore for updated
                    licensing.
                  </p>
                )}
                <Col>
                  <Row>
                    {this.context.canManageAccounts() && (
                      <React.Fragment>
                        <Button onClick={this.redeemLicensingToken} outline color="info" style={{ width: "fit-content" }}>
                          Upload Connect License Token
                        </Button>
                        <Button onClick={this.setServerLicense} outline color="info" style={{ width: "fit-content", marginLeft: "15px" }}>
                          Set Server License
                        </Button>
                      </React.Fragment>
                    )}
                    {this.context.canManageOfflinePackages() && (
                      <Button
                        onClick={this.setOfflineLicenseKey}
                        outline
                        color="info"
                        style={{ width: "fit-content", marginLeft: "15px", height: "fit-content" }}
                      >
                        Input Offline License Key
                      </Button>
                    )}
                  </Row>
                  {this.context.canManageAccounts() && (
                    <Row style={{ marginTop: "15px" }}>
                      <FormGroup>
                        <Input placeholder="New Site Url" onChange={(e) => this.setState({ newSiteUrl: e.target.value })} />
                      </FormGroup>
                      <Button onClick={this.setSiteUrl} outline color="info" style={{ width: "fit-content", marginLeft: "15px", height: "fit-content" }}>
                        Set Site URL
                      </Button>
                    </Row>
                  )}
                </Col>
              </div>
              {this.context.canManageAccounts() && (
                <div className="section">
                  <h2>Users</h2>
                  <DataTable
                    headers={["Display name", "Email", "Status", "Is Auth User?", "License count", "Permissions"]}
                    headerFlexes={[2, 2, 1, 1, 1, 1]}
                    flowProvider={this.userFlowProvider}
                    initializeFlowProvider={this.initializeUsers}
                    objectBuilder={this.generateUser}
                    ref={this.userTable}
                    searchQueryComitted={this.productQueryExecute}
                    rowAddRequested={this.createAccount}
                    settingsOverride={settings}
                  />
                </div>
              )}
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
