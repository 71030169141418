import { Guid } from "guid-typescript";
import * as React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { DashboardView } from "src/ui/foundation/Layout";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import { Drawer, DrawerContainer } from "../../foundation/Controls";
import { DataItem, DataRow, DataTable } from "../../foundation/DataTable";
import * as Messages from "../../foundation/Messages";
import { Action, INode, IRequest, IResponse } from "../../foundation/StandaloneCogniflow";
import { AppContext } from "../../state/Contextes";
import { AuthenticationProviderForm } from "./AuthenticationProviderForm";
import { ConfigurationPairForm } from "./ConfigurationPairForm";
import { CreateServerLicensingTokenForm } from "./CreateLicensingTokenForm";
import { CreatePublicationPackTokenForm } from "./CreatePublicationPackTokenForm";
import { OpenIddictApplicationForm } from "./OpenIddictApplicationForm";
import { FormMode, PermissionForm } from "./PermissionForm";

export interface ISystemViewProps {
  IsLoggedIn: boolean;
}
export interface ISystemViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  permissionQuery: string;
  currPub: number;
}
export class SystemView extends React.Component<ISystemViewProps, ISystemViewState> {
  context: AppSession;
  static contextType = AppContext;
  configPairTable = React.createRef<DataTable>();
  authProviderTable = React.createRef<DataTable>();
  permissionTable = React.createRef<DataTable>();
  publisherApplicationTable = React.createRef<DataTable>();
  openIddictApplicationTable = React.createRef<DataTable>();

  constructor(props: ISystemViewProps) {
    super(props);
    this.state = { drawerShow: false, currentDrawerContent: null, permissionQuery: "", currPub: 0 };
  }
  loginInit = () => {
    this.context.viewedViews.get(DashboardView.System)!.progressLoading();
  };
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.System)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.System)!.loading.off(this.loginInit);
  }
  /* #region ConfigPairs */
  private initializeConfigPairs = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowConfigPairs({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0 },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private configPairFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowConfigPairs({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private saveConfigPair = async (e: Models.IConfigurationPair) => {
    let response = await this.context.insertOrUpdateConfigPair({ ConfigPair: e });
    if (response.valid()) {
      Messages.Notify.success(`The Configuration Pair ${e.Name} was saved successfully!`);
      this.configPairTable.current!.reload();
      this.setState({ currentDrawerContent: null, drawerShow: false });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  private deleteConfigPair = async (e: Models.IConfigurationPair) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete ${e.Name}?`);
    if (result === "true") {
      let response = await this.context.deleteConfigPair({ ConfigPair: e });
      if (response.valid()) {
        Messages.Notify.success(`The Configuration Pair ${e.Name} was deleted successfully!`);
        this.configPairTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private configPairRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <ConfigurationPairForm initialNode={e as Models.IConfigurationPair} saveRequested={this.saveConfigPair} deleteRequested={this.deleteConfigPair} />
      ),
    });
  };
  private configPairInsertRequest = () => {
    let blankModel: Models.IConfigurationPair = { Name: "", Value: "", Index: -1, IsFirst: false, IsLast: false };
    this.setState({ drawerShow: true, currentDrawerContent: <ConfigurationPairForm initialNode={blankModel} saveRequested={this.saveConfigPair} /> });
  };
  private generateConfigPair = (n: INode): JSX.Element => {
    let node = n as Models.IConfigurationPair;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Index;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={1} key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="leftBorder" value={node.Value} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.configPairRowEditRequest} />;
  };
  /* #endregion */

  /* #region Auth Providers */
  private initializeAuthProvider = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowAuthenticationProviders({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0 },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private authProviderFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowAuthenticationProviders({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private authProviderRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <AuthenticationProviderForm
          initialNode={e as Models.IAuthenticationProvider}
          saveRequested={this.saveAuthProvider}
          deleteRequested={this.deleteAuthProvider}
        />
      ),
    });
  };
  private authProviderInsertRequest = () => {
    let blankModel: Models.IAuthenticationProvider = {
      Method: Models.AuthMethod.Undefined,
      Provider: Models.AuthProviderType.AuthUndefined,
      Template: "",
      TemplateType: Models.AuthTemplateType.Undefined,
      TableId: -1,
      Index: -1,
      IsFirst: false,
      IsLast: false,
    };

    this.setState({ drawerShow: true, currentDrawerContent: <AuthenticationProviderForm initialNode={blankModel} saveRequested={this.saveAuthProvider} /> });
  };
  private saveAuthProvider = async (e: Models.IAuthenticationProvider) => {
    let response = await this.context.insertOrUpdateAuthenticationProvider({ AuthenticationProvider: e });
    if (response.valid()) {
      Messages.Notify.success(`The Authentication Provider item was saved successfully!`);
      this.authProviderTable.current!.reload();
      this.setState({ currentDrawerContent: null, drawerShow: false });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  private deleteAuthProvider = async (e: Models.IAuthenticationProvider) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this item?`);
    if (result === "true") {
      let response = await this.context.deleteAuthenticationProvider({ AuthenticationProvider: e });
      if (response.valid()) {
        Messages.Notify.success(`The Authentication Provider item was deleted successfully!`);
        this.authProviderTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private generateAuthProvider = (n: INode): JSX.Element => {
    let node = n as Models.IAuthenticationProvider;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={1} key={1} value={Models.AuthProviderType[node.Provider]} />);
    dataItems.push(<DataItem className="rightBorder leftBorder" flexVal={1} key={2} value={Models.AuthMethod[node.Method]} />);
    dataItems.push(<DataItem flexVal={1} key={3} value={Models.AuthTemplateType[node.TemplateType]} />);
    dataItems.push(<DataItem className="leftBorder" flexVal={5} key={4} value={node.Template} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.authProviderRowEditRequest} />;
  };
  /* #endregion */

  /* #region Permissions */
  private initializePermission = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> => {
    if (!query) {
      query = "";
    }
    return new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowPermissions({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  };
  private permissionFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowPermissions({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private permissionRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <PermissionForm
          initialNode={e as Models.IPermissionViewModel}
          saveRequested={this.savePermission}
          deleteRequested={this.deletePermission}
          reloadPermissions={this.reloadPermissionsAndDismiss}
        />
      ),
    });
  };
  private permissionInsertRequest = () => {
    let d1yr = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    let blankModel: Models.IPermissionViewModel = {
      Permission: {
        CreatedDate: new Date(Date.now()),
        ExpirationDate: d1yr,
        GrantedByUserId: -1,
        ManageProducts: false,
        ManagePublishers: false,
        ManageSubscription: false,
        ManageSystem: false,
        ManageTitles: false,
        ManageAccounts: false,
        ManageBulletins: false,
        ManageFeedback: false,
        ManageTips: false,
        ManageOfflinePackages: false,
        PublisherId: null,
        SubscriptionId: null,
        TableId: -1,
        UserId: -1,
        ManageReporting: false,
      },
      Index: -1,
      IsFirst: false,
      IsLast: false,
    };

    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <PermissionForm initialNode={blankModel} saveRequested={this.savePermission} reloadPermissions={this.reloadPermissionsAndDismiss} />
      ),
    });
  };
  private reloadPermissionsAndDismiss = () => {
    this.permissionTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };
  private savePermission = async (e: Models.IPermissionViewModel) => {
    let response = await this.context.insertOrUpdatePermission({ Permission: e.Permission });
    if (response.valid()) {
      Messages.Notify.success(`The Permission item was saved successfully!`);
      this.reloadPermissionsAndDismiss();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  private deletePermission = async (e: Models.IPermissionViewModel) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this Permission? The user will lose all rights associated with it.`);
    if (result === "true") {
      let response = await this.context.deletePermission({ Permission: e.Permission });
      if (response.valid()) {
        Messages.Notify.success(`The Permission item was deleted successfully!`);
        this.reloadPermissionsAndDismiss();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private generatePermission = (n: INode): JSX.Element => {
    let node = n as Models.IPermissionViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Permission.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    if (node.AuthUser) {
      dataItems.push(
        <DataItem flexVal={2} key={1} value={null}>
          <span title={node.AuthUser.AuthUserName + " using " + Models.AuthProviderType[node.AuthUser.AuthProvider]}>
            {node.AuthUser.AuthUserName + (node.Permission.SubscriptionId !== null ? " (curator) " : "")}{" "}
            <i> using {Models.AuthProviderType[node.AuthUser.AuthProvider]}</i>
          </span>
        </DataItem>
      );
    } else {
      dataItems.push(<DataItem flexVal={2} key={1} value={node.User!.LoginName + (node.Permission.SubscriptionId !== null ? " (curator) " : "")} />);
    }

    let value = "";
    if (node.Permission.ExpirationDate === null) {
      value = "Never";
    } else if (new Date(node.Permission.ExpirationDate) < new Date()) {
      value = "Expired";
    } else {
      value = Convert.dateToFormattedString(node.Permission.ExpirationDate, Languages.English);
    }
    if (value === "Expired") {
      dataItems.push(
        <DataItem flexVal={2} className="centerText" key={3} value={null}>
          <span style={{ color: "red" }}>{value}</span>
        </DataItem>
      );
    } else if (value === "Never") {
      dataItems.push(<DataItem flexVal={2} className="centerText bolded" key={3} value={value} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="centerText" key={3} value={value} />);
    }
    if (node.Permission.PublisherId === null) {
      dataItems.push(<DataItem flexVal={2} className="centerText bolded" key={4} value={"All"} />);
    } else {
      dataItems.push(<DataItem flexVal={2} key={4} value={node.Publisher!.Name} />);
    }
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={6} value={node.Permission.ManageSystem} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={11} value={node.Permission.ManageAccounts} />);
    dataItems.push(<DataItem flexVal={1} key={7} value={node.Permission.ManagePublishers} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={8} value={node.Permission.ManageSubscription} />);
    dataItems.push(<DataItem flexVal={1} key={9} value={node.Permission.ManageProducts} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={10} value={node.Permission.ManageTitles} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={12} value={node.Permission.ManageBulletins} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={13} value={node.Permission.ManageFeedback} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={14} value={node.Permission.ManageTips} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={15} value={node.Permission.ManageReporting} />);
    dataItems.push(<DataItem flexVal={1} className="leftBorder" key={16} value={node.Permission.ManageOfflinePackages} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.permissionRowEditRequest} />;
  };
  private permissionQueryExecute = (query: string) => {
    this.permissionTable.current!.reload(query);
  };
  /* #endregion */

  /* #region IDServer OpenIddictApplications */
  private openIddictAppFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowOpenIddictApplications({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private initializeOpenIddictApp = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowOpenIddictApplications({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0 },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private saveOpenIddictApplication = async (e: Models.IOpenIddictApplication) => {
    // check for existing clientId
    let openIddictApplicationR = await this.context.getOpenIddictApplication({ OpenIddictApplication: e });
    if (openIddictApplicationR.data?.OpenIddictApplication == null) {
      let response = await this.context.insertOrUpdateOpenIddictApplication({ OpenIddictApplication: e });
      if (response.valid()) {
        let result = await Messages.Dialog.confirm(
          `This is the new client secret.  Click Yes to Copy to Clipboard, or write it down.  This is the only time the unhashed value is displayed.  The system administrators have been sent an email with the details.\n Client Secret:  ` +
            response.data.ClientSecret,
          `Copy Client Secret`
        );
        if (result === "true") {
          navigator.clipboard.writeText(response.data.ClientSecret).then(
            () => {
              console.log("Copying to clipboard was successful!");
            },
            (err) => {
              console.error("Could not copy text: ", err);
            }
          );
        }
        Messages.Notify.success(`The OpenIddict Application item was saved successfully!`);
        this.openIddictApplicationTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    } else {
      Messages.Notify.error("ClientId must be unique.  An OpenIddictApplication with clientId " + e.ClientId + " already exists.");
    }
  };
  private deleteOpenIddictApplication = async (e: Models.IOpenIddictApplication) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this item?`);
    if (result === "true") {
      let response = await this.context.deleteOpenIddictApplication({ OpenIddictApplication: e });
      if (response.valid()) {
        Messages.Notify.success(`The OpenIddictApplication item was deleted successfully!`);
        this.openIddictApplicationTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private getExpirationDate = () => {
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let c = new Date(year + 1, month, day);
    return c;
  };
  private openIddictAppInsertRequest = () => {
    let blankModel: Models.IOpenIddictApplication = {
      Id: Guid.create().toString(),
      DisplayName: "",
      ClientId: "",
      Permissions: '["ept:token","ept:introspection","gt:client_credentials","scp:scim"]',
      ExpirationDate: this.getExpirationDate(),
      PublisherId: 0,
      Index: -1,
      IsFirst: false,
      IsLast: false,
    };

    this.setState({
      drawerShow: true,
      currentDrawerContent: <OpenIddictApplicationForm initialNode={blankModel} saveRequested={this.saveOpenIddictApplication} />,
    });
  };
  private openIddictRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <OpenIddictApplicationForm
          initialNode={e as Models.IOpenIddictApplication}
          saveRequested={this.saveOpenIddictApplication}
          deleteRequested={this.deleteOpenIddictApplication}
        />
      ),
    });
  };
  private generateOpenIddictApplication = (n: INode): JSX.Element => {
    let node = n as Models.IOpenIddictApplication;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Id;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={1} key={1} value={node.Id} />);
    dataItems.push(<DataItem flexVal={1} key={2} value={node.DisplayName} />);
    dataItems.push(<DataItem flexVal={1} key={3} value={node.ClientId} />);
    // dataItems.push(<DataItem flexVal={1} key={4} value={node.ClientSecret} />);
    // dataItems.push(<DataItem className="leftBorder" flexVal={1} key={5} value={node.Permissions} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.openIddictRowEditRequest} />;
  };
  /* #endregion */

  createPublicationPackToken = () => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: <CreatePublicationPackTokenForm dismissDrawer={() => this.setState({ currentDrawerContent: null, drawerShow: false })} />,
    });
  };
  createServerLicensingToken = () => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: <CreateServerLicensingTokenForm dismissDrawer={() => this.setState({ currentDrawerContent: null, drawerShow: false })} />,
    });
  };
  deleteTestPublisher = async () => {
    let dResp = await Messages.Dialog.confirm(
      <div>
        <span>
          Are you absolutely sure you wish to delete this publisher? This will remove it entirely from the system as if it never existed.{" "}
          <b>This is not used lightly and cannot be reversed.</b>
        </span>
      </div>,
      "Delete publisher?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (dResp === "true") {
      let response = await this.context.deletePublisher({ PublisherId: this.state.currPub });
      if (response.valid()) {
        Messages.Notify.success(`The Publisher item was deleted successfully!`);
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  invitePubManager = () => {
    let d1yr = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    let perm: Models.IPermissionViewModel = {
      Index: -1,
      IsFirst: false,
      IsLast: false,
      Permission: {
        CreatedDate: new Date(),
        ExpirationDate: d1yr,
        GrantedByUserId: -1,
        ManageProducts: false,
        ManagePublishers: true,
        ManageSubscription: false,
        ManageSystem: false,
        ManageTitles: false,
        ManageBulletins: false,
        ManageFeedback: false,
        ManageTips: false,
        ManageOfflinePackages: false,
        PublisherId: null,
        SubscriptionId: null,
        TableId: -1,
        UserId: -1,
        ManageAccounts: false,
        ManageReporting: false,
      },
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <PermissionForm
          formMode={FormMode.SystemAdmin}
          saveRequested={this.savePermission}
          initialNode={perm}
          reloadPermissions={() => {}}
          goBackBtnClicked={() => this.setState({ drawerShow: false, currentDrawerContent: null })}
        />
      ),
    });
  };

  render() {
    if (!this.props.IsLoggedIn || !this.context.canManageAccounts() || !this.context.viewedViews.get(DashboardView.System)!.isLoaded()) {
      return "";
    }
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 25;
    return (
      <div className="mainView">
        <div className="systemView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="systemViewInner">
              <div className="section">
                <h1>Welcome to the System View</h1>
                <p>
                  Welcome to the system view. This view is reserved for system administrators that wish to make changes to functional aspects of the Dashboard
                  system. It contains sections controlling:
                </p>
                <ul>
                  <li>Permissions</li>
                  <li>Server configurations</li>
                  <li>Authority providers</li>
                  <li>Log management</li>
                  <li>IDServer management</li>
                </ul>
                <p>If you are seeing this page, it&apos;s because you are listed as an administrator of the Dashboard system for proLibro Connect.</p>
                <Col>
                  <Row>
                    {this.context.canManageSystem() && (
                      <Button style={{ width: "fit-content", margin: "10px" }} onClick={this.createPublicationPackToken} outline color="info">
                        Create Publication Pack Token
                      </Button>
                    )}
                    {this.context.canManageSystem() && (
                      <Button style={{ width: "fit-content", margin: "10px" }} onClick={this.createServerLicensingToken} outline color="info">
                        Create Server Licensing Token
                      </Button>
                    )}
                  </Row>
                  {this.context.canManageSystem() && (
                    <div>
                      <h2>Publisher deletion</h2>
                      <Row>
                        <FormGroup>
                          <Label for="templateName">Publisher Id</Label>
                          <Input type="number" onChange={(e) => this.setState({ currPub: +e.target.value })} />
                        </FormGroup>
                      </Row>
                      <Button style={{ width: "fit-content", margin: "10px" }} onClick={this.deleteTestPublisher} outline color="info">
                        Delete Publisher
                      </Button>
                    </div>
                  )}
                </Col>
              </div>
              {this.context.canManageAccounts() && (
                <div className="section">
                  <h2>Permission invitations panel</h2>
                  <p>
                    Here are some quick links to extend permissions to a user in the system. You can see these because you have a &quot;Manage Accounts&quot;
                    role in the system.
                  </p>
                  <Button outline color="info" style={{ width: "fit-content" }} onClick={this.invitePubManager}>
                    Extend permission to user
                  </Button>
                </div>
              )}
              {this.context.canManageAccounts() && (
                <div className="section">
                  <h2>Permissions</h2>
                  <p>
                    Permissions govern what a given user can do both in Dashboard and within the Connect system. A user with elevated permissions will see
                    different view on the dashboard and possibly see more titles in their Reader beyond what their Licences afford them.
                  </p>
                  <DataTable
                    headers={[
                      "Username",
                      "Expires (UTC)",
                      "Publisher",
                      "Manage System",
                      "Manage Accounts",
                      "Manage Publishers",
                      "Manage Subs",
                      "Manage Products",
                      "Manage Titles",
                      "Manage Bulletins",
                      "Manage Feedback",
                      "Manage Tips",
                      "Manage Reporting",
                      "Manage Offline Packages",
                    ]}
                    headerFlexes={[2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                    rowAddRequested={this.permissionInsertRequest}
                    flowProvider={this.permissionFlowProvider}
                    initializeFlowProvider={this.initializePermission}
                    objectBuilder={this.generatePermission}
                    ref={this.permissionTable}
                    settingsOverride={settings}
                    searchQueryComitted={this.permissionQueryExecute}
                  />
                </div>
              )}
              {this.context.canManageSystem() && (
                <div className="section">
                  <h2>Configuration Pairs</h2>
                  <p>
                    Configuration pairs are a series of Key+Value combinations that configure various features of the Server. Generally these are used by the
                    server business layer to operate various things within Dashboard and the Connect systems.{" "}
                  </p>
                  <DataTable
                    headers={["Name", "Value"]}
                    headerFlexes={[1, 2]}
                    rowAddRequested={this.configPairInsertRequest}
                    flowProvider={this.configPairFlowProvider}
                    initializeFlowProvider={this.initializeConfigPairs}
                    objectBuilder={this.generateConfigPair}
                    ref={this.configPairTable}
                    settingsOverride={settings}
                  />
                </div>
              )}
              {this.context.canManageSystem() && (
                <div className="section">
                  <h2>Authorization Providers</h2>
                  <p>
                    Authorization providers are values used by connect to login to various publisher servers to fetch subscriptions to products through the
                    Reader. Generally, these are only created and deleted by developers and their templates edited as publisher needs change.
                  </p>
                  <DataTable
                    headers={["Provider Type", "Method Type", "Template Type", "Template"]}
                    headerFlexes={[1, 1, 1, 5]}
                    rowAddRequested={this.authProviderInsertRequest}
                    flowProvider={this.authProviderFlowProvider}
                    initializeFlowProvider={this.initializeAuthProvider}
                    objectBuilder={this.generateAuthProvider}
                    ref={this.authProviderTable}
                    settingsOverride={settings}
                  />
                </div>
              )}
              {this.context.canManageSystem() && (
                <div className="section">
                  <h2>IDServer: OpenIddict Applications</h2>
                  <p>Manage OpenIddict Applications registered with IDServer. </p>
                  <DataTable
                    headers={["Application Id", "Display Name", "Client Id"]}
                    headerFlexes={[1, 1, 1]}
                    rowAddRequested={this.openIddictAppInsertRequest}
                    flowProvider={this.openIddictAppFlowProvider}
                    initializeFlowProvider={this.initializeOpenIddictApp}
                    objectBuilder={this.generateOpenIddictApplication}
                    ref={this.openIddictApplicationTable}
                    settingsOverride={settings}
                  />
                </div>
              )}
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
