import { IId } from 'src/models/Interfaces';
import { Binding, Emitter, EmitterEvent } from 'src/utilities/Events';

import { Dictionary, IDictionary, ImmutableDictionary } from './Generics';

/**
 * Encapsulate a single value in a emitting wrapper.
 */
export class Single<T> extends Emitter<T> {
  private value: T;
  constructor(value?: T) {
    super();
    if (value !== undefined) {
      this.value = value;
    }
  }
  get(): T {
    return this.value;
  }

  set(value: T): void {
    this.value = value;
    this.emit(this.value);
  }
}

export interface ITable<T> extends Emitter<T> {
  pairedRows: IDictionary<number | string, T>;
  length: number;
  rows(): T[];
  keys(): Array<number | string>;
  get(id: number | string): T | undefined;
  exists(id: number | string): boolean;
  insertOrUpdateAll(items: T[]): void;
  insertOrUpdate(item: T): void;
  delete(item: T): void;
  deleteById(id: number | string): void;
  deleteAll(): void;
  bind(item: T): Binding<T>;
  [Symbol.iterator](): any;
}

export class Table<T> extends Emitter<T> implements ITable<T> {
  pairedRows: Dictionary<number | string, T>;
  bindings: Dictionary<number | string, Binding<T>>;

  private idFunc: (a: T) => number | string;

  constructor(getIdFunc?: (a: T) => number | string) {
    super();
    this.pairedRows = new Dictionary<number | string, T>();
    this.bindings = new Dictionary<number | string, Binding<T>>();
    if (getIdFunc === undefined) {
      this.idFunc = (a: any) => (a as IId).id;
    } else {
      this.idFunc = getIdFunc;
    }
  }

  rows(): T[] {
    return this.pairedRows.values();
  }
  keys(): Array<number | string> {
    return this.pairedRows.keys();
  }

  get(id: number | string): T | undefined {
    return this.pairedRows.get(id);
  }

  exists(id: number | string) {
    return this.pairedRows.containsKey(id);
  }

  insertOrUpdateAll(items: T[]): void {
    for (let item of items) {
      this.pairedRows.set(this.idFunc(item), item);
    }
    // Two loops for consistency with the behaviour of the ImmutableTable
    for (let item of items) {
      this.fireBinding(item);
    }
    this.emit(undefined, EmitterEvent.insert);
  }

  insertOrUpdate(item: T): void {
    let event = EmitterEvent.insert;
    if (this.exists(this.idFunc(item))) {
      event = EmitterEvent.update;
    }
    this.pairedRows.set(this.idFunc(item), item);
    this.fireBinding(item);
    this.emit(item, event);
  }

  delete(item: T, suppressEmission = false): void {
    this.deleteById(this.idFunc(item), suppressEmission);
  }

  deleteById(id: number | string, suppressEmission = false): void {
    let item = this.get(id);
    this.pairedRows.remove(id);
    if (!suppressEmission) {
      this.emit(item, EmitterEvent.delete, id);
    }
  }

  deleteAll(suppressEmission?: boolean) {
    let keys = [...this.keys()];
    keys.map((x) => {
      this.deleteById(x, suppressEmission);
    });
    this.emit(undefined, EmitterEvent.deleteAll);
  }

  bind(item: T): Binding<T> {
    if (!this.exists(this.idFunc(item))) {
      this.insertOrUpdate(item);
    }
    if (!this.bindings.containsKey(this.idFunc(item))) {
      this.bindings.set(this.idFunc(item), new Binding<T>(item));
    }
    return this.bindings.get(this.idFunc(item))!;
  }

  private fireBinding(item: T) {
    if (this.bindings.containsKey(this.idFunc(item))) {
      this.bindings.get(this.idFunc(item))!.dispatch(item, this);
    }
  }

  [Symbol.iterator]() {
    return this.pairedRows.iterator();
  }

  get length(): number {
    return this.pairedRows.count();
  }
}

export class ImmutableTable<T> extends Emitter<T> implements ITable<T> {
  pairedRows: ImmutableDictionary<number | string, T>;
  bindings: Dictionary<number | string, Binding<T>>;
  private idFunc: (a: T) => number | string;

  constructor(getIdFunc?: (a: T) => number | string) {
    super();
    this.pairedRows = new ImmutableDictionary<number | string, T>();
    this.bindings = new Dictionary<number | string, Binding<T>>();
    if (getIdFunc === undefined) {
      this.idFunc = (a: any) => (a as IId).id;
    } else {
      this.idFunc = getIdFunc;
    }
  }

  rows(): T[] {
    return this.pairedRows.values();
  }

  keys(): Array<number | string> {
    return this.pairedRows.keys();
  }

  get(id: number | string): T | undefined {
    return this.pairedRows.get(id);
  }

  exists(id: number | string) {
    return this.pairedRows.containsKey(id);
  }

  insertOrUpdateAll(items: T[]): void {
    const itemsMappedToArray = items.map((item) => [this.idFunc(item), item] as [number | string, T]);
    this.pairedRows = this.pairedRows.setAll(itemsMappedToArray);

    for (let item of items) {
      this.fireBinding(item);
    }

    this.emit(undefined, EmitterEvent.update);
  }

  insertOrUpdate(item: T): void {
    let event = EmitterEvent.insert;
    if (this.exists(this.idFunc(item))) {
      event = EmitterEvent.update;
    }
    this.pairedRows = this.pairedRows.set(this.idFunc(item), item);
    this.fireBinding(item);
    this.emit(item, event);
  }

  delete(item: T, suppressEmission = false): void {
    this.deleteById(this.idFunc(item), suppressEmission);
  }

  deleteById(id: number | string, suppressEmission = false): void {
    let item = this.get(id);
    this.pairedRows = this.pairedRows.remove(id);
    this.emit(item, EmitterEvent.delete, id);
    if (!suppressEmission) {
      this.emit(item, EmitterEvent.delete, id);
    }
  }

  deleteAll() {
    this.pairedRows = this.pairedRows.clear();
    this.emit(undefined, EmitterEvent.deleteAll);
  }

  bind(item: T): Binding<T> {
    if (!this.exists(this.idFunc(item))) {
      this.insertOrUpdate(item);
    }
    if (!this.bindings.containsKey(this.idFunc(item))) {
      this.bindings.set(this.idFunc(item), new Binding<T>(item));
    }
    return this.bindings.get(this.idFunc(item))!;
  }

  private fireBinding(item: T) {
    if (this.bindings.containsKey(this.idFunc(item))) {
      this.bindings.get(this.idFunc(item))!.dispatch(item, this);
    }
  }

  [Symbol.iterator]() {
    return this.pairedRows.iterator();
  }

  get length(): number {
    return this.pairedRows.count();
  }
}
