import * as React from "react";
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { AppSession, LoginType } from "src/models/AppSession";
import { ILibraryConfig } from "src/models/dto/DashboardModels";
import { FormState } from "src/utilities/FormState";
import { Convert } from "src/utilities/Helpers";
import { Validator } from "src/utilities/Validator";

import * as Models from "../../../models/dto/DashboardModels";
import { Image } from "../../foundation/Assets";
import { Icon, Loading } from "../../foundation/Controls";
import * as Messages from "../../foundation/Messages";
import { AppContext } from "../../state/Contextes";

export interface ILibraryConfigFormProps {
  selectedPublisher: number;
  publisherChangedId: (pubId: number) => void;
}
export interface ILibraryConfigFormState {
  isInitialized: boolean;
  form: FormState;
  loading: boolean;
}
export class LibraryConfigForm extends React.Component<ILibraryConfigFormProps, ILibraryConfigFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ILibraryConfigFormProps) {
    super(props);
    let form = new FormState({
      tableId: "",
      publisherId: "",
      lastModified: "",
      brandKey: "",
      version: "",
      authorizationId: 0,
      authorizationMethod: 0,
      productIdPrefix: "",
      stripePublicKey: "",
      stripePublicKeyTest: "",
      stripeStoreUrl: "",
      stripeStoreUrlTest: "",
      urlPrefix: "",
      theme: "",
      printMaxWordsCount: 0,
      copyMaxWordCountDaily: 0,
      hideRegistrationLink: false,
      isIAPEnabled: false,
      isAnonymousEnabled: false,
      isExternallyAddable: false,
      isLoginEmailBased: false,
      isRemeberMeAllowed: false,
      loginLogo: "",
      defaultBookSplash: "",
      libraryIcon: "",
      isServerUrlOverrideable: false,
      anonymousRequiresToken: false,
      isRememberMeAllowed: false,

      builtInLinkingOverride: "",
      builtInServerOverride: "",
      microsoftTenantOverride: "",
      isMicrosoftSsoEnabled: false,
      availableTitlesSectionDisabled: true,
      availableTitlesSectionDefaultsCollapsed: true,
    });

    this.state = {
      isInitialized: false,
      form,
      loading: false,
    };
  }

  initializeLibraryConfig = (libConfig: ILibraryConfig) => {
    let form = new FormState({
      tableId: libConfig.TableId ?? "",
      publisherId: libConfig.PublisherId ?? "",
      lastModified: libConfig.LastModified ?? "",
      brandKey: libConfig.BrandKey ?? "",
      version: libConfig.Version ?? "",
      authorizationId: libConfig.AuthorizationId === 0 ? 1 : libConfig.AuthorizationId,
      authorizationMethod: libConfig.AuthorizationMethod === 0 ? 1 : libConfig.AuthorizationMethod,
      productIdPrefix: libConfig.ProductIdPrefix ?? "",
      stripePublicKey: libConfig.StripePublicKey ?? "",
      stripePublicKeyTest: libConfig.StripePublicKeyTest ?? "",
      stripeStoreUrl: libConfig.StripeStoreUrl ?? "",
      stripeStoreUrlTest: libConfig.StripeStoreUrlTest ?? "",
      urlPrefix: libConfig.URLPrefix ?? "",
      theme: libConfig.Theme ?? "",
      printMaxWordsCount: libConfig.PrintMaxWordCount ?? 0,
      copyMaxWordCountDaily: libConfig.CopyMaxWordCountDaily ?? 0,
      hideRegistrationLink: libConfig.HideRegistrationLink ?? false,
      isIAPEnabled: libConfig.IsIAPEnabled ?? false,
      isLoginEmailBased: libConfig.IsLoginEmailBased ?? true,
      isAnonymousEnabled: libConfig.IsAnonymousEnabled ?? false,
      anonymousRequiresToken: libConfig.AnonymousRequiresToken ?? false,
      isServerUrlOverrideable: libConfig.IsServerUrlOverrideable ?? false,
      isExternallyAddable: libConfig.IsExternallyAddable ?? true,
      isRememberMeAllowed: libConfig.IsRememberMeAllowed ?? true,
      loginLogo: libConfig.LoginLogo ?? "",
      defaultBookSplash: libConfig.DefaultBookSplash ?? "",
      libraryIcon: libConfig.LibraryIcon ?? "",
      builtInLinkingOverride: libConfig.BuiltInLinkingOverride ?? "",
      builtInServerOverride: libConfig.BuiltInServerOverride ?? "",
      microsoftTenantOverride: libConfig.MicrosoftTenantOverride ?? "",
      isMicrosoftSsoEnabled: libConfig.IsMicrosoftSsoEnabled ?? false,
      availableTitlesSectionDisabled: libConfig.AvailableTitlesSectionDisabled ?? true,
      availableTitlesSectionDefaultsCollapsed: libConfig.AvailableTitlesSectionDefaultsCollapsed ?? true,
    });
    form.addValidator("brandKey", Validator.notNullOrWhitespace, "");

    this.setState({
      form: form,
      isInitialized: true,
    });
  };

  downloadCurrentCSS = () => {
    let element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(this.state.form.values.theme as string));
    element.setAttribute("download", this.state.form.values.brandKey + "_theme.css");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkFileType(e)) {
      if (files && files.length > 0) {
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = () => {
          let basedata = reader.result;
          if (basedata === null) {
            return;
          }
          basedata = basedata.toString();
          let form = this.state.form;
          form.values.theme = basedata;
          this.setState({
            form: form,
          });
        };
        reader.onerror = (error) => error;
        Messages.Notify.success("File uploaded successfully");
      }
    }
  };
  checkFileType = (event: React.ChangeEvent<HTMLInputElement>) => {
    // getting file object
    let files = event.target.files;
    if (files != null) {
      // define message container
      let err = "";
      // list allow mime type
      const types = ["text/css", ""];
      // loop access array
      for (let x = 0; x < files.length; x++) {
        // compare file type find doesn't matach
        if (types.every((type) => files![x].type !== type)) {
          // create error message and assign to container
          err = "Invalid file type (expected file type is .css or .scss)";
        }
      }

      if (err !== "") {
        // if message not same old that mean has error
        event.target.value = ""; // discard selected file
        console.log(err);
        Messages.Notify.error(err);
        return false;
      }
    } else {
      return false;
    }
    return true;
  };
  checkImageType = (event: any) => {
    // getting file object
    let files = event.target.files;
    // define message container
    let err = "";
    // list allow mime type
    const types = ["image/png", "image/jpeg"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err = "Invalid file type (expected file type is .png or .jpeg)";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      Messages.Notify.error(err);
      return false;
    }
    return true;
  };
  handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // event.preventDefault();
    let formlocal = this.state.form;
    formlocal = formlocal.change(event.target);
    this.setState({
      form: formlocal,
    });
  };

  getBase64 = (file: Blob, stateFunction: (basedata: string) => void): void => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let basedata = reader.result;
      if (basedata === null) {
        return;
      }
      basedata = basedata.toString();
      // Validating file type
      if (!basedata.includes("png")) {
        alert("Invalid file type");
        return;
      }
      // use regex to remove headers (data:image/png)
      if (basedata.includes("data")) {
        basedata = basedata.replace("data:", "").replace(/^.+,/, "");
      }
      stateFunction(basedata);
    };
    reader.onerror = (error) => error;
  };

  onSplashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkImageType(e)) {
      if (files && files.length > 0) {
        this.getBase64(files[0], (basedata) => {
          let form = this.state.form;
          form.values.defaultBookSplash = basedata;
          this.setState(
            {
              form: form,
            },
            () => {
              Messages.Notify.success("Image uploaded successfully");
            }
          );
        });
      }
    }
  };
  onLoginLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkImageType(e)) {
      if (files && files.length > 0) {
        this.getBase64(files[0], (basedata) => {
          let form = this.state.form;
          form.values.loginLogo = basedata;
          this.setState(
            {
              form: form,
            },
            () => {
              Messages.Notify.success("Image uploaded successfully");
            }
          );
        });
      }
    }
  };
  onLibraryIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkImageType(e)) {
      if (files && files.length > 0) {
        this.getBase64(files[0], (basedata) => {
          let form = this.state.form;
          form.values.libraryIcon = basedata;
          this.setState(
            {
              form: form,
            },
            () => {
              Messages.Notify.success("Image uploaded successfully");
            }
          );
        });
      }
    }
  };

  submit = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.form.values === undefined) {
      return;
    }
    let config: ILibraryConfig = {
      TableId: this.state.form.values.tableId,
      PublisherId: this.state.form.values.publisherId,
      LastModified: this.state.form.values.lastModified,
      BrandKey: this.state.form.values.brandKey,
      Version: this.state.form.values.version,
      AuthorizationId: this.state.form.values.authorizationId,
      AuthorizationMethod: this.state.form.values.authorizationMethod,
      ProductIdPrefix: this.state.form.values.productIdPrefix,
      StripePublicKey: this.state.form.values.stripePublicKey ?? null,
      StripePublicKeyTest: this.state.form.values.stripePublicKeyTest ?? null,
      StripeStoreUrl: this.state.form.values.stripeStoreUrl ?? null,
      StripeStoreUrlTest: this.state.form.values.stripeStoreUrlTest ?? null,
      URLPrefix: this.state.form.values.urlPrefix === "" ? this.state.form.values.brandKey : this.state.form.values.urlPrefix,
      Theme: this.state.form.values.theme,
      PrintMaxWordCount: this.state.form.values.printMaxWordsCount,
      CopyMaxWordCountDaily: this.state.form.values.copyMaxWordCountDaily,
      HideRegistrationLink: this.state.form.values.hideRegistrationLink,
      IsIAPEnabled: this.state.form.values.isIAPEnabled,
      IsLoginEmailBased: this.state.form.values.isLoginEmailBased,
      IsAnonymousEnabled: this.state.form.values.isAnonymousEnabled,
      IsExternallyAddable: this.state.form.values.isExternallyAddable,
      IsRememberMeAllowed: this.state.form.values.isRememberMeAllowed,
      AnonymousRequiresToken: this.state.form.values.anonymousRequiresToken,
      IsServerUrlOverrideable: this.state.form.values.isServerUrlOverrideable,
      LoginLogo: this.state.form.values.loginLogo,
      DefaultBookSplash: this.state.form.values.defaultBookSplash,
      LibraryIcon: this.state.form.values.libraryIcon,
      AvailableTitlesSectionDefaultsCollapsed: this.state.form.values.availableTitlesSectionDefaultsCollapsed,
      AvailableTitlesSectionDisabled: this.state.form.values.availableTitlesSectionDisabled,
      BuiltInLinkingOverride: this.state.form.values.builtInLinkingOverride,
      BuiltInServerOverride: this.state.form.values.builtInServerOverride,
      IsMicrosoftSsoEnabled: this.state.form.values.isMicrosoftSsoEnabled,
      MicrosoftTenantOverride: this.state.form.values.microsoftTenantOverride,
    };
    this.setState({ loading: true }, async () => {
      let response = await this.context.insertOrUpdateLibraryConfig({ LibraryConfig: config });
      let name = this.state.form.values.brandKey.toString().toUpperCase() as string;
      if (response.valid()) {
        Messages.Notify.success(`The LibraryConfig for  ${name} was updated successfully!`);
        this.props.publisherChangedId(this.state.form.values.publisherId as number);
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
      this.setState({ loading: false });
    });
  };

  render() {
    if (
      this.context.login === LoginType.None ||
      !(this.context.userPermissions.some((x) => x.ManagePublishers) || this.context.userPermissions.some((x) => x.ManageSystem))
    ) {
      return (
        <div className="section">
          <h1>You cannot view this page</h1>
        </div>
      );
    }
    let form = this.state.form;
    let selectedPublisher = this.props.selectedPublisher;
    let lastModifiedControl: JSX.Element;
    if (this.state.form.values.lastModified === null) {
      lastModifiedControl = <Input disabled value={"Never"} type="text" name="lastModified" id="lastModified" />;
    } else {
      lastModifiedControl = (
        <Input
          disabled={true}
          type="text"
          name="lastModified"
          id="lastModified"
          value={Convert.formatDateForFormWithTime(new Date(this.state.form.values.lastModified as Date))}
        />
      );
    }

    return (
      <Loading isLoading={this.state.loading} status="Saving configuration..." className="formContainer">
        <Form className="p-2" onSubmit={this.submit}>
          {this.context.canManageSystem() && (
            <Row>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.info />} /> &nbsp; Version
                    </InputGroupText>
                    <Input
                      type="text"
                      name="version"
                      disabled={!this.context.canManageSystem()}
                      value={form.values.version}
                      invalid={form.invalid("version")}
                      placeholder="Version"
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          {this.context.canManageSystem() && (
            <Row>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.card />} /> &nbsp; Authorization Id
                    </InputGroupText>
                    <Input
                      type="select"
                      name="authorizationId"
                      value={form.values.authorizationId}
                      placeholder={"Authorization Id"}
                      onChange={this.handleInput}
                    >
                      {Object.keys(Models.AuthProviderType)
                        .filter((key) => isNaN(Number(Models.AuthProviderType[key as keyof typeof Models.AuthProviderType])))
                        .map((it) => (
                          <option value={it} key={it} data-providerval={it}>
                            {Models.AuthProviderType[it as keyof typeof Models.AuthProviderType]}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.card />} /> &nbsp; Authorization Method
                    </InputGroupText>

                    <Input
                      type="select"
                      name="authorizationMethod"
                      value={form.values.authorizationMethod}
                      placeholder={" Authorization Method"}
                      onChange={this.handleInput}
                    >
                      {Object.keys(Models.AuthMethod)
                        .filter((key) => isNaN(Number(Models.AuthMethod[key as keyof typeof Models.AuthMethod])))
                        .map((it) => (
                          <option value={it} key={it} data-providerval={it}>
                            {Models.AuthMethod[it as keyof typeof Models.AuthMethod]}
                          </option>
                        ))}
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          {this.context.canManageSystem() && (
            <Row>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.key5 />} /> &nbsp; Stripe Public Key
                    </InputGroupText>
                    <Input
                      type="text"
                      name="stripePublicKey"
                      disabled={!this.context.canManageSystem()}
                      value={form.values.stripePublicKey}
                      invalid={form.invalid("stripePublicKey")}
                      placeholder="Stripe Public Key"
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.key5 />} /> &nbsp; Stripe Public Key Test
                    </InputGroupText>

                    <Input
                      type="text"
                      name="stripePublicKeyTest"
                      disabled={!this.context.canManageSystem()}
                      value={form.values.stripePublicKeyTest}
                      invalid={form.invalid("stripePublicKeyTest")}
                      placeholder="Stripe Public Key Test"
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          {this.context.canManageSystem() && (
            <Row>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.url />} /> &nbsp; Stripe Store Url
                    </InputGroupText>

                    <Input
                      type="text"
                      name="stripeStoreUrl"
                      disabled={!this.context.canManageSystem()}
                      value={form.values.stripeStoreUrl}
                      invalid={form.invalid("stripeStoreUrl") && form.values.stripeStoreUrl !== ""}
                      placeholder="Stripe Store Url"
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.url />} /> &nbsp; Stripe Store Url Test
                    </InputGroupText>

                    <Input
                      type="text"
                      name="stripeStoreUrlTest"
                      disabled={!this.context.canManageSystem()}
                      value={form.values.stripeStoreUrlTest}
                      invalid={form.invalid("stripeStoreUrlTest") && form.values.stripeStoreUrlTest !== ""}
                      placeholder="Stripe Store Url Test"
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.history />} /> &nbsp; Last Modified
                  </InputGroupText>
                  {lastModifiedControl}
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {this.context.canManageSystem() && (
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.url />} /> &nbsp; URL Prefix
                    </InputGroupText>

                    <Input
                      type="text"
                      name="urlPrefix"
                      disabled={!this.context.canManageSystem()}
                      value={form.values.urlPrefix}
                      invalid={form.invalid("urlPrefix") && form.values.urlPrefix !== ""}
                      placeholder="URL Prefix"
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            )}
            {this.context.canManageSystem() && (
              <Col>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon src={<Image.card />} /> &nbsp; ProductId Prefix
                    </InputGroupText>

                    <Input
                      type="text"
                      name="productIdPrefix"
                      disabled={!this.context.canManageSystem()}
                      value={form.values.productIdPrefix}
                      invalid={form.invalid("productIdPrefix")}
                      placeholder="ProductId Prefix"
                      onChange={this.handleInput}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.key4 />} />
                    &nbsp; Brand Key
                  </InputGroupText>

                  <Input
                    type="text"
                    name="brandKey"
                    disabled={!this.context.canManageSystem()}
                    value={form.values.brandKey}
                    invalid={form.invalid("brandKey")}
                    placeholder="Brand Key"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.file_empty />} /> &nbsp; Print Max Word Count
                  </InputGroupText>
                  <Input
                    type="number"
                    name="printMaxWordsCount"
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    value={form.values.printMaxWordsCount}
                    invalid={form.invalid("printMaxWordsCount")}
                    placeholder="Print Max Word Count"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.files_empty />} /> &nbsp; Copy Max Word Count Daily
                  </InputGroupText>
                  <Input
                    type="number"
                    name="copyMaxWordCountDaily"
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    value={form.values.copyMaxWordCountDaily}
                    invalid={form.invalid("copyMaxWordCountDaily")}
                    placeholder="Copy Max Word Count Daily"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.link />} /> &nbsp; Built-in Linking Override
                  </InputGroupText>
                  <Input
                    type="text"
                    name="builtInLinkingOverride"
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    value={form.values.builtInLinkingOverride}
                    invalid={form.invalid("builtInLinkingOverride")}
                    placeholder="Built-in Linking Override"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.link />} /> &nbsp; Built-in Server Override
                  </InputGroupText>
                  <Input
                    type="text"
                    name="builtInServerOverride"
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    value={form.values.builtInServerOverride}
                    invalid={form.invalid("builtInServerOverride")}
                    placeholder="Built-in Server Override"
                    onChange={this.handleInput}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {this.context.canManageSystem() && (
              <Col>
                <FormGroup check style={{ flex: "1" }}>
                  <Label check for="isIAPEnabled">
                    <Input
                      onChange={this.handleInput}
                      checked={form.values.isIAPEnabled}
                      disabled={!this.context.canManagePublisher(selectedPublisher)}
                      type="checkbox"
                      name="isIAPEnabled"
                      id="isIAPEnabled"
                    />
                    IAP Enabled
                  </Label>
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="hideRegistrationLink">
                  <Input
                    onChange={this.handleInput}
                    checked={form.values.hideRegistrationLink}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    type="checkbox"
                    name="hideRegistrationLink"
                    id="hideRegistrationLink"
                  />
                  Hide Registration Link
                </Label>
              </FormGroup>
            </Col>
            {this.context.canManageSystem() && (
              <Col>
                <FormGroup check style={{ flex: "1" }}>
                  <Label check for="isExternallyAddable">
                    <Input
                      onChange={this.handleInput}
                      checked={form.values.isExternallyAddable}
                      disabled={!this.context.canManagePublisher(selectedPublisher)}
                      type="checkbox"
                      name="isExternallyAddable"
                      id="isExternallyAddable"
                    />
                    Externally Addable
                  </Label>
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="isServerUrlOverrideable">
                  <Input
                    onChange={this.handleInput}
                    checked={form.values.isServerUrlOverrideable}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    type="checkbox"
                    name="isServerUrlOverrideable"
                    id="isServerUrlOverrideable"
                  />
                  Server Url Overrideable
                </Label>
              </FormGroup>
            </Col>
            {this.context.canManageSystem() && (
              <Col>
                <FormGroup check style={{ flex: "1" }}>
                  <Label check for="anonymousRequiresToken">
                    <Input
                      onChange={this.handleInput}
                      checked={form.values.anonymousRequiresToken}
                      disabled={!this.context.canManagePublisher(selectedPublisher)}
                      type="checkbox"
                      name="anonymousRequiresToken"
                      id="anonymousRequiresToken"
                    />
                    Anonymous Requires Token
                  </Label>
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="isRememberMeAllowed">
                  <Input
                    onChange={this.handleInput}
                    checked={form.values.isRememberMeAllowed}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    type="checkbox"
                    name="isRememberMeAllowed"
                    id="isRememberMeAllowed"
                  />
                  Remember Me Allowed
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ paddingTop: "14px", paddingBottom: "14px" }}>
            <Col>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="availableTitlesSectionDefaultsCollapsed">
                  <Input
                    onChange={this.handleInput}
                    checked={form.values.availableTitlesSectionDefaultsCollapsed}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    type="checkbox"
                    name="availableTitlesSectionDefaultsCollapsed"
                    id="availableTitlesSectionDefaultsCollapsed"
                  />
                  Available Titles Section Defaults Collapsed
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="availableTitlesSectionDisabled">
                  <Input
                    onChange={this.handleInput}
                    checked={form.values.availableTitlesSectionDisabled}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    type="checkbox"
                    name="availableTitlesSectionDisabled"
                    id="availableTitlesSectionDisabled"
                  />
                  Available Titles Section Disabled
                </Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup check style={{ flex: "1" }}>
                <Input
                  onChange={this.handleInput}
                  checked={form.values.isMicrosoftSsoEnabled}
                  disabled={!this.context.canManagePublisher(selectedPublisher)}
                  type="checkbox"
                  name="isMicrosoftSsoEnabled"
                  id="isMicrosoftSsoEnabled"
                />
                <Label check for="isMicrosoftSsoEnabled">
                  Microsoft Single Sign On (SSO) enabled
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ paddingTop: "14px", paddingBottom: "14px" }}>
            <Col>
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="isAnonymousEnabled">
                  <Input
                    onChange={this.handleInput}
                    checked={form.values.isAnonymousEnabled}
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    type="checkbox"
                    name="isAnonymousEnabled"
                    id="isAnonymousEnabled"
                  />
                  Anonymous Enabled
                </Label>
              </FormGroup>
            </Col>
            {this.context.canManageSystem() && (
              <Col>
                <FormGroup check style={{ flex: "1" }}>
                  <Label check for="isLoginEmailBased">
                    <Input
                      onChange={this.handleInput}
                      checked={form.values.isLoginEmailBased}
                      disabled={!this.context.canManagePublisher(selectedPublisher)}
                      type="checkbox"
                      name="isLoginEmailBased"
                      id="isLoginEmailBased"
                    />
                    Login Email Based
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <FormGroup>
              <InputGroup>
                <InputGroupText>
                  <Icon src={<Image.link />} /> &nbsp; Microsoft Tenant Override
                </InputGroupText>
                <Input
                  type="text"
                  name="microsoftTenantOverride"
                  disabled={!this.context.canManagePublisher(selectedPublisher) || form.values.isMicrosoftSsoEnabled === false}
                  value={form.values.microsoftTenantOverride}
                  invalid={form.invalid("microsoftTenantOverride")}
                  placeholder="Microsoft Tenant Override"
                  onChange={this.handleInput}
                />
              </InputGroup>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <InputGroup>
                <InputGroupText>
                  <Icon src={<Image.link />} /> &nbsp; Available Title Section Name
                </InputGroupText>
                <Input
                  type="text"
                  name="availableTitleSectionName"
                  disabled={!this.context.canManagePublisher(selectedPublisher) || form.values.availableTitlesSectionDisabled === true}
                  value={form.values.availableTitleSectionName}
                  invalid={form.invalid("availableTitleSectionName")}
                  placeholder="Available Title Section Name"
                  onChange={this.handleInput}
                />
              </InputGroup>
            </FormGroup>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Theme
                  </InputGroupText>
                  <Label style={{ paddingLeft: "10px", paddingTop: "10px", flexGrow: "1" }} for="theme-input" className="input-label">
                    <i style={{ fontStyle: "italic" }}>Click to Upload (.css file)</i>
                  </Label>
                  <Input type="file" accept=".css" name="theme" id="theme-input" onChange={this.handleFile} />
                  <Button onClick={this.downloadCurrentCSS}>Download current theme file</Button>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ flex: "1" }}>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Login Logo
                  </InputGroupText>
                  <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="logo-input" className="input-label">
                    <i style={{ fontStyle: "italic" }}>Click to Upload (.png) </i>{" "}
                    <img title="loginLogo" src={`data:image/png;base64,${String(form.values.loginLogo)}`} width={50} />
                  </Label>
                  <Input
                    type="file"
                    accept=".png"
                    id="logo-input"
                    name="loginLogo"
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("loginLogo") && form.values.loginLogo !== ""}
                    placeholder="Login Logo"
                    onChange={this.onLoginLogoChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ flex: "1" }}>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Library Icon
                  </InputGroupText>
                  <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="library-input" className="input-label">
                    <i style={{ fontStyle: "italic" }}>Click to Upload (.png) </i>
                    <img title="libraryIcon" src={`data:image/png;base64,${String(form.values.libraryIcon)}`} width={50} />
                  </Label>
                  <Input
                    type="file"
                    accept=".png"
                    id="library-input"
                    name="libraryIcon"
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("libraryIcon") && form.values.libraryIcon !== ""}
                    placeholder="Library Icon"
                    onChange={this.onLibraryIconChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ flex: "1" }}>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} /> &nbsp; Default Book Splash
                  </InputGroupText>
                  <Label style={{ paddingLeft: "10px", paddingTop: "10px" }} for="file-input" className="input-label">
                    <i style={{ fontStyle: "italic" }}>Click to Upload (.png) </i>
                    <img title="defaultBookSplash" src={`data:image/png;base64,${String(form.values.defaultBookSplash)}`} width={50} />
                  </Label>
                  <Input
                    type="file"
                    accept=".png"
                    id="file-input"
                    name="defaultBookSplash"
                    disabled={!this.context.canManagePublisher(selectedPublisher)}
                    invalid={form.invalid("defaultBookSplash") && form.values.defaultBookSplash !== ""}
                    placeholder="Default Book Splash"
                    onChange={this.onSplashChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <FormGroup style={{ flex: "1", display: "flex", justifyContent: "center" }}>
              <Col xs={8}>
                <Button color="primary" outline block disabled={!form.formValid()} onClick={this.submit}>
                  Update
                </Button>
              </Col>
            </FormGroup>
          </Row>
        </Form>
      </Loading>
    );
  }
}
