import { Wire } from 'src/network/Wire';
import { ResourcePlatform } from 'src/utilities/Helpers';

/** This model includes resources and external websites management */
export class Resource {
  private wire: Wire;
  private id: number;

  public some: string;
  constructor(wire: Wire, id: number) {
    this.wire = wire;
    this.id = id;
    this.wire.addRequestTransform((request) => {
      request.bookId = this.id;
      return request;
    });
  }

  openExternalWebsite(externalWebsiteUrl: string): void {
    this.setwebResource(externalWebsiteUrl);
  }

  private setwebResource(data: string) {
    ResourcePlatform.openNewWindowInOtherDomain(data);
  }
}
