export class AsyncQueue<T> {
    private values: T[];
    private resolvers: Array<(t:T) => void>;

    constructor()
    {
        this.resolvers = [];
        this.values = [];
    }

    enqueue(item:T):Promise<T> 
    {
        return new Promise<T>(resolve => {
            this.values.push(item);
            this.resolvers.push(resolve);
            if(this.resolvers.length === 1)
            {
                this.resolveCurrent();
            }
        }); 
    }

    dequeue()
    {
        if(this.isEmpty())
        {
            return; // no-op on empty dequeue
        }

        this.resolvers.shift();
        this.values.shift();

        this.resolveCurrent();
    }

    private resolveCurrent()
    {
        if(this.isEmpty())
        {
            return; // no-op on empty current
        }
        this.resolvers[0](this.values[0]);

    }

    isEmpty()
    {
        return !this.resolvers.length && !this.values.length;
    }

    get length()
    {
        return this.resolvers.length; 
    }

}