import * as React from "react";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import Gfm from "remark-gfm";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";

interface RichContentControlProps {
  data: any;
  enabled: boolean;
  handleChange(path: string, value: any): void;
  path: string;
}
const resizeIframe = (obj: React.SyntheticEvent, prevHeight: number) => {
  setTimeout(() => {
    if ((obj.target as HTMLIFrameElement)!.contentWindow === null) {
      return;
    }
    let heightTarget = (obj.target as HTMLIFrameElement)!.contentWindow!.document.documentElement.children[1].scrollHeight * 1.3;
    if (prevHeight !== heightTarget) {
      (obj.target as HTMLIFrameElement).style.height = heightTarget + "px";
      (obj.target as HTMLIFrameElement).style.minHeight = "40pt";
      if (prevHeight === 0) {
        (obj.target as HTMLIFrameElement)!.closest(".innerCommunity")!.scrollTop = 0;
      }
      prevHeight = heightTarget;
    }
    resizeIframe(obj, prevHeight);
  }, 200);
};

const preHandleChange = (handleChange: (path: string, newObj: any) => void, path: string, content: string, fieldType: string) => {
  let newObj = { fieldType: fieldType, content: content };
  handleChange(path, newObj);
};

const RichContentControl = ({ data, enabled, handleChange, path }: RichContentControlProps) => {
  // const [text, handleChange] = React.useState<string>(data.data === undefined ? "" : data.data.content);
  // const [fieldType, handleChange] = React.useState<string>(data.data === undefined ? "md" : data.data.fieldType);
  let text = data === undefined ? "" : (data.content as string);
  let fieldType = data === undefined ? "md" : (data.fieldType as string);

  // The form element is allowing editing to occur.
  if (enabled === true) {
    let radios = (
      <RadioGroup row value={fieldType} onChange={(e) => preHandleChange(handleChange, path, text, e.target.value)} name="radio-buttons-group">
        <FormControlLabel value="md" control={<Radio />} label="Markdown" />
        <FormControlLabel value="html" control={<Radio />} label="HTML" />
      </RadioGroup>
    );
    if (fieldType === "md") {
      return (
        <div style={{ maxWidth: "initial" }}>
          {radios}
          <MDEditor
            value={text}
            onChange={(e) => preHandleChange(handleChange, path, e ? e : "", fieldType)}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
          />
        </div>
      );
    } else {
      return (
        <div>
          {radios}
          <textarea
            name="textarea"
            id="html"
            required
            placeholder="Enter basic HTML here"
            className="htmlEditor bg-white rounded shadow w-full"
            value={text}
            onChange={(e) => preHandleChange(handleChange, path, e.target.value, fieldType)}
          />
        </div>
      );
    }
  }
  // The form element is read only. "Display mode".
  else {
    if (fieldType === "md") {
      return (
        <div>
          <ReactMarkdown remarkPlugins={[Gfm]}>{data.data.content}</ReactMarkdown>
        </div>
      );
    } else {
      return (
        <div className="">
          <iframe title="formFrame" className="formFrame" srcDoc={data.data.content} frameBorder="0" scrolling="no" onLoad={(e) => resizeIframe(e, 0)} />
        </div>
      );
    }
  }
};
export default withJsonFormsControlProps(RichContentControl);
