import { IFlow } from './AppRequest';
import { IUserFormDefinition } from './DashboardModels';

export interface IFlowUserFormsRequest extends IFlow {
  TitleId: number;
  PublisherId: number;
  FormType: UserFormSubmissionType;
  StateFilter: UserFormSubmissionState;
}

export interface IGetActiveUserFormDefinition {
  FormType: UserFormSubmissionType;
  PublisherId: number;
}
export interface IGetActiveUserFormDefinitionR {
  ActiveUserFormDefinition: IUserFormDefinition;
}

export interface IGetUserForms {}
export interface IGetUserFormsR {}
export interface IDeleteUserForm {
  Id: number;
  FormType: UserFormSubmissionType;
}
export interface IDeleteUserFormR {}
export interface IChangeUserFormState {
  NewState: UserFormSubmissionState;
  UserFormSubmissionId: number;
}
export interface IChangeUserFormStateR {}
export enum UserFormSubmissionType {
  Tip,
  Bulletin,
  Feedback,
}
export enum UserFormSubmissionState {
  New,
  UnderReview,
  Published,
  Rejected,
  Completed,
}

export const UserFormSubmissionStateToString = {
  UserFormSubmissionStateToString: (state: UserFormSubmissionState) => {
    switch (state) {
      case UserFormSubmissionState.UnderReview:
        return "Under Review";
      default:
        return UserFormSubmissionState[state];
    }
  },
};
