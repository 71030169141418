import * as React from "react";
import { AppSession } from "src/models/AppSession";

import { AppContext } from "../state/Contextes";

export interface IMasterPageProps {
  children?: React.ReactNode;
}
export interface IMasterPageState {}
export class MasterPage extends React.Component<IMasterPageProps, IMasterPageState> {
  context: AppSession;
  static contextType = AppContext;
  render() {
    return <div className="masterPage-container">{this.props.children}</div>;
  }
}
