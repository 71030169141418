import * as React from "react";
import { AppSession, LoginType } from "src/models/AppSession";
import * as Models from "src/models/dto/DashboardModels";

import { Image } from "../foundation/Assets";
import { AppContext } from "../state/Contextes";

interface IPanelViewProps {
  publisherChanged: (newPub: Models.ILoginLibrary) => void;
  addPublisher?: () => void;
  publisherList: Models.ILoginLibrary[];
  showAdd: boolean;
  selectedPublisher: Models.ILoginLibrary;
  disabled: boolean;
}

interface IPanelViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  publisherList: Models.ILoginLibrary[];
}

export class PanelView extends React.Component<IPanelViewProps, IPanelViewState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IPanelViewProps | Readonly<IPanelViewProps>) {
    super(props);
    this.state = {
      publisherList: [],
      drawerShow: false,
      currentDrawerContent: null,
    };
  }

  selectPublisher = (publisherId: number) => {
    if (!this.props.disabled) {
      this.props.publisherChanged(this.props.publisherList.find((x) => x.PublisherId === publisherId)!);
    }
  };

  publisherInsertRequest = () => {
    if (!this.props.disabled && this.props.showAdd && this.props.addPublisher !== undefined) {
      this.props.addPublisher();
    }
  };

  render() {
    if (
      this.context.login === LoginType.None ||
      !(
        this.context.userPermissions.some((x) => x.ManagePublishers) ||
        this.context.userPermissions.some((x) => x.ManageSystem) ||
        this.context.userPermissions.some((x) => x.ManageTitles) ||
        this.context.userPermissions.some((x) => x.ManageProducts)
      )
      // || this.props.publisherList.length === 1
    ) {
      return "";
    }

    let publisherList = this.props.publisherList;
    let showAdd = this.props.showAdd;
    let add = (
      <div className="item" onClick={this.publisherInsertRequest}>
        <div className="addDiv">
          <Image.plus />
        </div>
        <div className="publisher-name">Add</div>
      </div>
    );
    let panelList = (
      <div className={"panel" + (this.props.disabled ? " disabled" : "")}>
        {publisherList.map((publisher) => (
          <div
            key={publisher.PublisherId}
            className={"item" + (this.props.selectedPublisher.PublisherId === publisher.PublisherId ? " selected" : "")}
            onClick={() => this.selectPublisher(publisher.PublisherId)}
          >
            <img title="libraryIcon" className="icon" src={`data:image/jpeg;base64,${publisher.LibraryIcon}`} />
            <div className="publisher-name">{publisher.BrandKey.toUpperCase()}</div>
          </div>
        ))}
        {showAdd ? add : null}
      </div>
    );

    return panelList;
  }
}
