import { IError, Status } from 'src/network/Requests';

export class ActionResult<T = any> {
  public errors: IError[];
  public status: Status;
  public data: T;
  constructor() {
    this.status = Status.Success;
    this.errors = new Array<IError>();
  }

  addError(error: IError) {
    this.errors.push(error);
  }

  valid() {
    return this.status === Status.Success && this.errors.length === 0;
  }

  promise(): Promise<ActionResult<T>> {
    return new Promise<ActionResult<T>>((resolve) => resolve(this));
  }

  static create<R = any>(): ActionResult {
    let result: ActionResult<R> = new ActionResult<R>();
    return result;
  }
}
