import * as React from "react";
import { Input } from "reactstrap";
import { genericDataSettings } from "src/models/dto/DashboardModels";

import { Image } from "../foundation/Assets";
import * as Messages from "../foundation/Messages";
import { Loading } from "./Controls";
import { ICogniflowOptionalSettings, INode, IRequest, IResponse, StandaloneCogniflowContainer } from "./StandaloneCogniflow";

export interface IDataTableProps {
  flowProvider: (req: IRequest) => Promise<IResponse>;
  initializeFlowProvider: (initialAnchor?: number | undefined) => Promise<{ nodes: any[]; targetSpine: number }>;
  objectBuilder: (node: INode, attributes?: any, key?: number | undefined) => JSX.Element;
  headers: string[];
  headerFlexes: number[];
  rowAddRequested?: () => void;
  settingsOverride?: ICogniflowOptionalSettings;
  tableClassName?: string | undefined;
  headerRowClassName?: string | undefined;
  dataClassName?: string | undefined;
  headerItemClassName?: string | undefined;
  loadingStatus?: string;

  // Search methods. If changed is set but comitted is not, will show search box with no button.
  searchQueryChanged?: (arg: React.ChangeEvent<HTMLInputElement>) => void; // Sent whenever the search query changes in the table.
  searchQueryComitted?: (currentQuery: string) => void; // Sent whenever the search query is committed.
  searchModeCancelled?: () => void;

  selectedRowDelete?: () => void;
  canDelete?: boolean;
}
export interface IDataTableState {
  loading: boolean;
}

export class DataTable extends React.PureComponent<IDataTableProps, IDataTableState> {
  flowRef = React.createRef<StandaloneCogniflowContainer>();
  constructor(props: IDataTableProps) {
    super(props);
    this.reload = this.reload.bind(this);
    this.state = { loading: false };
  }
  reload(query?: string) {
    if (this.flowRef.current) {
      this.flowRef.current.reloadCogniflow(undefined, undefined, undefined, undefined, query);
    }
  }
  reRender() {
    this.flowRef.current!.updateNodes();
  }
  render() {
    let headers: JSX.Element[] = [];
    let count = 0;
    for (let head of this.props.headers) {
      headers.push(
        <div
          title={head}
          style={{ flexGrow: this.props.headerFlexes[count] }}
          key={count++}
          className={"dataHeader" + (this.props.headerItemClassName ? " " + this.props.headerItemClassName : "")}
        >
          <span>{head}</span>
        </div>
      );
    }

    let addBtn = 0;
    let deleteBtn = 10;
    let searchBtn = [0, 0, 0];
    if (this.props.rowAddRequested && this.props.selectedRowDelete && (this.props.searchQueryComitted || this.props.searchQueryChanged)) {
      addBtn = 10;
      deleteBtn = 55;
      searchBtn = [40, 125, 100];
    } else if (this.props.rowAddRequested && this.props.selectedRowDelete && !this.props.searchQueryComitted && !this.props.searchQueryChanged) {
      addBtn = 10;
      deleteBtn = 55;
    } else if (!this.props.rowAddRequested && this.props.selectedRowDelete && (this.props.searchQueryComitted || this.props.searchQueryChanged)) {
      deleteBtn = 10;
      searchBtn = [45, 85, 60];
    } else if (!this.props.rowAddRequested && !this.props.selectedRowDelete && (this.props.searchQueryComitted || this.props.searchQueryChanged)) {
      searchBtn = [45, 85, 60];
    } else if (this.props.rowAddRequested && (this.props.searchQueryComitted || this.props.searchQueryChanged)) {
      addBtn = 10;
      searchBtn = [45, 85, 60];
    } else if (this.props.rowAddRequested && !this.props.selectedRowDelete && !(this.props.searchQueryComitted || this.props.searchQueryChanged)) {
      addBtn = 10;
    }
    let searchButton =
      this.props.searchQueryComitted || this.props.searchQueryChanged ? (
        <SearchButton
          searchModeCancelled={this.props.searchModeCancelled}
          searchQueryComitted={this.props.searchQueryComitted}
          searchQueryChanged={this.props.searchQueryChanged}
          offset={searchBtn}
        />
      ) : null;
    let addRowButton = this.props.rowAddRequested ? <AddRowButton offset={addBtn} addRowClicked={this.props.rowAddRequested} /> : null;
    let deleteRowButton = this.props.selectedRowDelete ? (
      <DeleteRowButton canDelete={this.props.canDelete} offset={deleteBtn} deleteRowClicked={this.props.selectedRowDelete} />
    ) : null;
    return (
      <div className={"dataTable" + (this.props.tableClassName ? " " + this.props.tableClassName : "")}>
        <div className={"dataHeaderRow" + (this.props.headerRowClassName ? " " + this.props.headerRowClassName : "")}>{headers}</div>
        <Loading
          isLoading={this.state.loading}
          theme="opaque"
          status={"Loading " + (this.props.loadingStatus ? this.props.loadingStatus : " data...")}
          className={"data" + (this.props.dataClassName ? " " + this.props.dataClassName : "")}
        >
          <StandaloneCogniflowContainer
            provider={this.props.flowProvider}
            builder={this.props.objectBuilder}
            initialize={this.props.initializeFlowProvider}
            extraSettings={this.props.settingsOverride ? this.props.settingsOverride : genericDataSettings}
            ref={this.flowRef}
            loadStarted={() => this.setState({ loading: true })}
            loadEnded={() => this.setState({ loading: false })}
          />
        </Loading>
        {searchButton}
        {deleteRowButton}
        {addRowButton}
      </div>
    );
  }
}
export interface IDataRowProps {
  attributes: any;
  dataItems: JSX.Element[];
  node: INode;
  rowEditRequested?: (node: INode, infoNode?: INode) => void;
  className?: string | undefined;
}
export interface IDataRowState {}
export class DataRow extends React.PureComponent<IDataRowProps, IDataRowState> {
  constructor(props: IDataRowProps) {
    super(props);
  }
  render() {
    return (
      <div
        onClick={() => {
          if (this.props.rowEditRequested) {
            this.props.rowEditRequested(this.props.node);
          }
        }}
        {...this.props.attributes}
        className={"dataRow" + (this.props.className ? " " + this.props.className : "")}
      >
        {this.props.dataItems}
      </div>
    );
  }
}
export interface IDataItemProps {
  value: string | boolean | null;
  flexVal: number;
  className?: string | undefined;
  children?: React.ReactNode;
}
export interface IDataItemState {}

export class DataItem extends React.PureComponent<IDataItemProps, IDataItemState> {
  render() {
    let dataItem: JSX.Element;
    if (this.props.children) {
      dataItem = (
        <div style={{ flexGrow: this.props.flexVal }} className={"dataItem" + (this.props.className ? " " + this.props.className : "")}>
          {this.props.children}
        </div>
      );
    } else if (typeof this.props.value !== "boolean") {
      dataItem = (
        <div
          title={this.props.value!}
          style={{ flexGrow: this.props.flexVal }}
          className={"dataItem" + (this.props.className ? " " + this.props.className : "")}
        >
          <span>{this.props.value}</span>
        </div>
      );
    } else {
      dataItem = (
        <div style={{ flexGrow: this.props.flexVal }} className={"dataItem checkBox" + (this.props.className ? " " + this.props.className : "")}>
          <Input readOnly type="checkbox" checked={this.props.value} />
        </div>
      );
    }
    return dataItem;
  }
}

export interface ISearchButtonProps {
  className?: string | undefined;
  offset: number[];
  // Search methods. If changed is set but comitted is not, will show search box with no button.
  searchQueryChanged?: (arg: React.ChangeEvent<HTMLInputElement>) => void; // Sent whenever the search query changes in the table.
  searchQueryComitted?: (currentQuery: string) => void; // Sent whenever the search query is committed.
  searchModeCancelled?: () => void;
}
export interface ISearchButtonState {
  buttonMode: ButtonMode;
  currentQuery: string;
}
enum ButtonMode {
  Closed,
  Open,
  Active,
}
export class SearchButton extends React.PureComponent<ISearchButtonProps, ISearchButtonState> {
  constructor(props: ISearchButtonProps) {
    super(props);
    this.state = { buttonMode: ButtonMode.Closed, currentQuery: "" };
  }
  searchButtonClicked = () => {
    switch (this.state.buttonMode) {
      case ButtonMode.Closed:
        this.setState({ buttonMode: ButtonMode.Open });
        break;
      case ButtonMode.Open:
      case ButtonMode.Active:
        if (this.props.searchModeCancelled) {
          this.props.searchModeCancelled();
        }
        this.setState({ buttonMode: ButtonMode.Closed });
        break;
    }
  };
  checkEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && this.props.searchQueryComitted) {
      e.preventDefault();
      this.props.searchQueryComitted((e.target as HTMLInputElement).value);
      this.setState({ buttonMode: ButtonMode.Active });
    }
  };
  cancelSearchButtonClicked = () => {
    if (this.props.searchQueryComitted) {
      this.props.searchQueryComitted("");
    }
    if (this.props.searchModeCancelled) {
      this.props.searchModeCancelled();
    }
    this.setState({ buttonMode: ButtonMode.Closed, currentQuery: "" });
  };
  queryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentQuery: e.target.value });
    if (this.props.searchQueryChanged) {
      this.props.searchQueryChanged(e);
    }
  };
  render() {
    return (
      <div className={"searchButtonContainer" + (this.props.className ? " " + this.props.className : "")}>
        <div
          style={{ right: this.props.offset[0] + "px" }}
          className={"searchBarContainer " + ((this.state.buttonMode === ButtonMode.Open || this.state.buttonMode === ButtonMode.Active) && "visible")}
        >
          <div>
            <Input
              value={this.state.currentQuery}
              placeholder="Search"
              onKeyUp={this.checkEnter}
              onChange={this.queryChanged}
              className={"searchBar"}
              type={"text"}
              style={{ right: this.props.offset[1] + "px" }}
            />
          </div>
          <div style={{ right: this.props.offset[1] - 20 + "px" }} className={"searchButton cancellable"} onClick={this.cancelSearchButtonClicked}>
            {<Image.close />}
          </div>
        </div>
        <div>
          <div style={{ right: this.props.offset[2] + "px" }} className={"searchButton"} onClick={this.searchButtonClicked}>
            {this.state.buttonMode === ButtonMode.Active || this.state.buttonMode === ButtonMode.Open ? <Image.cancel_search /> : <Image.search />}
          </div>
        </div>
      </div>
    );
  }
}

export interface IAddRowButtonProps {
  addRowClicked: () => void;
  offset: number;
  className?: string | undefined;
}
export interface IAddRowButtonState {}

export class AddRowButton extends React.PureComponent<IAddRowButtonProps, IAddRowButtonState> {
  render() {
    return (
      <div
        onClick={this.props.addRowClicked}
        style={{ right: this.props.offset + "px" }}
        className={"addRowButton" + (this.props.className ? " " + this.props.className : "")}
      >
        <Image.plus />
      </div>
    );
  }
}

export interface IDeleteRowButtonProps {
  deleteRowClicked: () => void;
  canDelete?: boolean;
  offset: number;
  className?: string | undefined;
}
export interface IDeleteRowButtonState {}

export class DeleteRowButton extends React.PureComponent<IDeleteRowButtonProps, IDeleteRowButtonState> {
  delete = () => {
    if (this.props.canDelete === true) {
      this.props.deleteRowClicked();
    } else {
      Messages.Notify.error("No row selected");
    }
  };
  render() {
    return (
      <div
        onClick={this.delete}
        style={{ right: this.props.offset + "px" }}
        className={"deleteRowButton" + (this.props.className ? " " + this.props.className : "") + (this.props.canDelete !== true ? " disabled" : "")}
      >
        <Image.delete_item />
      </div>
    );
  }
}
