import classnames from "classnames";
import * as React from "react";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, DropdownItem, DropdownMenu, Spinner, Table } from "reactstrap";
import { FormState } from "src/utilities/FormState";
import _ from "underscore";

import { IEmptyValue } from "../state/Generics";
import { Image } from "./Assets";

export interface IHtmlElementProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  title?: string;
}
export interface IIconProps extends IHtmlElementProps {
  src: React.ReactNode;
}
export class Icon extends React.Component<IIconProps, unknown> {
  render() {
    return (
      <span id={this.props.id} className={classnames("icon", this.props.className)} title={this.props.title} style={this.props.style}>
        {this.props.src}
      </span>
    );
  }
}

export interface IActionIconProps extends IIconProps {
  onClick: (event: any) => any;
  enabled?: boolean;
  active?: boolean;
}

export class ActionIcon extends React.Component<IActionIconProps, unknown> {
  static defaultProps = {
    enabled: true,
  };
  constructor(props: IActionIconProps | Readonly<IActionIconProps>) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(event: any) {
    if (this.isEnabled()) {
      this.props.onClick(event);
    }
  }

  isEnabled(): boolean {
    return this.props.enabled !== undefined ? this.props.enabled : true;
  }

  isActive(): boolean {
    return this.props.active !== undefined ? this.props.active : false;
  }

  render() {
    return (
      <a
        id={this.props.id}
        className={classnames("icon", "action-icon", this.props.className, {
          active: this.isActive(),
          disabled: !this.isEnabled(),
        })}
        onClick={this.clickHandler}
      >
        {this.props.src}
      </a>
    );
  }
}

export class CircleActionIcon extends React.Component<IActionIconProps, unknown> {
  render() {
    return (
      <Button className="btn-round" color="primary" outline onClick={this.props.onClick}>
        <Icon {...this.props} />
      </Button>
    );
  }
}

export class FabButton extends React.Component<IActionIconProps, unknown> {
  render() {
    return (
      <Button className="position-absolute btn-fab" color="primary" onClick={this.props.onClick}>
        <Icon className="w-100 h-100" {...this.props} />
      </Button>
    );
  }
}

interface IVerticalNavBarProps {
  level: string;
  className?: string;
  children?: React.ReactNode;
}

export class VerticalNavBar extends React.Component<IVerticalNavBarProps, IEmptyValue> {
  render() {
    return (
      <div
        className={classnames(`vertical-navbar justify-content-between ${this.props.level}-navbar`, this.props.className)}
        style={
          {
            // backgroundColor: this.props.color,
          }
        }
      >
        {this.props.children}
      </div>
    );
  }
}

export interface IItemCardProps extends IHtmlElementProps {
  children: {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    description: React.ReactNode;
  };
  onClick: () => any;
  showMore: boolean | undefined;
  onShowMoreClick: () => any | undefined;
}
export class ItemCard extends React.Component<IItemCardProps> {
  render() {
    let desc: any = "";
    if (typeof this.props.children.description === "object") {
      desc = this.props.children.description;
    } else if (this.props.children.description) {
      desc = <CardText>{this.props.children.description}</CardText>;
    }
    return (
      <Card className={classnames("m-1 bg-light", this.props.className)} style={this.props.style} id={this.props.id}>
        <CardBody className="p-0 d-flex">
          <div className="p-3 flex-fill clickable" tabIndex={0} onClick={this.props.onClick}>
            <CardTitle>{this.props.children.title ? this.props.children.title : "\u00A0"}</CardTitle>
            <CardSubtitle>{this.props.children.subtitle ? this.props.children.subtitle : ""}</CardSubtitle>
            {desc}
          </div>
          {this.props.showMore && (
            <div
              className="p-3 border-left d-flex align-items-center justify-content-middle flex-shrink-0 clickable"
              tabIndex={0}
              onClick={this.props.onShowMoreClick}
            >
              <Icon src={<Image.showmore />} />
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

export const FormValue = (props: any) => <div className="form-text-value">{props.children}</div>;

export interface ISortDropdownMenuProps extends IHtmlElementProps {
  initial: any;
  initialReversed?: boolean;
  onSortSelected: (value: any, reversed: boolean) => any;
  children?: React.ReactNode;
}

export interface ISortDropdownMenuState {}

export class SortDropdownMenu extends React.Component<ISortDropdownMenuProps, ISortDropdownMenuState> {
  constructor(props: ISortDropdownMenuProps | Readonly<ISortDropdownMenuProps>) {
    super(props);
    this.state = {};
    this.onItemClicked = this.onItemClicked.bind(this);
  }

  onItemClicked(value: any, reversed: boolean) {
    if (this.props.initial === value) {
      reversed = !reversed;
    } else {
      reversed = false;
    }
    this.props.onSortSelected(value, reversed);

    this.setState({});
  }

  render() {
    return (
      <DropdownMenu id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        {React.Children.map(this.props.children as React.ReactElement, (child: React.ReactElement<ISortDropdownItemProps>) => {
          let clone = React.cloneElement(child, {
            key: child.props.value,
            reversed: this.props.initialReversed ? this.props.initialReversed : false,
            onClick: this.onItemClicked,
            active: child.props.value === this.props.initial,
          });
          return clone;
        })}
      </DropdownMenu>
    );
  }
}

export interface ISortDropdownItemProps extends IHtmlElementProps {
  value: any;
  reversed?: boolean;
  onClick?: (value: any, reversed: boolean) => any;
  active?: boolean;
  children?: React.ReactNode;
}

export class SortDropdownItem extends React.Component<ISortDropdownItemProps, unknown> {
  constructor(props: ISortDropdownItemProps | Readonly<ISortDropdownItemProps>) {
    super(props);
    this.onDropdownItemClick = this.onDropdownItemClick.bind(this);
  }

  onDropdownItemClick() {
    const reversed = this.props.reversed || false;
    if (this.props.onClick) {
      this.props.onClick(this.props.value, reversed);
    }
  }

  render() {
    const active = this.props.active || false;
    let icon: React.ReactNode = null;
    if (this.props.active) {
      if (this.props.reversed) {
        icon = <Image.sortdown />;
      } else {
        icon = <Image.sortup />;
      }
    }

    return (
      <DropdownItem active={active} onClick={this.onDropdownItemClick} id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        <div className="d-flex justify-content-between">
          <div>{this.props.children}</div>{" "}
          <div className="ml-2">
            <Icon src={icon} />
          </div>
        </div>
      </DropdownItem>
    );
  }
}

export interface ISelectDropdownMenuProps extends IHtmlElementProps {
  initial: any;
  onSelected: (value: any) => any;
  children?: React.ReactNode;
}

export interface ISelectDropdownMenuState {
  current: any;
}

export class SelectDropdownMenu extends React.Component<ISelectDropdownMenuProps, ISelectDropdownMenuState> {
  constructor(props: ISelectDropdownMenuProps | Readonly<ISelectDropdownMenuProps>) {
    super(props);
    this.state = {
      current: this.props.initial,
    };
    this.onItemClicked = this.onItemClicked.bind(this);
  }

  componentDidUpdate(prevProps: ISelectDropdownMenuProps) {
    if (prevProps.initial !== this.state.current) {
      this.setState({
        current: this.props.initial,
      });
    }
  }

  onItemClicked(value: any) {
    this.props.onSelected(value);

    this.setState({
      current: value,
    });
  }

  render() {
    return (
      <DropdownMenu id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        {React.Children.map(this.props.children as React.ReactElement, (child: React.ReactElement<ISelectDropdownItemProps>) => {
          let clone = React.cloneElement(child, {
            key: child.props.value,
            onClick: this.onItemClicked,
            active: child.props.value === this.state.current,
          });
          return clone;
        })}
      </DropdownMenu>
    );
  }
}

export interface ISelectDropdownItemProps extends IHtmlElementProps {
  value: any;
  onClick?: (value: any) => any;
  active?: boolean;
  children?: React.ReactNode;
}

export class SelectDropdownItem extends React.Component<ISelectDropdownItemProps, unknown> {
  constructor(props: ISelectDropdownItemProps | Readonly<ISelectDropdownItemProps>) {
    super(props);
    this.onDropdownItemClick = this.onDropdownItemClick.bind(this);
  }

  onDropdownItemClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  }

  render() {
    const active = this.props.active || false;
    let icon: React.ReactNode = null;
    if (this.props.active) {
      icon = <Image.radio_on />;
    } else {
      icon = <Image.radio_off />;
    }
    return (
      <DropdownItem active={active} onClick={this.onDropdownItemClick} id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        <div className="d-flex justify-content-start">
          <div className="mr-2">
            <Icon src={icon} />
          </div>
          <div>{this.props.children}</div>{" "}
        </div>
      </DropdownItem>
    );
  }
}

export interface IExpanderProps {
  isOpen: boolean;
  [key: string]: any;
  className: string;
}
export class Expander extends React.Component<IExpanderProps, unknown> {
  render() {
    const openClass = this.props.isOpen ? "expander-open" : "";
    return (
      <div className="expander">
        <div className={`expander-collider ${openClass}`}>
          <div className={classnames("expander-body", this.props.className)} {..._.omit(this.props, "className", "isOpen")}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export interface IExpanderCloseProps {
  onClick: () => any;
  isOpen: boolean;
}
export interface IExpanderCloseState {
  isOpen: boolean;
}
export class ExpanderClose extends React.Component<IExpanderCloseProps, IExpanderCloseState> {
  constructor(props: IExpanderCloseProps) {
    super(props);
    this.state = { isOpen: props.isOpen };
    this.onCLickExecutor = this.onCLickExecutor.bind(this);
  }

  onCLickExecutor() {
    this.props.onClick();
    this.setState({ isOpen: !this.state.isOpen });
  }
  componentDidUpdate() {
    if (this.props.isOpen !== this.state.isOpen) {
      // Props override state
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  render() {
    return (
      <Button close aria-label="Collapse" onClick={this.onCLickExecutor} className="p-3">
        <span aria-hidden>{this.state.isOpen ? "–" : "+"}</span>
      </Button>
    );
  }
}

interface IFilteringMenuProps extends IHtmlElementProps {
  onFilterChanged: (filter: { [key: string]: any }) => any;
  children?: React.ReactNode;
}

interface IFilteringMenuState {
  form: FormState;
}

interface IFilteringItemProps extends IHtmlElementProps {
  value: string | number;
  children?: React.ReactNode;
}

export class FilteringMenu extends React.Component<IFilteringMenuProps, IFilteringMenuState> {
  constructor(props: IFilteringMenuProps | Readonly<IFilteringMenuProps>) {
    super(props);
    this.onOptionChanged = this.onOptionChanged.bind(this);
    this.state = { form: new FormState({}) };
  }

  componentDidUpdate() {
    const previous = _.clone(this.state.form.values);
    React.Children.map(this.props.children as React.ReactElement, (child: React.ReactElement<IFilteringItemProps>) => {
      let filterKey = child.props.value;
      this.state.form.update({
        [filterKey]: this.state.form.values[filterKey] === undefined ? true : this.state.form.values[filterKey],
      });
    });

    if (!_.isEqual(previous, this.state.form.values)) {
      this.setState({
        form: this.state.form,
      });
      this.props.onFilterChanged(this.state.form.values);
    }
  }

  onOptionChanged(input: HTMLInputElement) {
    if (input) {
      this.setState({
        form: this.state.form.change(input),
      });
      this.props.onFilterChanged(this.state.form.values);
    }
  }

  render() {
    let content = (
      <Table id={this.props.id} className={classnames("mb-0", this.props.className)} style={this.props.style}>
        <tbody>
          {React.Children.map(this.props.children as React.ReactElement, (child: React.ReactElement<IFilteringItemProps>) => (
            <FilteringItemWrapper
              checkState={this.state.form.values[child.props.value] === true ? CheckboxState.checked : CheckboxState.unchecked}
              element={child}
              onOptionChanged={this.onOptionChanged}
            />
          ))}
        </tbody>
      </Table>
    );
    return <div className="scrollingFilterContainer">{content}</div>;
  }
}
interface IFilteringItemWrapperProps {
  onOptionChanged: (event: HTMLInputElement) => void;
  element: JSX.Element;
  checkState: CheckboxState;
}
class FilteringItemWrapper extends React.Component<IFilteringItemWrapperProps, unknown> {
  rowRef = React.createRef<HTMLTableRowElement>();
  constructor(props: IFilteringItemWrapperProps) {
    super(props);
    this.innerOptionChanged = this.innerOptionChanged.bind(this);
    this.innerWrapperClick = this.innerWrapperClick.bind(this);
  }
  innerOptionChanged(ev: React.MouseEvent) {
    let input = this.rowRef.current!.querySelector("input");
    if (input) {
      ev.stopPropagation();
      this.props.onOptionChanged(input);
    }
  }
  innerWrapperClick(ev: React.MouseEvent) {
    let input = this.rowRef.current!.querySelector("input");
    if (input) {
      ev.stopPropagation();
      input.checked = !input.checked;
      this.props.onOptionChanged(input);
    }
  }
  render() {
    return (
      <tr ref={this.rowRef}>
        <td>
          <Checkbox name={this.props.element.props.value.toString()} state={this.props.checkState} onChange={this.innerOptionChanged} />
        </td>
        <td onClick={this.innerWrapperClick} style={{ width: "100%" }}>
          {this.props.element}
        </td>
      </tr>
    );
  }
}

export class FilteringItem extends React.Component<IFilteringItemProps, unknown> {
  render() {
    return (
      <div id={this.props.id} className={classnames(this.props.className)} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export enum CheckboxState {
  checked,
  unchecked,
  indeterminate,
}
export interface ICheckboxProps extends IHtmlElementProps {
  state: CheckboxState;
  onChange: (event: any) => any;
  name?: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  labelBefore?: boolean;
}

export interface ICheckboxState {
  state: CheckboxState;
}

export class Checkbox extends React.Component<ICheckboxProps, ICheckboxState> {
  private inputRef = React.createRef<HTMLInputElement>();
  constructor(props: ICheckboxProps | Readonly<ICheckboxProps>) {
    super(props);
    this.state = { state: CheckboxState.unchecked };
  }

  componentDidMount() {
    this.setCheckboxState();
  }

  componentDidUpdate(prevProps: ICheckboxProps) {
    if (prevProps.state !== this.props.state) {
      this.setCheckboxState();
    }
  }

  private setCheckboxState() {
    this.setState({
      state: this.props.state,
    });
    if (this.inputRef.current) {
      this.inputRef.current.indeterminate = this.props.state === CheckboxState.indeterminate;
    }
  }

  render() {
    return (
      <label className={classnames("custom-checkbox", this.props.className)} id={this.props.id} style={this.props.style}>
        {this.props.labelBefore && this.props.label !== undefined && <label>{this.props.label}</label>}
        <input
          ref={this.inputRef}
          type="checkbox"
          checked={this.state.state === CheckboxState.checked}
          onChange={this.props.onChange}
          name={this.props.name}
          disabled={this.props.disabled}
        />
        <span className="custom-checkbox-state" tabIndex={0} />
        {!this.props.labelBefore && this.props.label !== undefined && <label>{this.props.label}</label>}
      </label>
    );
  }
}

export interface ISwitchProps extends IHtmlElementProps {
  on: boolean;
  onChange: (event: any) => any;
  name?: string;
  disabled?: boolean;
}

export class Switch extends React.Component<ISwitchProps, unknown> {
  render() {
    return (
      <Checkbox
        onChange={this.props.onChange}
        name={this.props.name}
        disabled={this.props.disabled}
        className={classnames("switch", this.props.className)}
        style={this.props.style}
        id={this.props.id}
        state={this.props.on ? CheckboxState.checked : CheckboxState.unchecked}
      />
    );
  }
}

export interface ILoadingProps extends IHtmlElementProps {
  isLoading: boolean;
  status?: string | React.ReactNode;
  theme?: "opaque" | "translucent";
  children?: React.ReactNode;
}
export class Loading extends React.Component<ILoadingProps, unknown> {
  render() {
    return (
      <div className={classnames("loading-modal-context", this.props.className)} id={this.props.id} style={this.props.style}>
        {this.props.isLoading && (
          <div
            className={classnames("loading-modal", {
              "loading-modal-opaque": this.props.theme === "opaque",
            })}
          >
            <div className="loading-modal-content">
              {this.props.theme === "opaque" && (
                <Spinner
                  color="dark"
                  className="loading-modal-spinner"
                  style={{
                    opacity: 0.6,
                  }}
                />
              )}
              {this.props.theme !== "opaque" && <Spinner color="light" className="loading-modal-spinner" />}
              {this.props.status}
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

interface IStaticModalProps extends IHtmlElementProps {
  backdrop?: boolean;
  centered?: boolean;
  visible: boolean;
  children?: React.ReactNode;
}
export class StaticModal extends React.Component<IStaticModalProps, unknown> {
  render() {
    let visibility = this.props.visible ? "" : " modalHide";

    return (
      <div className={"static-modal-container" + visibility}>
        <div className={classnames("modal", this.props.className)} tabIndex={-1} role="dialog" id={this.props.id} style={this.props.style}>
          <div
            className={classnames("modal-dialog", {
              "modal-dialog-centered": this.props.centered,
            })}
            role="document"
          >
            <div className="modal-content">{this.props.children}</div>
          </div>
        </div>
        {this.props.backdrop === true && <div className="modal-backdrop static-modal-backdrop" />}
      </div>
    );
  }
}

interface IDrawerContainerProps extends IHtmlElementProps {
  direction?: "left" | "top";
  children?: React.ReactNode;
}

interface IDrawerContainerState {}
export class DrawerContainer extends React.Component<IDrawerContainerProps, IDrawerContainerState> {
  constructor(props: IDrawerContainerProps | Readonly<IDrawerContainerProps>) {
    super(props);
  }

  render() {
    return (
      <div
        className={classnames("drawer-container", this.props.className, {
          "drawer-container-to-top": this.props.direction === "top",
        })}
      >
        {this.props.children}
      </div>
    );
  }
}

interface IDrawerProps extends IHtmlElementProps {
  isOpen: boolean;
  backdrop?: boolean;
  onBackdropClicked?: () => any;
  children?: React.ReactNode;
}

interface IDrawerState extends IHtmlElementProps {}

export class Drawer extends React.Component<IDrawerProps, IDrawerState> {
  constructor(props: IDrawerProps | Readonly<IDrawerProps>) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={classnames("drawer", this.props.className, {
            "drawer-show": this.props.isOpen,
          })}
        >
          <div className="drawer-content">{this.props.children}</div>
        </div>
        {!(this.props.backdrop === false) && <div className="drawer-backdrop" onClick={this.props.onBackdropClicked} />}
      </React.Fragment>
    );
  }
}

interface ISeparatorProps {
  className?: string;
}

export class Separator extends React.Component<ISeparatorProps, unknown> {
  constructor(props: ISeparatorProps | Readonly<ISeparatorProps>) {
    super(props);
  }

  render() {
    return <div className={classnames("w-100 my-2 separator", this.props.className)} />;
  }
}

interface IAnnotationTypeProps {
  color: string;
  name: string;
  tokenClassname?: string;
}

export class AnnotationTypeComponent extends React.PureComponent<IAnnotationTypeProps, unknown> {
  render() {
    let classes = "colour-token";
    if (this.props.tokenClassname) {
      classes += " " + this.props.tokenClassname;
    }
    return (
      <div className="annotation-type d-flex align-items-center">
        <div className={classes} style={{ backgroundColor: this.props.color }} />
        <span>{this.props.name}</span>
      </div>
    );
  }
}
