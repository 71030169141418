import * as React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";

export interface IOpenIddictApplicationFormProps {
  initialNode: Models.IOpenIddictApplication;
  deleteRequested?: (node: Models.IOpenIddictApplication) => void;
  saveRequested?: (node: Models.IOpenIddictApplication) => void;
}
export interface IOpenIddictApplicationFormState {
  editingNode: Models.IOpenIddictApplication;
}
export class OpenIddictApplicationForm extends React.Component<IOpenIddictApplicationFormProps, IOpenIddictApplicationFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IOpenIddictApplicationFormProps) {
    super(props);
    this.state = { editingNode: this.props.initialNode };
  }
  componentDidMount() {}

  idChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Id: e.target.value } }));
  };
  displayNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, DisplayName: e.target.value } }));
  };

  clientIdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, ClientId: e.target.value } }));
  };

  permissionsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Permissions: e.target.value } }));
  };

  expirationDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.valueAsDate === null) {
      return;
    }
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, ExpirationDate: new Date(e.target.valueAsDate!) } }));
  };

  publisherTableIdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, PublisherId: +e.target.value } }));
  };

  publisherChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (+e.target.value === -1) {
      this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, PublisherId: 0 } }));
    }
    this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, PublisherId: +e.target.value === -1 ? 0 : +e.target.value } }));
  };

  formValid() {
    return (
      this.state.editingNode.Id.length > 0 &&
      this.state.editingNode.DisplayName.length > 0 &&
      this.state.editingNode.ClientId.length > 0 &&
      this.state.editingNode.Permissions.length > 0 &&
      this.state.editingNode.PublisherId > 0
    );
  }
  render() {
    return (
      <div className="formContainer">
        <h2>OpenIddict Applications Management</h2>
        {this.props.deleteRequested ? (
          <p>You have selected a OpenIddict Application item to consult or edit.</p>
        ) : (
          <p>You have chosen to create a new OpenIddict Application item. Enter the required properties below and click &quot;save&quot;</p>
        )}
        <Form>
          <Col>
            <Row>
              <FormGroup className="formGroup" style={{ flex: "1" }}>
                <Label for="tableId">Table Id</Label>
                <Input onChange={this.idChanged} value={this.state.editingNode.Id} disabled={true} type="text" name="Id" id="Id" />
              </FormGroup>
              <FormGroup className="formGroup" style={{ flex: "1" }}>
                <Label for="displayName">Display Name</Label>
                <Input
                  onChange={this.displayNameChanged}
                  value={this.state.editingNode.DisplayName}
                  disabled={this.props.deleteRequested !== undefined}
                  type="text"
                  name="displayName"
                  id="displayName"
                />
              </FormGroup>
              <FormGroup className="formGroup" style={{ flex: "1" }}>
                <Label for="clientId">Client Id</Label>
                <Input
                  onChange={this.clientIdChanged}
                  value={this.state.editingNode.ClientId}
                  disabled={this.props.deleteRequested !== undefined}
                  type="text"
                  name="clientId"
                  id="clientId"
                />
              </FormGroup>
              <FormGroup className="formGroup" style={{ flex: "1" }}>
                <Label for="expirationDate">Expiration Date</Label>
                <Input
                  onChange={this.expirationDateChanged}
                  value={Convert.formatDateForForm(new Date(this.state.editingNode.ExpirationDate))}
                  disabled={this.props.deleteRequested !== undefined}
                  type="date"
                  name="expirationDate"
                  id="expirationDate"
                />
              </FormGroup>
              <FormGroup className="formGroup" style={{ flex: "1" }}>
                <Label for="brandKey">Publisher Id</Label>
                <Input
                  type="select"
                  name="brandKey"
                  value={this.state.editingNode.PublisherId === null ? undefined : this.state.editingNode.PublisherId}
                  disabled={this.props.deleteRequested !== undefined}
                  onChange={this.publisherChanged}
                >
                  <option value={-1}>Select</option>
                  {this.context.loginLibraries
                    .rows()
                    .sort((a: any, b: any) => (a.DisplayName > b.DisplayName ? 1 : -1))
                    .map((lib: any) => (
                      <option value={lib.PublisherId} key={lib.PublisherId}>
                        {lib.DisplayName}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Row>
          </Col>
          <Col>
            <Row className="formButtons">
              {this.props.deleteRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>
                  Delete
                </Button>
              )}
              {this.props.saveRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                  Save
                </Button>
              )}
            </Row>
          </Col>
        </Form>
      </div>
    );
  }
}
