import * as React from 'react';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';

import { Image } from '../../foundation/Assets';
import { Icon } from '../../foundation/Controls';
import * as Messages from '../../foundation/Messages';
import { AppContext } from '../../state/Contextes';

interface IResetFormProps {
  returnToLogin: () => void;
  publisherId: number;
}
interface IResetFormState {
  email: string;
}

export class ResetForm extends React.Component<IResetFormProps, IResetFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IResetFormProps) {
    super(props);
    this.state = { email: "" };
  }
  validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  handleEmailChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: arg.target.value });
  };
  requestReset = async () => {
    let response = await this.context.resetPassword({ PublisherId: this.props.publisherId, UserEmail: this.state.email });
    if (response.valid()) {
      Messages.Notify.success("Your request has been sent to the associated email, if you have an account with us. Please check your inbox.");
      this.props.returnToLogin();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Confirmation failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  render() {
    return (
      <div className="resetView">
        <p>To reset your password, please enter the email you use to log in below. An email with instructions will be sent to that account.</p>
        <Form className="p-2">
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input
                type="email"
                name="email"
                value={this.state.email}
                invalid={this.state.email.length > 0 && !this.validateEmail(this.state.email)}
                placeholder={"Account email"}
                onChange={this.handleEmailChanged}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Button onClick={this.requestReset} color="primary" outline block disabled={this.state.email.length > 0 && !this.validateEmail(this.state.email)}>
              Request password reset
            </Button>
            <Button onClick={this.props.returnToLogin} color="primary" outline block>
              Cancel
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
