import * as React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { AppContext } from "src/ui/state/Contextes";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";

export interface IEmailTemplateFormProps {
  initialNode: Models.IEmailTemplateViewModel;
  deleteRequested?: (node: Models.IEmailTemplateViewModel) => void;
  saveRequested?: (node: Models.IEmailTemplateViewModel) => void;
}
export interface IEmailTemplateFormState {
  editingNode: Models.IEmailTemplateViewModel;
  mandatoryVariables: string[];
}
export class EmailTemplateForm extends React.Component<IEmailTemplateFormProps, IEmailTemplateFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IEmailTemplateFormProps) {
    super(props);
    this.state = { editingNode: this.props.initialNode, mandatoryVariables: [] };
  }
  formValid = () => true;
  friendlyTypeName = (value: Models.EmailTemplateType) => {
    switch (value) {
      case Models.EmailTemplateType.Undefined:
        return "Email Template Type";
      case Models.EmailTemplateType.PermissionInvite:
        return "Permission invitation";
      case Models.EmailTemplateType.LicenceInvite:
        return "Licence invitation";
      case Models.EmailTemplateType.AccountConfirm:
        return "Account confirmation";
      case Models.EmailTemplateType.PasswordReset:
        return "Password reset";
      case Models.EmailTemplateType.SystemMessage:
        return "System Message";
      case Models.EmailTemplateType.LogSubmissionResponse:
        return "Log submission response";
      default:
        return "Undefined";
    }
  };
  mandatoryVariables = (value: Models.EmailTemplateType): JSX.Element | null => {
    switch (value) {
      case Models.EmailTemplateType.Undefined:
      case Models.EmailTemplateType.PermissionInvite:
      case Models.EmailTemplateType.LicenceInvite:
      case Models.EmailTemplateType.AccountConfirm:
      case Models.EmailTemplateType.PasswordReset:
      default:
        return (
          <div>
            <span>This template requires the following variables exist in the email content:</span>
            <ul>
              <li>{"${EMAIL_LINK}"}</li>
            </ul>
          </div>
        );

      case Models.EmailTemplateType.SystemMessage:
        return (
          <div>
            <span>This template requires the following variables exist in the email content and subject:</span>
            <ul>
              <li>{"${SYSTEM_MESSAGE}"}</li>
            </ul>
          </div>
        );
      case Models.EmailTemplateType.LogSubmissionResponse:
        return (
          <div>
            <span>This template requires no variables exist in the email content and subject.</span>
          </div>
        );
    }
  };
  handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, EmailTemplate: { ...prevState.editingNode.EmailTemplate, TemplateName: e.target.value } },
    }));
  };
  handleTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let mando = ["${EMAIL_LINK}"];
    if (+e.target.value === Models.EmailTemplateType.SystemMessage) {
      mando = ["${SYSTEM_MESSAGE}"];
    }
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, EmailTemplate: { ...prevState.editingNode.EmailTemplate, TemplateType: +e.target.value } },
      mandatoryVariables: mando,
    }));
  };
  handlePublisherChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, EmailTemplate: { ...prevState.editingNode.EmailTemplate, PublisherId: +e.target.value } },
    }));
  };
  handleSubjectChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, EmailTemplate: { ...prevState.editingNode.EmailTemplate, Subject: e.target.value } },
    }));
  };
  handleFileChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let txt = await e.target.files!.item(0)!.text();
    let notFound = "";
    this.state.mandatoryVariables.forEach((variable) => {
      if (!txt.includes(variable)) {
        notFound += " " + variable;
      }
    });
    if (notFound.length === 0) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, EmailTemplate: { ...prevState.editingNode.EmailTemplate, EmailContents: txt } },
      }));
    } else {
      Messages.Notify.error("Mandatory variables were missing in the email contents. They were: " + notFound);
      e.target.value = "";
    }
  };
  downloadEmail = () => {
    let element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(this.state.editingNode.EmailTemplate.EmailContents));
    element.setAttribute("download", "emailTemplate.html");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  testEmail = async () => {
    let recipients = "";
    await Messages.Dialog.confirm(
      <div>
        <span>Please input the emails (comma separated) to send the test email to.</span>
        <label>Recipients</label>
        <input
          title="Recipients"
          type="text"
          style={{ width: "100%" }}
          onChange={(e) => {
            recipients = e.target.value;
          }}
        />
      </div>,
      "Test email template"
    );
    let response = await this.context.testEmailTemplate({ Template: this.state.editingNode.EmailTemplate, Recipients: recipients });
    if (response.valid()) {
      Messages.Notify.success(`The test email was sent successfully!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Send failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  render() {
    let allowedPubs = this.context.getManageablePublishers();
    return (
      <div className="formContainer">
        <h2>Email template management</h2>
        {this.props.deleteRequested ? (
          <p>You have selected an email template to consult or edit.</p>
        ) : (
          <p>You have chosen to create a new email template. Enter the properties below and click &quot;save&quot;</p>
        )}
        <Form>
          <Col>
            <Row>
              <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                <Label for="templateName">Template Name</Label>
                <Input
                  value={this.state.editingNode.EmailTemplate.TemplateName}
                  onChange={this.handleNameChanged}
                  type="text"
                  name="templateName"
                  id="templateName"
                  placeholder="Email Template Name"
                />
              </FormGroup>
              <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                <Label for="templateType">Template Type</Label>
                <Input
                  value={this.state.editingNode.EmailTemplate.TemplateType}
                  onChange={this.handleTypeChanged}
                  type="select"
                  name="templateType"
                  id="templateType"
                >
                  {Object.keys(Models.EmailTemplateType)
                    .filter((key) => isNaN(Number(Models.EmailTemplateType[key as keyof typeof Models.EmailTemplateType])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {this.friendlyTypeName(+it)}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ marginLeft: "15px", flex: "1" }}>{this.mandatoryVariables(this.state.editingNode.EmailTemplate.TemplateType)}</FormGroup>
            </Row>
            {this.state.editingNode.EmailTemplate.TemplateType !== Models.EmailTemplateType.SystemMessage && (
              <Row>
                <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                  <Label for="subject">Email subject</Label>
                  <Input
                    value={this.state.editingNode.EmailTemplate.Subject}
                    onChange={this.handleSubjectChanged}
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="Email subject"
                  />
                </FormGroup>
              </Row>
            )}
            <Row>
              <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                <Label for="publisher">Publisher</Label>
                <Input
                  value={this.state.editingNode.EmailTemplate.PublisherId}
                  onChange={this.handlePublisherChanged}
                  disabled={allowedPubs.length === 1}
                  type="select"
                  name="publisher"
                  id="publisher"
                >
                  {allowedPubs.map((it) => (
                    <option value={it.PublisherId} key={it.PublisherId} data-providerval={it.PublisherId}>
                      {it.DisplayName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                <Label for="email">Email Contents (HTML)</Label>
                <Input onChange={this.handleFileChanged} type="file" name="email" id="email" placeholder="Email contents" />
              </FormGroup>
            </Row>
          </Col>
          <Col>
            <Row className="formButtons">
              {this.props.deleteRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>
                  Delete
                </Button>
              )}
              {this.props.saveRequested && (
                <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                  Save
                </Button>
              )}
              {this.props.deleteRequested && (
                <Button onClick={this.downloadEmail} color="info" outline>
                  Download
                </Button>
              )}
              {this.props.deleteRequested && (
                <Button onClick={this.testEmail} color="info" outline>
                  Test
                </Button>
              )}
            </Row>
          </Col>
        </Form>
      </div>
    );
  }
}
