import * as React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';
import { ILicence, ILoginLibrary, InviteType } from 'src/models/dto/DashboardModels';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Messages from '../Messages';

export interface ILicenceInviteFormProps {
  licence: ILicence;
  wrapInForm?: boolean;
  goBackBtnClicked: () => void;
}
export interface ILicenceInviteFormState {
  currentInviteType: InviteType;
  userId: number | null;
  userName: string | null;
  email: string | null;
  publisherId: number | null;
  completedLink: string | null;
}
export class LicenceInviteForm extends React.Component<ILicenceInviteFormProps, ILicenceInviteFormState> {
  context: AppSession;
  static contextType = AppContext;
  linkRef = React.createRef<HTMLInputElement>();
  constructor(props: ILicenceInviteFormProps) {
    super(props);
    this.state = { currentInviteType: InviteType.DirectCreateUsername, email: null, userId: null, userName: null, publisherId: null, completedLink: null };
  }
  componentDidMount() {
    let pub = this.context.loginLibraries.get("prolibro");
    if (!pub) {
      pub = this.context.loginLibraries.rows()[0];
    }
    this.setState({ publisherId: pub.PublisherId });
  }
  componentWillUnmount() {}
  inviteTypeChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentInviteType: +arg.target.value });
  };
  userIdChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ userId: +arg.target.value });
  };
  usernameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ userName: arg.target.value });
  };
  directCreateUserIdLicence = async () => {
    let response = await this.context.insertLicence({ Licence: this.props.licence });
    if (response.valid()) {
      Messages.Notify.success(`The Licence item was saved successfully!`);
      if (this.props.goBackBtnClicked) {
        this.props.goBackBtnClicked();
      }
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  directCreateUsername = async () => {
    let response = await this.context.createLicenceByUsername({
      Licence: this.props.licence,
      Username: this.state.userName!,
      PublisherId: this.state.publisherId!,
    });
    if (response.valid()) {
      Messages.Notify.success(`The Licence item was saved successfully!`);
      if (this.props.goBackBtnClicked) {
        this.props.goBackBtnClicked();
      }
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  inviteEmail = async () => {
    if (this.state.email === null || Convert.isEmptyOrSpaces(this.state.email)) {
      Messages.Notify.error("Please enter an email in the field to send the invite.");
      return;
    }
    let response = await this.context.createLicenceInvitationWithEmail({ Licence: this.props.licence, Email: this.state.email });
    if (response.valid()) {
      Messages.Notify.success(`The Licence item was saved successfully and the email was sent!`);
      if (this.props.goBackBtnClicked) {
        this.props.goBackBtnClicked();
      }
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  inviteLink = async () => {
    let response = await this.context.createLicenceInvitationWithLink({ Licence: this.props.licence });
    if (response.valid()) {
      Messages.Notify.success(`The Licence item was saved successfully and copied to clipboard!`);
      this.setState({ completedLink: response.data.Link }, () => {
        this.setLinkCopy(false);
      });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  setLinkCopy = (showMessage: boolean) => {
    this.linkRef.current!.select();
    document.execCommand("copy");
    if (showMessage) {
      Messages.Notify.success(`Copied to clipboard!`);
    }
  };
  publisherChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ publisherId: +arg.target.value });
  };
  emailChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: arg.target.value });
  };
  formValid = () => {
    switch (this.state.currentInviteType) {
      case InviteType.DirectCreateUserId:
        return this.state.userId !== null && this.state.userId > 0;
      case InviteType.DirectCreateUsername:
        return this.state.userName !== null && !Convert.isEmptyOrSpaces(this.state.userName);
      case InviteType.InviteEmail:
        return this.state.email !== null && !Convert.isEmptyOrSpaces(this.state.email);
      case InviteType.InviteLink:
        return true;
    }
  };
  render() {
    let button: JSX.Element;

    switch (this.state.currentInviteType) {
      case InviteType.DirectCreateUserId:
        button = (
          <Button style={{ flex: "1" }} disabled={!this.formValid()} onClick={this.directCreateUserIdLicence} outline color="primary">
            Create Licence
          </Button>
        );
        break;
      case InviteType.DirectCreateUsername:
        button = (
          <Button style={{ flex: "1" }} disabled={!this.formValid()} onClick={this.directCreateUsername} outline color="primary">
            Create Licence
          </Button>
        );
        break;
      case InviteType.InviteEmail:
        button = (
          <Button style={{ flex: "1" }} disabled={!this.formValid()} onClick={this.inviteEmail} outline color="primary">
            Send invite
          </Button>
        );
        break;
      case InviteType.InviteLink:
        button = (
          <Button style={{ flex: "1" }} disabled={!this.formValid()} onClick={this.inviteLink} outline color="primary">
            Get Link
          </Button>
        );
        break;
    }
    if (this.state.completedLink !== null) {
      button = <Input onClick={() => this.setLinkCopy(true)} innerRef={this.linkRef} type="text" readOnly value={this.state.completedLink} />;
    }

    let wrapper: JSX.Element;
    let pub: ILoginLibrary | null = null;
    if (this.state.publisherId === null) {
      pub = this.context.loginLibraries.get("prolibro")!;
    }
    if (this.state.publisherId === null && !pub) {
      pub = this.context.loginLibraries.rows()[0]!;
    }
    if (this.state.publisherId !== null) {
      pub = this.context.loginLibraries.rows().find((x) => x.PublisherId === this.state.publisherId)!;
    }

    let contents = (
      <Col>
        <Row>
          <p>Licences in the Dashboard can be granted in 4 ways. You may choose one of the following options:</p>
          <ol>
            <li>If you know the (case-sensitive) login name of the user and their login Publisher, you can attribute the licence directly.</li>
            <li>If you know the User ID, you can attribute the licence directly.</li>
            <li>
              If you want to send the invitation yourself, a link can be generated that you can send to the person so they can go through the acceptance steps.
            </li>
            <li>If you wish for Dashboard to contact the user by email, input the email and Dashboard will send an email invitation directly.</li>
          </ol>
          <p>For options 3 and 4, the licence will only become active once the user accepts it and links it to a Dashboard account.</p>
        </Row>
        <Row>
          <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
            <Input type="select" name="permissionInviteType" value={this.state.currentInviteType} onChange={this.inviteTypeChanged}>
              <option value={InviteType.DirectCreateUsername}>Invite by username</option>
              <option value={InviteType.DirectCreateUserId}>Invite by user ID</option>
              <option value={InviteType.InviteEmail}>Invite by email</option>
              <option value={InviteType.InviteLink}>Invite by link</option>
            </Input>
          </FormGroup>
        </Row>
        {this.state.currentInviteType === InviteType.DirectCreateUserId && (
          <Row>
            <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
              <Label for="userId">User ID</Label>
              <Input type="number" name="userId" defaultValue={this.state.userId === null ? 0 : this.state.userId} onChange={this.userIdChanged} />
            </FormGroup>
          </Row>
        )}
        {this.state.currentInviteType === InviteType.DirectCreateUsername && (
          <Row>
            <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
              <Label for="userId">Username</Label>
              <Input
                type="text"
                name="username"
                defaultValue={this.state.userName === null ? undefined : this.state.userName}
                onChange={this.usernameChanged}
              />
            </FormGroup>
            <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
              <Label>Publisher the user logs in with</Label>
              <Input type="select" name="brandKey" value={pub!.PublisherId} onChange={this.publisherChanged}>
                {this.context.loginLibraries
                  .rows()
                  .sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))
                  .map((lib) => (
                    <option value={lib.PublisherId} key={lib.PublisherId}>
                      {lib.DisplayName}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Row>
        )}
        {this.state.currentInviteType === InviteType.InviteEmail && (
          <Row>
            <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
              <Label for="userId">Email</Label>
              <Input type="email" name="username" defaultValue={this.state.email === null ? undefined : this.state.email} onChange={this.emailChanged} />
            </FormGroup>
          </Row>
        )}
        <Row className="formButtons">
          {button}
          {this.props.goBackBtnClicked && (
            <Button onClick={this.props.goBackBtnClicked} style={{ flex: "1", marginLeft: "10px" }} outline color="info">
              Go back
            </Button>
          )}
        </Row>
      </Col>
    );
    if (this.props.wrapInForm) {
      wrapper = <Form>{contents}</Form>;
    } else {
      wrapper = contents;
    }
    return wrapper;
  }
}
