
import * as React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import * as Models from '../../../models/dto/DashboardModels';

export interface IConfigurationPairFormProps {
    initialNode: Models.IConfigurationPair;
    deleteRequested?: (node: Models.IConfigurationPair) => void;
    saveRequested?: (node: Models.IConfigurationPair) => void;
}
export interface IConfigurationPairFormState {
    editingNode: Models.IConfigurationPair;
}
export class ConfigurationPairForm extends React.Component<IConfigurationPairFormProps, IConfigurationPairFormState>{
    constructor(props: IConfigurationPairFormProps) {
        super(props);
        this.state = { editingNode: this.props.initialNode }
    }
    componentDidMount() {
    }
    nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Name: e.target.value } }));
    }
    valueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Value: e.target.value } }));
    }
    formValid = () => this.state.editingNode.Name.length > 0 && this.state.editingNode.Value.length > 0
    render() {
        return <div className="formContainer">
            <h2>Configuration pair management</h2>
            {this.props.deleteRequested ? <p>You have selected a configuration pair to consult or edit.</p> : <p>You have chosen to create a new configuration pair. Enter the properties below and click &quot;save&quot;</p>}
            <Form>
                <Col>
                    <Row>
                        <FormGroup style={{ flex: "1" }}>
                            <Label for="configName">Name</Label>
                            <Input invalid={!(this.state.editingNode.Name.length > 0)} disabled={this.props.initialNode.Name.length > 0} value={this.state.editingNode.Name} onChange={this.nameChanged} type="text" name="configName" id="configName" placeholder="Configuration Pair Name" />
                        </FormGroup>
                        <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                            <Label for="configValue">Value</Label>
                            <Input invalid={!(this.state.editingNode.Value.length > 0)} value={this.state.editingNode.Value} onChange={this.valueChanged} type="text" name="configValue" id="configValue" placeholder="Configuration Pair Value" />
                        </FormGroup>
                    </Row>
                </Col>
                <Col>
                    <Row className="formButtons">
                        {this.props.deleteRequested && <Button disabled={!this.formValid()} onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>Delete</Button>}
                        {this.props.saveRequested && <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>Save</Button>}
                    </Row>
                </Col>
            </Form>
        </div >
    }
}