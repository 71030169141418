import * as React from "react";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { Drawer, DrawerContainer } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../models/dto/DashboardModels";
import { DashboardView } from "../foundation/Layout";
import { SubscriptionForm } from "./SubscriptionView/SubscriptionForm";

export interface ILicensingViewProps {
  IsLoggedIn: boolean;
}
export interface ILicensingViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
}
export class LicensingView extends React.Component<ILicensingViewProps, ILicensingViewState> {
  context: AppSession;
  static contextType = AppContext;
  subscriptionsTable = React.createRef<DataTable>();
  constructor(props: ILicensingViewProps) {
    super(props);
    this.state = { currentDrawerContent: null, drawerShow: false };
  }
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Licensing)!.loading.on(this.initialize);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Licensing)!.loading.off(this.initialize);
  }
  initialize = () => {
    this.context.viewedViews.get(DashboardView.Licensing)!.progressLoading();
  };
  // #region Subscriptions
  private initializeSubscriptions = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowCuratedSubscriptions({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private subscriptionFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowCuratedSubscriptions({ FlowRequest: request.Batches[0] });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  generateSubscription = (n: INode) => {
    let node = n as Models.ISubscriptionViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Subscription.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} key={1} className="centerText" value={node.Customer!.Name} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="rightBorder leftBorder centerText" value={node.ProductDef!.Name} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="centerText" value={node.Subscription.AuthSubscription} />);
    dataItems.push(
      <DataItem
        flexVal={1}
        key={4}
        className="rightBorder leftBorder centerText"
        value={Convert.dateToFormattedString(node.Subscription.StartDate, Languages.English)}
      />
    );
    if (new Date(node.Subscription.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={1} className="centerText" key={5} value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(
        <DataItem flexVal={1} key={5} className="centerText" value={Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)} />
      );
    }
    dataItems.push(<DataItem flexVal={1} key={6} className="rightBorder leftBorder centerText" value={node.AssignedLicences.length.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={7} className="centerText" value={node.Subscription.TotalLicences.toString()} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.subscriptionEdit} />;
  };
  subscriptionEdit = (n: INode) => {
    let node = n as Models.ISubscriptionViewModel;
    this.setState({
      drawerShow: true,
      currentDrawerContent: <SubscriptionForm initialNode={node} publisherId={node.Customer!.PublisherId} curatorMode={true} />,
    });
  };
  // #endregion
  render() {
    if (!this.props.IsLoggedIn || !this.context.canCurateAnySubscription() || !this.context.viewedViews.get(DashboardView.Licensing)!.isLoaded()) {
      return "";
    }
    return (
      <div className="mainView">
        <div className="licensingView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="licencingViewInner">
              <div className="section">
                <h1>Welcome to the Licensing View</h1>
                <p>
                  Welcome to the Licencing View. This view is used to manage the various subscription in the system that your user has curation rights on. The
                  subscriptions that show in the table below allow you to extend licences to users for them. In this view you can:
                </p>
                <ul>
                  <li>View subscription properties</li>
                  <li>Extend new licences to users</li>
                  <li>Revoke existing licences for users.</li>
                </ul>
                <p>If you are seeing this page, it&apos;s because you are listed as a curator for one or more subscriptions.</p>
                <DataTable
                  headers={["Customer name", "Product name", "Subscription Auth", "Start Date", "End Date", "Used Licences", "Maximum Licences"]}
                  headerFlexes={[2, 2, 2, 1, 1, 1, 1]}
                  flowProvider={this.subscriptionFlowProvider}
                  initializeFlowProvider={this.initializeSubscriptions}
                  objectBuilder={this.generateSubscription}
                  ref={this.subscriptionsTable}
                  settingsOverride={Models.genericDataSettings}
                />
              </div>
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
