import * as React from "react";
import { AppSession, LoginType } from "src/models/AppSession";

import { Image } from "../foundation/Assets";
import { Icon } from "../foundation/Controls";
import { AppContext } from "../state/Contextes";

const logo = require("../../assets/img/logo.png");
const userAvatar = require("../../assets/img/User1.png");
interface IHeaderViewProps {}

interface IHeaderViewState {}

export class HeaderView extends React.Component<IHeaderViewProps, IHeaderViewState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IHeaderViewProps | Readonly<IHeaderViewProps>) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    return (
      <div className="header">
        <img title="headerLogo" className="header-logo" src={logo} />
        {this.context.login > LoginType.None && (
          <UserBox>
            <img title="avatarImg" className="avatar" src={userAvatar} />
            <span className="username">{this.context.userName}</span>
            <div
              className="logoutBox"
              onClick={() => {
                this.context.logoutDashboard({});
              }}
            >
              <span>
                <Icon src={<Image.exit />} />
              </span>
            </div>
          </UserBox>
        )}
      </div>
    );
  }
}
interface IUserBoxProps {
  children?: React.ReactNode;
}

interface IUserBoxState {}

export class UserBox extends React.Component<IUserBoxProps, IUserBoxState> {
  render() {
    return <div className="userBox">{this.props.children}</div>;
  }
}
