import { Dictionary } from 'src/collections/Generics';
import * as _ from 'underscore';

type fieldValidator = (value: any, state?: FormState) => boolean;

/**
 * Represent a form, composed of fields with names and values. Abstract the logic for state preservation and validation.
 */
export class FormState {

    values: { [key: string]: any }
    fieldValidators: Dictionary<string, Array<{ f: fieldValidator, error: string }>>;
    errors: Dictionary<string, string[]>;
    private initialValues: { [key: string]: any }
    private changed = false;
    /**
     * Create this instance. Receives form initial values. 
     */
    constructor(initial: { [key: string]: any }) {
        this.initialValues = initial;
        this.values = initial;
        this.reset();
    }

    /**
     * Add a validation function to a particular field of the form 
     *
     * @param name name of the field to validate
     * @param validator validation function. Function must get a value, and return True if the validation succeeded. 
     * Optionally, the function can get the FormState object as a parameter, allowing to validate a field value in relation to another field value
     */
    addValidator(name: string, validator: fieldValidator, error: string) {
        if (!this.fieldValidators.containsKey(name)) {
            this.fieldValidators.set(name, new Array<{ f: fieldValidator, error: string }>());
        }

        this.fieldValidators.get(name)!.push({
            f: validator,
            error
        });
    }

    /**
     * Validate a single field with name. Returns a True if newValue has passed validation, or 
     * a list of error messages (string) if it didn't. 
     * NOTE: You probably don't need to call this method manually, if will be called by change()
     * when the form is mutated 
     */
    validate(name: string, newValue: any): boolean | string[] {
        let valid: string[] = new Array<string>();
        if (this.fieldValidators.containsKey(name)) {
            if (this.fieldValidators.get(name) != null) {
                for (let validator of this.fieldValidators.get(name)!) {
                    if (!validator.f(newValue, this)) {
                        valid.push(validator.error);
                    }
                }
            }
        }
        return valid.length === 0 || valid;
    }

    /**
     * Returns a boolean whether the entire form (all the fields) is invalid in the current state. 
     */
    formInvalid(): boolean {
        let invalid = false;
        for (let k of _.keys(this.values)) {
            this.setAndValidate(k, this.values[k], true);
            invalid = invalid || this.invalid(k); // We don't want to return early, to set all the errors
        }
        return invalid;
    }

    /**
     * Returns a boolean whether the entire form (all the fields) is valid in the current state. 
     */
    formValid(): boolean {
        return !this.formInvalid();
    }

    /**
     * Returns a boolean whether the field with name is invalid in the current state of the form.
     *
     * @return True: the field is invalid, False: the field is valid
     */
    invalid(name: string): boolean {
        return this.errors.containsKey(name) && this.errors.get(name)!.length > 0;
    }

    /**
     * Returns a boolean whether the field with name is valid in the current state of the form.
     *
     * @return True: the field is valid, False: the field is invalid
     */
    valid(name: string): boolean {
        return !this.invalid(name);
    }

    /**
     * Returns a collection of error messages for a field with name in the current state of the form.
     * Will be empty if the field is valid. 
     */
    getErrors(name: string): string[] {
        if (this.errors.containsKey(name)) {
            return this.errors.get(name)!;
        }
        return new Array<string>();

    }

    /**
     * Mutate the state of a field in the form. Performs validation and apply the new value into state. 
     * The target parameter is compatible with the target property of Input/Form onChange() DOM event
     *
     * @returns this instance, so the return of this method can be given to a React Component setState() method directly. 
     */
    change(target: { name: string, type: string, value?: any, checked?: boolean }): FormState {
        const value: any = target.type === 'checkbox' ? target.checked! : target.value;
        const name = target.name;
        this.setAndValidate(name, value, true)
        this.changed = true;
        return this;
    }

    /**
     * Mutate the state of many fields in the form. The values parameter will be merged in the current state, so only the values present in the values parameter will be changed.
     *
     * @returns this instance, so the return of this method can be given to a React Component setState() method directly. 
     */
    update(values: { [key: string]: any }): FormState {
        for (let v of _.keys(values)) {
            this.setAndValidate(v, values[v], true);
        }
        this.changed = true;
        return this;
    }

    /**
     * Reset the state of the form to the initial values provided in constructor. 
     *
     * @returns this instance, so the return of this method can be given to a React Component setState() method directly. 
     */
    reset(): FormState {
        this.fieldValidators = new Dictionary<string, Array<{ f: fieldValidator, error: string }>>();
        this.errors = new Dictionary<string, string[]>();
        this.values = JSON.parse(JSON.stringify(this.initialValues));
        this.changed = false;
        return this;

    }

    isDefault(): boolean {
        return !this.changed;
    }

    private addError(name: string, error: string) {
        if (!this.errors.containsKey(name)) {
            this.errors.set(name, new Array<string>());
        }
        this.errors.get(name)!.push(error);
    }


    private setAndValidate(name: string, value: any, force: boolean) {
        const result = this.validate(name, value)
        this.clearErrors(name);
        if (result !== true && typeof (result) === 'object') {
            for (let error of result) {
                this.addError(name, error);
            }
        }

        if (result === true || force) {
            this.values[name] = value;
        }
    }

    private clearErrors(name: string) {
        if (this.errors.containsKey(name)) {
            this.errors.set(name, new Array<string>());
        }
    }



}