import * as React from "react";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { IndustryType, IUserCreate, LanguageCode, UserStatus } from "src/models/dto/DashboardModels";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import { Image } from "../../foundation/Assets";
import { Icon } from "../../foundation/Controls";
import * as Messages from "../../foundation/Messages";
import { AppContext } from "../../state/Contextes";

interface IRegisterFormProps {
  returnToLogin: () => void;
  publisherId: number;
}
interface IRegisterFormState {
  user: IUserCreate;
  createStep: number;
  createFailed: boolean;
  lastError: string;
}

export class RegisterForm extends React.Component<IRegisterFormProps, IRegisterFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IRegisterFormProps) {
    super(props);
    this.state = {
      createStep: 1,
      user: {
        ConfirmPassword: "",
        DateAdded: new Date(),
        DateLastUsed: new Date(),
        DisplayName: "",
        Email: "",
        Industry: IndustryType.Undefined,
        LanguageType: LanguageCode.Undefined,
        LockUntilDate: new Date(),
        Locked: false,
        LoginName: "",
        Occupation: "",
        Password: "",
        PhoneNumber: "",
        SocialAddr: "",
        SocialAddr2: "",
        TableGuid: "00000000-0000-0000-0000-000000000000",
        TableId: -1,
        UserStatus: UserStatus.Pending,
        FirstName: "",
        LastName: "",
        IsSso: false,
      },
      createFailed: false,
      lastError: "",
    };
  }
  // Step 1
  handleUsernameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, Email: arg.target.value, DisplayName: arg.target.value, LoginName: arg.target.value } }));
  };
  handleSsoChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    if (arg.target.checked) {
      this.setState((prevState) => ({ user: { ...prevState.user, Password: "" } }));
      this.setState((prevState) => ({ user: { ...prevState.user, ConfirmPassword: "" } }));
    }
    this.setState((prevState) => ({ user: { ...prevState.user, IsSso: arg.target.checked } }));
  };
  handlePasswordChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, Password: arg.target.value } }));
  };
  handleConfirmPasswordChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, ConfirmPassword: arg.target.value } }));
  };
  // Step 2
  handleIndustryChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, Industry: +arg.target.value } }));
  };
  handleOccupationChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, Occupation: arg.target.value } }));
  };
  handleFirstnameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, FirstName: arg.target.value } }));
  };
  handleLastnameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, LastName: arg.target.value } }));
  };
  handleAddressChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, SocialAddr: arg.target.value } }));
  };
  handleAddress2Changed = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, SocialAddr2: arg.target.value } }));
  };
  handlePhoneChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, PhoneNumber: arg.target.value } }));
  };
  handleLanguageChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({ user: { ...prevState.user, LanguageType: +arg.target.value } }));
  };
  formValid = () => {
    if (this.state.createStep === 1) {
      return (
        ((!Convert.isEmptyOrSpaces(this.state.user.ConfirmPassword) &&
          !Convert.isEmptyOrSpaces(this.state.user.Password) &&
          this.state.user.ConfirmPassword === this.state.user.Password &&
          !Convert.isEmptyOrSpaces(this.state.user.Email)) ||
          this.state.user.IsSso === true) &&
        this.validateEmail(this.state.user.Email)
      );
    } else if (this.state.createStep === 2) {
      return (
        !Convert.isEmptyOrSpaces(this.state.user.FirstName) &&
        !Convert.isEmptyOrSpaces(this.state.user.LastName) &&
        this.state.user.Industry !== 0 &&
        this.state.user.LanguageType !== 0
      );
    }
    return true;
  };
  forwardStep = () => {
    this.setState({ createStep: this.state.createStep + 1 });
  };
  backStep = () => {
    this.setState({ createStep: this.state.createStep - 1 });
  };
  validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  createAccount = async () => {
    let response = await this.context.createUserAccount({
      User: this.state.user,
      Password: this.state.user.Password,
      FirstName: this.state.user.FirstName,
      LastName: this.state.user.LastName,
      PublisherId: this.props.publisherId,
      IsSso: this.state.user.IsSso,
    });
    this.forwardStep();
    if (response.valid()) {
      Messages.Notify.success(`The account was created successfully!`);
      this.setState({ createFailed: false, lastError: "" });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Account creation failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      this.setState({ createFailed: true, lastError: response.errors[0].Message });
    }
  };

  getFormControls = () => {
    if (this.state.createStep === 1) {
      return (
        <div className="formContainer">
          <h3>Credentials</h3>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="email" name="username" value={this.state.user.LoginName} placeholder={"Email"} onChange={this.handleUsernameChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.lock />} />
              </InputGroupText>

              <Input
                type="password"
                name="password"
                value={this.state.user.Password}
                placeholder={this.context.localization.currentLocale.LoginView.LABEL_LOGIN_PASSWORD}
                onChange={this.handlePasswordChanged}
                disabled={this.state.user.IsSso}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.lock />} />
              </InputGroupText>

              <Input
                type="password"
                name="confirmPassword"
                value={this.state.user.ConfirmPassword}
                placeholder={"Confirm password"}
                onChange={this.handleConfirmPasswordChanged}
                disabled={this.state.user.IsSso}
              />
            </InputGroup>
          </FormGroup>
          {this.context.canManageAccounts() && (
            <FormGroup>
              <InputGroup style={{ paddingLeft: "3em" }}>
                <Label check for="ssoCheckUser">
                  <Input type="checkbox" id="ssoCheckUser" name="ssoCheck" checked={this.state.user.IsSso} onChange={this.handleSsoChanged} />
                  User logs in with SSO provider.
                </Label>
              </InputGroup>
            </FormGroup>
          )}
          <div className="formButtons">
            <Button onClick={this.forwardStep} color="primary" outline block disabled={!this.formValid()}>
              Next
            </Button>
            <Button onClick={this.props.returnToLogin} color="primary" outline block>
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (this.state.createStep === 2) {
      return (
        <div className="formContainer">
          <h3>
            User Info <b>(* required)</b>
          </h3>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="text" name="firstname" value={this.state.user.FirstName} placeholder={"First name*"} onChange={this.handleFirstnameChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="text" name="lastname" value={this.state.user.LastName} placeholder={"Last name*"} onChange={this.handleLastnameChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="text" name="phone" value={this.state.user.PhoneNumber} placeholder={"Phone"} onChange={this.handlePhoneChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="text" name="Address" value={this.state.user.SocialAddr} placeholder={"Address"} onChange={this.handleAddressChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="text" name="AddressL2" value={this.state.user.SocialAddr2} placeholder={"Address Line 2"} onChange={this.handleAddress2Changed} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="select" name="language" value={this.state.user.LanguageType} placeholder={"Language"} onChange={this.handleLanguageChanged}>
                {Object.keys(Models.LanguageCode)
                  .filter((key) => isNaN(Number(Models.LanguageCode[key as keyof typeof Models.LanguageCode])))
                  .map((it) => (
                    <option value={it} key={it} data-providerval={it}>
                      {+it === LanguageCode.Undefined ? "Preferred Language*" : Models.LanguageCode[it as keyof typeof Models.LanguageCode]}
                    </option>
                  ))}
              </Input>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="select" name="language" value={this.state.user.Industry} placeholder={"Industry"} onChange={this.handleIndustryChanged}>
                {Object.keys(Models.IndustryType)
                  .filter((key) => isNaN(Number(Models.IndustryType[key as keyof typeof Models.IndustryType])))
                  .map((it) => (
                    <option value={it} key={it} data-providerval={it}>
                      {+it === IndustryType.Undefined ? "Industry*" : Models.IndustryType[it as keyof typeof Models.IndustryType]}
                    </option>
                  ))}
              </Input>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input type="text" name="occupation" value={this.state.user.Occupation} placeholder={"Occupation"} onChange={this.handleOccupationChanged} />
            </InputGroup>
          </FormGroup>
          <FormGroup className="formButtons">
            <div>
              <Button onClick={this.backStep} color="primary" outline block>
                Previous
              </Button>
              <Button onClick={this.createAccount} color="primary" outline block disabled={!this.formValid()}>
                Finish
              </Button>
            </div>
          </FormGroup>
        </div>
      );
    } else if (this.state.createStep === 3) {
      if (this.state.createFailed) {
        return (
          <FormGroup>
            <span>Your account could not be created. Error: {this.state.lastError}, please correct the issue or contact an administrator for assistance.</span>
            <Button onClick={this.backStep} color="primary" outline block>
              Previous
            </Button>
            <Button color="primary" outline block onClick={this.props.returnToLogin}>
              {this.props.publisherId === -1 ? "Dismiss" : "Back to login"}
            </Button>
          </FormGroup>
        );
      } else {
        return (
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {this.props.publisherId === -1
                ? "Account was created and activated. User can now login on Reader/Dashboard."
                : "Your account was created successfully, please check your email for a link to activate it. Then login!"}
            </span>
            <Button color="primary" outline onClick={this.props.returnToLogin}>
              {this.props.publisherId === -1 ? "Dismiss" : "Login"}
            </Button>
          </FormGroup>
        );
      }
    } else {
      return <span>Invalid step</span>;
    }
  };

  render() {
    let res = this.getFormControls();
    return <Form className="p-2">{res}</Form>;
  }
}
