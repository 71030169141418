import * as React from "react";
import { Languages } from "src/localization/Locale";
import { AppSession, LoginType } from "src/models/AppSession";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../models/dto/DashboardModels";
import { AppContext } from "../state/Contextes";

export interface IHomeViewProps {
  IsLoggedIn: boolean;
}
export interface IHomeViewState {
  daysToExpiry: number;
  licensingToken: Models.ILicensingToken | null;
}
export class HomeView extends React.Component<IHomeViewProps, IHomeViewState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IHomeViewProps | Readonly<IHomeViewProps>) {
    super(props);
    this.state = { daysToExpiry: 0, licensingToken: null };
  }
  componentDidMount() {
    this.getServerExpiryInfo();
  }
  getServerExpiryInfo = async () => {
    let response = await this.context.getServerLicensing({});
    if (response.valid()) {
      this.setState({ daysToExpiry: response.data.DaysToExpiry, licensingToken: response.data.LicensingToken });
    }
  };
  render() {
    if (this.context.login === LoginType.None) {
      return "";
    }
    return (
      <div className="mainView">
        <div className="homeView">
          <div className="section">
            <h1>The proLibro connect Dashboard</h1>
            <p>
              The proLibro connect system is used to manage access to products housed in the proLibro format. This home page will be continuously updated with
              new features and widgets affecting how the proLibro system is being used. Check back often!
            </p>
          </div>
          {this.context.userPermissions.length > 0 && (
            <div className="section">
              <h2>Current licensing status</h2>
              {this.state.licensingToken === null && (
                <p style={{ color: "red" }}>
                  This Connect & Dashboard instance is currently unlicensed! Reader and Builder requests will be revoked. Please contact CogniLore.
                </p>
              )}
              {this.state.licensingToken !== null && this.state.daysToExpiry > 0 && (
                <p style={{ color: "green" }}>
                  This instance of Connect & Dashboard is licensed through to{" "}
                  {Convert.dateToFormattedString(this.state.licensingToken.ExpirationDate, Languages.English)} (UTC) ({this.state.daysToExpiry} days)
                </p>
              )}
              {this.state.licensingToken !== null && this.state.daysToExpiry < 0 && this.state.daysToExpiry >= -30 && (
                <p style={{ color: "red" }}>
                  This instance of Connect & Dashboard expired on {Convert.dateToFormattedString(this.state.licensingToken.ExpirationDate, Languages.English)}{" "}
                  (UTC)! Reader and Builder requests will be revoked in {this.state.daysToExpiry + 30} days
                </p>
              )}
              {this.state.licensingToken !== null && this.state.daysToExpiry < 0 && this.state.daysToExpiry < -30 && (
                <p style={{ color: "red" }}>
                  This instance of Connect & Dashboard expired on {Convert.dateToFormattedString(this.state.licensingToken.ExpirationDate, Languages.English)}{" "}
                  (UTC) and the 30 day grace period has elapsed. Reader and Builder requests are now being revoked. Please contact CogniLore for updated
                  licensing.
                </p>
              )}
            </div>
          )}
          <div className="section">
            <h3>Download the free proLibro software</h3>
            <p>
              If you have activated a Licence for a proLibro product, you will need to download, install, and login to the free proLibro software to view that
              product.
            </p>
            <p>
              Links to download the proLibro software, additional information on the features and advantages of the proLibro system, and helpful tutorials and
              information on using the system can all be found at{" "}
              <a href="http://www.proLibro.com" title="proLibro" target="_blank" rel="noopener noreferrer">
                www.proLibro.com
              </a>
              .
            </p>
            <p>You can view your Licence details and the devices you have registered by clicking on the Library tab on the left side of the site.</p>
          </div>
        </div>
      </div>
    );
  }
}
