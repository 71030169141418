import * as React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { Loading } from "src/ui/foundation/Controls";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";

export interface ICreatePublicationPackTokenFormProps {
  dismissDrawer: () => void;
}
export interface ICreatePublicationPackTokenFormState {
  editingPublicationPack: Models.IPublicationPack;
  editingPublicationPackDefinition: Models.IPublicationPackDefinition;
  tokenId: string;
  loading: boolean;
}
export class CreatePublicationPackTokenForm extends React.Component<ICreatePublicationPackTokenFormProps, ICreatePublicationPackTokenFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ICreatePublicationPackTokenFormProps) {
    super(props);
    this.state = {
      editingPublicationPack: {
        BackListPricePerAnnum: 0,
        DateCreated: new Date(),
        FrontListPricePerAnnum: 0,
        LastBilled: new Date(),
        MaintenancePricePerAnnum: 0,
        NextBilling: new Date(),
        PublicationPackDefinitionId: -1,
        PublisherId: -1,
        RemainingSlots: 0,
        Slots: 0,
        TableId: -1,
        PackDescription: "",
        PackName: "",
        TableGuid: "00000000-0000-0000-0000-000000000000",
      },
      editingPublicationPackDefinition: {
        BillingCurrency: Models.CurrencyCodes.CAD,
        PackDefDescription: "",
        PackDefName: "Not set",
        PublicationPackInvoicing: Models.PublicationPackInvoicing.FlatPerAnnum,
        TableId: -1,
        UserLicenseInvoicing: Models.UserLicenseInvoicing.RecurringPerAnnum,
      },
      loading: false,
      tokenId: "",
    };
  }
  frontListPricePerAnnumChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => {
      prevState.editingPublicationPack.FrontListPricePerAnnum = +e.target.value;
      return prevState;
    });
  };
  backListPricePerAnnumChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => {
      prevState.editingPublicationPack.BackListPricePerAnnum = +e.target.value;
      return prevState;
    });
  };
  maintenancePricePerAnnumChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => {
      prevState.editingPublicationPack.MaintenancePricePerAnnum = +e.target.value;
      return prevState;
    });
  };
  slotsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => {
      prevState.editingPublicationPack.Slots = +e.target.value;
      return prevState;
    });
  };
  packNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => {
      prevState.editingPublicationPack.PackName = e.target.value;
      return prevState;
    });
  };
  packDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => {
      prevState.editingPublicationPack.PackDescription = e.target.value;
      return prevState;
    });
  };
  lastBilledChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => {
      prevState.editingPublicationPack.LastBilled = dato!;
      return prevState;
    });
  };
  dateCreatedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => {
      prevState.editingPublicationPack.DateCreated = dato!;
      return prevState;
    });
  };
  nextBillingChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => {
      prevState.editingPublicationPack.NextBilling = dato!;
      return prevState;
    });
  };
  packDefNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingPublicationPackDefinition: { ...prevState.editingPublicationPackDefinition, PackDefName: e.target.value },
    }));
  };
  packDefDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingPublicationPackDefinition: { ...prevState.editingPublicationPackDefinition, PackDefDescription: e.target.value },
    }));
  };
  handleUserLicenseInvoicingChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingPublicationPackDefinition: { ...prevState.editingPublicationPackDefinition, UserLicenseInvoicing: +arg.target.value },
    }));
  };
  handlePublicationPackInvoicingChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingPublicationPackDefinition: { ...prevState.editingPublicationPackDefinition, PublicationPackInvoicing: +arg.target.value },
    }));
  };
  handleBillingCurrencyChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingPublicationPackDefinition: { ...prevState.editingPublicationPackDefinition, BillingCurrency: +arg.target.value },
    }));
  };
  tokenChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState(() => ({
      tokenId: e.target.value,
    }));
  };
  isPublicationDefinitionValid = () => {
    if (Convert.isEmptyOrSpaces(this.state.editingPublicationPackDefinition.PackDefName)) {
      Messages.Notify.error("Pack definition name cannot be empty");
      return false;
    } else if (this.state.editingPublicationPackDefinition.BillingCurrency <= 0) {
      Messages.Notify.error("Billing currency is invalid");
      return false;
    } else if (this.state.editingPublicationPackDefinition.PublicationPackInvoicing < 0) {
      Messages.Notify.error("Publication Pack Invoicing is invalid");
      return false;
    } else if (this.state.editingPublicationPackDefinition.UserLicenseInvoicing < 0) {
      Messages.Notify.error("User License Invoicing is invalid");
      return false;
    }

    return true;
  };
  isPublicationPackValid = () => {
    if (
      this.state.editingPublicationPack.FrontListPricePerAnnum < 0 ||
      this.state.editingPublicationPack.BackListPricePerAnnum < 0 ||
      this.state.editingPublicationPack.MaintenancePricePerAnnum < 0
    ) {
      Messages.Notify.error("Billing price cannot be negative.");
      return false;
    } else if (this.state.editingPublicationPack.Slots < 0) {
      Messages.Notify.error("Slot count cannot be negative.");
      return false;
    }
    return true;
  };
  createPackToken = () => {
    if (this.isPublicationDefinitionValid() && this.isPublicationPackValid() && !Convert.isEmptyOrSpaces(this.state.tokenId)) {
      this.setState({ loading: true }, async () => {
        let response = await this.context.createPublicationPackToken({
          PublicationPack: this.state.editingPublicationPack,
          PublicationPackDefinition: this.state.editingPublicationPackDefinition,
          TokenId: this.state.tokenId,
        });
        if (response.valid()) {
          Messages.Notify.success("Publication pack saved successfully!");
          this.download("PubToken" + this.state.tokenId + ".cogniPack", response.data.Token);
          this.props.dismissDrawer();
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  };
  download = (filename: string, text: string) => {
    let element = document.createElement("a");
    element.setAttribute("href", "data:application/octet-stream," + encodeURIComponent(text));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  };
  render() {
    let lastBilled = Convert.formatDateForForm(new Date(this.state.editingPublicationPack.LastBilled));
    let nextBilling = Convert.formatDateForForm(new Date(this.state.editingPublicationPack.NextBilling));
    let dateCreated = Convert.formatDateForForm(new Date(this.state.editingPublicationPack.DateCreated));

    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Publication Pack...">
        <div className="form-container full-width full-height">
          <h2>Publication pack token creation panel</h2>
          <p>
            This panel is to create publication pack tokens for remote instances of Connect/Dashboard (datacenter). Input the TokenConfig data you wish to use
            and fill out the information for the pack and pack definition. Then click &quot;Generate publication pack token&quot;. A token file will be
            downloaded through the browser and can be sent to the publisher manager this token needs to be applied to.
          </p>
          <Col>
            <Row>
              <FormGroup>
                <Label for="tokenId">Token Id on the server (TokenConfigs + [This_Input]) see config pairs.</Label>
                <Input value={this.state.tokenId} onChange={this.tokenChanged} type="text" name="tokenId" id="tokenId" placeholder="TokenConfigs +" />
              </FormGroup>
            </Row>
            <Row>
              <h2 style={{ marginTop: "15px" }}>Publication pack section</h2>
              {this.state.editingPublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.PerPublication && (
                <div className="full-height full-width">
                  <FormGroup>
                    <Label for="frontListPricePerAnnum">Price per frontlist slot yearly (this pack definition is invoiced by the slot)</Label>
                    <Input
                      value={this.state.editingPublicationPack.FrontListPricePerAnnum}
                      onChange={this.frontListPricePerAnnumChanged}
                      type="number"
                      name="frontListPricePerAnnum"
                      id="frontListPricePerAnnum"
                      placeholder="Front List Price Per Annum"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="backListPricePerAnnum">Price per backlist slot yearly (this pack definition is invoiced by the slot)</Label>
                    <Input
                      value={this.state.editingPublicationPack.BackListPricePerAnnum}
                      onChange={this.backListPricePerAnnumChanged}
                      type="number"
                      name="backListPricePerAnnum"
                      id="backListPricePerAnnum"
                      placeholder="Back List Price Per Annum"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="maintenancePricePerAnnum">Price per maintenance slot yearly (this pack definition is invoiced by the slot)</Label>
                    <Input
                      value={this.state.editingPublicationPack.MaintenancePricePerAnnum}
                      onChange={this.maintenancePricePerAnnumChanged}
                      type="number"
                      name="maintenancePricePerAnnum"
                      id="maintenancePricePerAnnum"
                      placeholder="Maintenance List Price Per Annum"
                    />
                  </FormGroup>
                </div>
              )}
              {this.state.editingPublicationPackDefinition.PublicationPackInvoicing === Models.PublicationPackInvoicing.FlatPerAnnum && (
                <div className="full-height full-width">
                  <FormGroup>
                    <Label for="frontListPricePerAnnum">Publication pack flat price per year</Label>
                    <Input
                      value={this.state.editingPublicationPack.FrontListPricePerAnnum}
                      onChange={this.frontListPricePerAnnumChanged}
                      type="number"
                      name="frontListPricePerAnnum"
                      id="frontListPricePerAnnum"
                      placeholder="Front List Price Per Annum"
                    />
                  </FormGroup>
                </div>
              )}
            </Row>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="slotsInPack">Slots in this pack</Label>
                <Input
                  value={this.state.editingPublicationPack.Slots}
                  onChange={this.slotsChanged}
                  type="number"
                  name="slotsInPack"
                  id="slotsInPack"
                  placeholder="Slots in this pack"
                />
              </FormGroup>
              <FormGroup style={{ flex: "1" }}>
                <Label for="packName">Pack name</Label>
                <Input
                  value={this.state.editingPublicationPack.PackName}
                  onChange={this.packNameChanged}
                  type="text"
                  name="packName"
                  id="packName"
                  placeholder="Pack name"
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: "1" }}>
                <Label for="packDescription">Pack description</Label>
                <Input
                  value={this.state.editingPublicationPack.PackDescription}
                  onChange={this.packDescriptionChanged}
                  type="text"
                  name="packDescription"
                  id="packDescription"
                  placeholder="Pack description"
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                <Label for="dateCreated">Date created</Label>
                <Input type="date" name="dateCreated" id="dateCreated" value={dateCreated} onChange={this.dateCreatedChanged} />
              </FormGroup>
              <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                <Label for="lastBilled">Last billed</Label>
                <Input type="date" name="lastBilled" id="lastBilled" value={lastBilled} onChange={this.lastBilledChanged} />
              </FormGroup>
              <FormGroup style={{ flex: "1" }}>
                <Label for="endDate">Next billing date</Label>
                <Input type="date" name="endDate" id="endDate" value={nextBilling} onChange={this.nextBillingChanged} />
              </FormGroup>
            </Row>
            <h2>Publication pack definition section</h2>
            <Row>
              <FormGroup>
                <Label for="packDefName">Publication pack definition name</Label>
                <Input
                  value={this.state.editingPublicationPackDefinition.PackDefName}
                  onChange={this.packDefNameChanged}
                  type="text"
                  name="packDefName"
                  id="packDefName"
                  placeholder="Publication pack definition name"
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: "1" }}>
                <Label for="packDefDescription">Publication pack definition description</Label>
                <Input
                  value={this.state.editingPublicationPackDefinition.PackDefDescription}
                  onChange={this.packDefDescriptionChanged}
                  type="text"
                  name="packDefDescription"
                  id="packDefDescription"
                  placeholder="Publication pack definition description"
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                <Label for="userLicenseInvoicing">User license invoicing</Label>
                <Input
                  value={this.state.editingPublicationPackDefinition.UserLicenseInvoicing}
                  onChange={this.handleUserLicenseInvoicingChanged}
                  type="select"
                  name="userLicenseInvoicing"
                  id="userLicenseInvoicing"
                >
                  {Object.keys(Models.UserLicenseInvoicing)
                    .filter((key) => isNaN(Number(Models.UserLicenseInvoicing[key as keyof typeof Models.UserLicenseInvoicing])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {Models.UserLicenseInvoicing[it as keyof typeof Models.UserLicenseInvoicing]}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                <Label for="publicationPackInvoicing">Publication pack invoicing</Label>
                <Input
                  value={this.state.editingPublicationPackDefinition.PublicationPackInvoicing}
                  onChange={this.handlePublicationPackInvoicingChanged}
                  type="select"
                  name="publicationPackInvoicing"
                  id="publicationPackInvoicing"
                >
                  {Object.keys(Models.PublicationPackInvoicing)
                    .filter((key) => isNaN(Number(Models.PublicationPackInvoicing[key as keyof typeof Models.PublicationPackInvoicing])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {Models.PublicationPackInvoicing[it as keyof typeof Models.PublicationPackInvoicing]}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                <Label for="billingCurrency">Billing currency</Label>
                <Input
                  value={this.state.editingPublicationPackDefinition.BillingCurrency}
                  onChange={this.handleBillingCurrencyChanged}
                  type="select"
                  name="billingCurrency"
                  id="billingCurrency"
                >
                  {Object.keys(Models.CurrencyCodes)
                    .sort()
                    .filter((key) => isNaN(Number(Models.CurrencyCodes[key as keyof typeof Models.CurrencyCodes])))
                    .map((it) => (
                      <option value={it} key={it} data-providerval={it}>
                        {Models.CurrencyCodes[it as keyof typeof Models.CurrencyCodes]}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={this.createPackToken}>
              Generate publication pack token
            </Button>
          </Col>
        </div>
      </Loading>
    );
  }
}
