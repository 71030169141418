import React from "react";
import { UserFormSubmissionContext } from "src/ui/state/Contextes";

import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { TextField } from "@mui/material";

const TableIdWithOffset = (props: ControlProps) => {
  const submission = React.useContext(UserFormSubmissionContext)!;
  if (submission.TableId <= 0) {
    return null;
  }
  if (!isNaN(Number(props.data)) && Number(props.data) < 2000000) {
    return (
      <TextField
        style={{ width: "100%" }}
        variant="standard"
        label={props.label}
        InputProps={{
          readOnly: true,
        }}
        placeholder={props.label.toString()}
        value={props.data}
      />
    );
  } else {
    if (isNaN(Number(props.data))) {
      props.handleChange(props.path, (submission.TableId + 2000000).toString());
    }
    return (
      <TextField
        style={{ width: "100%" }}
        variant="standard"
        label={props.label}
        InputProps={{
          readOnly: true,
        }}
        value={submission.TableId + 2000000}
      />
    );
  }
};
export default withJsonFormsControlProps(TableIdWithOffset);
