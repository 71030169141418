import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Languages } from "src/localization/Locale";
import { AppSession, LoginType } from "src/models/AppSession";
import * as Models from "src/models/dto/DashboardModels";
import { DashboardView } from "src/ui/foundation/Layout";
import { Convert } from "src/utilities/Helpers";

import { Drawer, DrawerContainer, Loading } from "../../foundation/Controls";
import { DataItem, DataRow, DataTable } from "../../foundation/DataTable";
import { Action, IBatch, INode, IResponse } from "../../foundation/StandaloneCogniflow";
import { AppContext } from "../../state/Contextes";
import { ProfileForm } from "./ProfileForm";

interface IProfileViewProps {}

interface IProfileViewState {
  isInitialized: boolean;
  name: string;
  licenses: Models.IUserLicenseViewModel[];
  permissions: Models.IPermissionViewModel[];
  loading: boolean;
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
}

export class ProfileView extends React.Component<IProfileViewProps, IProfileViewState> {
  context: AppSession;
  static contextType = AppContext;
  profileForm = React.createRef<ProfileForm>();
  userLicensesTable = React.createRef<DataTable>();
  permissionTable = React.createRef<DataTable>();
  constructor(props: IProfileViewProps | Readonly<IProfileViewProps>) {
    super(props);
    this.state = {
      isInitialized: false,
      licenses: [],
      permissions: [],
      name: "",
      loading: false,
      drawerShow: false,
      currentDrawerContent: null,
    };
  }

  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Profile)!.loading.on(this.loginChanged);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Profile)!.loading.off(this.loginChanged);
  }
  getFullAccount = async () => {
    let fullAccount = await this.context.getFullAccount({
      UserId: this.context.user!.TableId,
    });
    if (fullAccount.data === null || fullAccount.data === undefined) {
      return;
    }
    let licenseList = fullAccount.data.UserModel.Licenses;
    let permissionList = fullAccount.data.UserModel.Permissions;
    this.setState({
      licenses: licenseList,
      permissions: permissionList,
    });
  };
  // On User Login
  loginChanged = () => {
    this.context.viewedViews.get(DashboardView.Profile)!.progressLoading();
    // get lisences and permissions after Login
    this.getFullAccount();
  };
  private initializeUserLicenses = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let staticBatch: IBatch = { Action: Action.insert, AnchorMainId: 0, BatchSize: 10000, Nodes: this.state.licenses, TargetMainId: 0 };
      resolve({
        nodes: Convert.indexify(staticBatch).Nodes,
        targetSpine: 0,
      });
    });
  private userLicenseFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      reject();
    });
  generateUserLicense = (n: INode) => {
    let node = n as Models.IUserLicenseViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Subscription.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={1} key={1} className="leftBorder centerText" value={node.License.TableId.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="rightBorder leftBorder centerText" value={node.Subscription.TableId.toString()} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="rightBorder leftBorder centerTex" value={node.Product.Name} />);

    dataItems.push(
      <DataItem
        flexVal={1}
        key={4}
        className="rightBorder leftBorder centerText"
        value={Convert.dateToFormattedString(node.Subscription.StartDate, Languages.English)}
      />
    );
    if (new Date(node.Subscription.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={1} className="centerText" key={5} value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(
        <DataItem flexVal={1} key={5} className="centerText" value={Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)} />
      );
    }
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={() => {}} />;
  };

  private generatePermission = (node: INode): JSX.Element => {
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Permission.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    let value = "";
    if (node.Permission.ExpirationDate === null) {
      value = "Never";
    } else if (new Date(node.Permission.ExpirationDate as Date) < new Date()) {
      value = "Expired";
    } else {
      value = Convert.dateToFormattedString(node.Permission.ExpirationDate as Date, Languages.English);
    }
    if (value === "Expired") {
      dataItems.push(
        <DataItem flexVal={2} className="centerText" key={3} value={null}>
          <span style={{ color: "red" }}>{value}</span>
        </DataItem>
      );
    } else if (value === "Never") {
      dataItems.push(<DataItem flexVal={2} className="centerText bolded" key={3} value={value} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="centerText" key={3} value={value} />);
    }
    if (node.Permission.PublisherId === null) {
      dataItems.push(<DataItem flexVal={2} className="centerText bolded" key={4} value={"All"} />);
    } else {
      dataItems.push(<DataItem flexVal={2} key={4} value={node.Publisher.Name} />);
    }
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={6} value={node.Permission.ManageSystem} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={11} value={node.Permission.ManageAccounts} />);
    dataItems.push(<DataItem flexVal={1} key={7} value={node.Permission.ManagePublishers} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={8} value={node.Permission.ManageSubscription} />);
    dataItems.push(<DataItem flexVal={1} key={9} value={node.Permission.ManageProducts} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={10} value={node.Permission.ManageTitles} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={12} value={node.Permission.ManageBulletins} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={13} value={node.Permission.ManageFeedback} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={14} value={node.Permission.ManageTips} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder" key={15} value={node.Permission.ManageReporting} />);
    dataItems.push(<DataItem flexVal={1} className="leftBorder" key={16} value={node.Permission.ManageOfflinePackages} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };
  private initializePermission = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let staticBatch: IBatch = { Action: Action.insert, AnchorMainId: 0, BatchSize: 10000, Nodes: this.state.permissions, TargetMainId: 0 };
      resolve({
        nodes: Convert.indexify(staticBatch).Nodes,
        targetSpine: 0,
      });
    });
  private permissionFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      reject();
    });

  render() {
    let settings = Models.genericDataSettings;
    settings.batchSize = 25;
    if (
      this.context.login === LoginType.None ||
      // this.context.getManageablePublishers().length === 0 ||
      !this.context.viewedViews.get(DashboardView.Profile)!.isLoaded()
    ) {
      return (
        <div className="section">
          <h1>You cannot view this page</h1>
        </div>
      );
    }

    return (
      <div className="mainView" style={{ display: "flex" }}>
        <div className="profileView">
          <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Profile...">
            <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
              <Drawer
                onBackdropClicked={() => {
                  this.setState({ drawerShow: false, currentDrawerContent: null });
                }}
                isOpen={this.state.drawerShow}
                backdrop={true}
                className="details-view"
              >
                {this.state.currentDrawerContent}
              </Drawer>
              <div className="section">
                <div className="header-box">
                  <h3 className="content-publisher-name">Profile Management</h3>
                  <p> Your Profile can be updated on this page and you can also view your licenses and permissions </p>
                </div>
                <Tabs defaultActiveKey="profile-info" id="titleTabs">
                  <Tab eventKey="profile-info" title="Basic Info">
                    <ProfileForm ref={this.profileForm} />
                  </Tab>
                  <Tab eventKey="profile-license" title="Licenses">
                    {this.state.licenses.length === 0 && <span>This user does not have any licenses.</span>}
                    {this.state.licenses.length > 0 && (
                      <div className="titlesTab">
                        <p>
                          These are the products and date ranges the selected user has license to. There may be duplicates if the user has multiple licenses to
                          different subscriptions to the same product.
                        </p>
                        <DataTable
                          headers={["License ID", "Subscription ID", "Product name", "Start Date", "End Date"]}
                          headerFlexes={[1, 1, 2, 1, 1]}
                          flowProvider={this.userLicenseFlowProvider}
                          initializeFlowProvider={this.initializeUserLicenses}
                          objectBuilder={this.generateUserLicense}
                          ref={this.userLicensesTable}
                        />
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="profile-permission" title="Permissions">
                    {this.state.permissions.length === 0 && <span>This user does not have any permissions.</span>}
                    {this.state.permissions.length > 0 && (
                      <div className="titlesTab">
                        <p>These are the permissions that have been extended to this user within the system.</p>
                        <DataTable
                          headers={[
                            "Expires (UTC)",
                            "Publisher",
                            "Manage System",
                            "Manage Accounts",
                            "Manage Publishers",
                            "Manage Subs",
                            "Manage Products",
                            "Manage Titles",
                            "Manage Bulletins",
                            "Manage Feedback",
                            "Manage Tips",
                            "Manage Reporting",
                            "Manage Offline Packages",
                          ]}
                          headerFlexes={[2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                          flowProvider={this.permissionFlowProvider}
                          initializeFlowProvider={this.initializePermission}
                          objectBuilder={this.generatePermission}
                          ref={this.permissionTable}
                        />
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </DrawerContainer>
          </Loading>
        </div>
      </div>
    );
  }
}
